import * as React from 'react';
import produce from "immer";
import AdminApiService from 'src/api/AdminApiService';
import DesiredHardwareDTO from 'src/models/DesiredHardwareDTO';
import DesiredLayoutDTO from 'src/models/DesiredLayoutDTO';
//import DesiredPropertiesDTO from 'src/models/DesiredPropertiesDTO';
import DesiredRingHardwareDTO from 'src/models/DesiredRingHardwareDTO';
import DesiredRingLayoutDTO from 'src/models/DesiredRingLayoutDTO';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Layout,
    notification,
    Typography,
    Switch,
    Descriptions,
    Col,
    Row,
    Collapse,
    Image,
    Select,
    Radio,
    Space,
    DatePicker,
    Card, FormInstance, AutoComplete
} from 'antd';
import { EditOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DesiredOverridesDTO from 'src/models/DesiredOverridesDTO';
// @ts-ignore transformed by webpack into svg url later
import compassUrl from 'src/Compass_Card_BW.svg';
import { Option } from 'antd/lib/mentions';
import { LogViewer } from '../dashboard/BinStatusPage/LogViewer';
import { RestartDriStackModuleButton } from './RestartModuleButton';
import UnifiedDeviceConfigDTO from 'src/models/UnifiedDeviceConfigDTO';
import DesiredDynamicMCDTO from 'src/models/DesiredDynamicMCDTO';
import { DeviceUpdateModal } from './TextUpdate';
import { DefaultOptionType, LabeledValue } from 'antd/lib/select';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import {range} from 'lodash-es';
import { NamePath } from 'antd/es/form/interface';
import { AutomationType, selectAutomationType } from 'src/utils/deriveAutomationType';
import RhSensorEnum from 'src/consts/RhSensorEnum';
import TemperatureSensorEnum from 'src/consts/TemperatureSensorEnum';
import HardwareYear from 'src/consts/HardwareYear';

interface State {
    binIdList: string[];
    chosenBinId: string | null;
    currentBin: UnifiedDeviceConfigDTO | null;
    hardwareYear: number | null;
    isDeviceTwin: boolean;
    connectionString: string;
    highPoweredFanCheck: boolean;
    showModalFromSheet: boolean;
}

const { Text } = Typography;
const { Panel } = Collapse;

// const derivedHardwareYear = (bin: UnifiedDeviceConfigDTO): number => {
//     const minimumYear = 2021;
//     let currentDerivedYear = minimumYear;
    

//     // if hardwareYear has ben set, use this truth value
//     if (bin.hardwareYear != null && bin.hardwareYear > 2020) {
//         return bin.hardwareYear;
//     }

//     // figure out from set fields
//     const layout = bin.layout;
//     const any2022FieldSet = layout?.innerRingGAMBearing! > 0 || layout?.innerRingGAMHeight! > 0 || layout?.innerRingGAMRadius! > 0 || layout?.outerRingGAMBearing! > 0 || layout?.outerRingGAMHeight! > 0 || layout?.outerRingGAMRadius! > 0;
//     if (any2022FieldSet) {
//         currentDerivedYear = 2022;
//     }
//     return currentDerivedYear;
// }

interface FormDesiredRingLayout extends DesiredRingLayoutDTO {
    stacks: number,
}
interface FormDesiredLayout extends DesiredLayoutDTO {
    rings: FormDesiredRingLayout[],
}

interface UpdateDeviceForm extends UnifiedDeviceConfigDTO {
    layout: FormDesiredLayout,
}

interface GamFIllProps {
}

interface AutoCompletOptionType {
    value: string | number,
    label?: string,
}

interface CustomMappingProps {
    boardGroupLabel: string,
    customMappingParentName: NamePath,
    boardOptions: AutoCompletOptionType[],
    portOptions: AutoCompletOptionType[],
}

const CustomMappingSelection = (props: CustomMappingProps) => {

    let thePath = [];
    if (Array.isArray(props.customMappingParentName)) {
        thePath = props.customMappingParentName;
    }
    else {
        thePath = [props.customMappingParentName];
    }

    return (<>
    <Space direction='horizontal' wrap>
    <Form.Item label={props.boardGroupLabel} name={[...thePath, 'boardNumber']}>
        {/* <AutoComplete style={{width: "150px"}} placeholder="Board Number" options={props.boardOptions} />  */}
        <InputNumber style={{width: "150px"}} placeholder="Board Number" /> 
    </Form.Item>
    <Form.Item name={[...thePath, 'port']}>
        {/* <AutoComplete style={{width: "150px"}} placeholder="Port Number" options={props.portOptions} /> */}
        <InputNumber style={{width: "150px"}} placeholder="Port Number" />
    </Form.Item>
    </Space>
</>);
}

const TemperatureCableCustomMappingSelection = (props: CustomMappingProps) => {

    let thePath = [];
    if (Array.isArray(props.customMappingParentName)) {
        thePath = props.customMappingParentName;
    }
    else {
        thePath = [props.customMappingParentName];
    }

    return (<>
        <Row>
            <Col>
                <Form.List name={[...thePath, 'boardNumbers']}>
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                <Text>Board Numbers (ex. 1,2,3,4)</Text>
                                <PlusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        add();
                                    }}
                                />
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined style={{ margin: '8px' }}
                                        onClick={() => {
                                            remove(fields[fields.length - 1].name);
                                        }}
                                    />
                                ) : null}
                                <div style={{ margin: '8px' }}>
                                    {fields.map((field, index) => (
                                        <Form.Item {...field} key={field.key} label={`Bundle ${field.name + 1}`}
                                            rules={[{ required: true, message: 'Enter the board that maps to this Bundle of thermocouples' }]}>
                                            <InputNumber min={0} style={{ width: '75px' }} />
                                        </Form.Item>
                                    ))}
                                </div>
                            </div>
                        );
                    }}
                </Form.List>
            </Col>
            <Col>
                <Form.List name={[...thePath, 'ports']}>
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                <Text>Port Numbers (0,1,2,3)</Text>
                                <PlusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        add();
                                    }}
                                />
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined style={{ margin: '8px' }}
                                        onClick={() => {
                                            remove(fields[fields.length - 1].name);
                                        }}
                                    />
                                ) : null}
                                <div style={{ margin: '8px' }}>
                                    {fields.map((field, index) => (
                                        <Form.Item {...field} key={field.key} label={`Port ${field.name + 1}`}
                                            rules={[{ required: true, message: 'Enter the port (0-indexed) that maps to this Block of thermocouples' }]}>
                                            <InputNumber min={0} style={{ width: '75px' }} />
                                        </Form.Item>
                                    ))}
                                </div>
                            </div>
                        );
                    }}
                </Form.List>
            </Col>
        </Row>
    </>);
}

interface CustomMappingOPIProps {
    fieldName: NamePath,
}

export const CustomMappingOPI = (props: CustomMappingOPIProps) => {
    const form = Form.useFormInstance<any>();
    const opiBoardsDefined = Form.useWatch(['hardware', 'opiMcBoardsCnt'], form);
    const boardOptions = React.useMemo(() => {
        if (opiBoardsDefined == null || opiBoardsDefined < 0) {
            return [];
        }
        return range(1, opiBoardsDefined + 1).map((value) => {return {value: value}});
    }, [opiBoardsDefined]);

    const portOptions = React.useMemo(() => {
        return range(0, 3).map((value) => {return {value: value}});
    }, []);

    return (<>
    <Text strong>OPI Mapping</Text>
    <Form.List name={props.fieldName}>
        {(fields, { add, remove }) => {
            return <React.Fragment>
                                           <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}

                            {fields.map((field, index) => (
                <Form.Item {...field}>
                    <CustomMappingSelection boardGroupLabel={`OPI Cable ${index+1}`} customMappingParentName={field.name} boardOptions={boardOptions} portOptions={portOptions} />
                </Form.Item>
            ))}
            </React.Fragment>
        }}
    </Form.List>
    </>);
}
interface CustomMappingTemperatureCableProps {
    fieldName: NamePath,
}
export const CustomMappingTemperatureCable = (props: CustomMappingTemperatureCableProps) => {
    const form = Form.useFormInstance<any>();
    const SensorBoardsDefined = Form.useWatch(['hardware', 'sensorBoardsCnt'], form);
    const boardOptions = React.useMemo(() => {
        if (SensorBoardsDefined == null || SensorBoardsDefined < 0) {
            return [];
        }
         return range(1, SensorBoardsDefined + 1).map((value) => {return {value: value}});
    }, [SensorBoardsDefined]);

    const portOptions = React.useMemo(() => {
        // start at 1
        return range(1, 3).map((value) => {return {value: value}});
    }, []);

    return (<>
    <Text strong>Temperature cable Mapping</Text>
    <Form.List name={props.fieldName}>
        {(fields, { add, remove }) => {
            return <React.Fragment>
                                           <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}

                            {fields.map((field, index) => (
                                <React.Fragment key={field.key}>
                                    <div>Cable #{field.name + 1}</div>
                    <Form.Item {...field}>
                        <TemperatureCableCustomMappingSelection boardGroupLabel={`Temp Cable ${index+1}`} customMappingParentName={field.name} boardOptions={boardOptions} portOptions={portOptions} />
                    </Form.Item>
                </React.Fragment>
            ))}
            </React.Fragment>
        }}
    </Form.List>
    </>);
}

export const GamCustomMapping = (props: GamFIllProps) => {
    const form = Form.useFormInstance<any>();
    const sensorBoardsDefined = Form.useWatch(['hardware', 'sensorBoardsCnt'], form);
    // @ts-ignore
    window.form = form;

    //console.log("sensing board count: ", sensorBoardsDefined);

    const GAM_INNER_MAPPING_NAME = ["layout", "innerGamMapping"];
    const GAM_OUTER_MAPPING_NAME = ["layout", "outerGamMapping"];
    // const innerGamMappingObjField = Form.useWatch(GAM_INNER_MAPPING_NAME, form);
    // //const outerGamMappingObjField = Form.useWatch(GAM_OUTER_MAPPING_NAME, form);
    // const outerPort = Form.useWatch([...GAM_OUTER_MAPPING_NAME, "port"], form);
    // const layoutField = Form.useWatch("layout", form) as UnifiedDeviceConfigDTO['layout'];
    // const outerGamMappingObjField = layoutField?.outerGamMapping;
    
    // console.log("inner gam obj field: ", innerGamMappingObjField);
    // console.log("outer gam obj field: ", outerGamMappingObjField);
    // console.log("outer gam port number: ", outerPort);
    // console.log("watched layout: ", layoutField);
    

    const boardOptions = React.useMemo(() => {
        if (sensorBoardsDefined == null || sensorBoardsDefined <= 0) {
            return [];
        }
        // boards start at 1
        return range(1, sensorBoardsDefined + 1).map(boardNumber => { return { value: boardNumber }; }
        );
    }, [sensorBoardsDefined]);
    const portOptions = [{ value: 0 }, { value: 1 }];

    // const onInnerGamAddClick = React.useCallback(() => {
    //     //form.setFieldValue(GAM_INNER_MAPPING_NAME, {boardNumber: 1, port: 0});
    //     form.setFieldValue([...GAM_INNER_MAPPING_NAME, "boardNumber"], 1);
    //     form.setFieldValue([...GAM_INNER_MAPPING_NAME, "port"], 0);
    //     console.log(" adding inner gammapping?");

    // }, [form, GAM_INNER_MAPPING_NAME]);

    // const onInnerGamRemoveClick = React.useCallback(() => {
    //     form.setFieldValue(GAM_INNER_MAPPING_NAME, undefined);
    //     console.log(" removing inner gammapping?");

    // }, [form, GAM_INNER_MAPPING_NAME]);

    return (
        <section>
            <Text strong>Inner GAM Mapping</Text>
            <br />
            {/* {innerGamMappingObjField == null && <PlusCircleOutlined onClick={onInnerGamAddClick} />}
            {innerGamMappingObjField != null && <MinusCircleOutlined onClick={onInnerGamRemoveClick} />} */}

            <CustomMappingSelection boardGroupLabel='Sensing Module' customMappingParentName={GAM_INNER_MAPPING_NAME} boardOptions={boardOptions} portOptions={portOptions}  />

            <br />
            {<><Text strong>Outer GAM Mapping</Text>
            <br />
            <CustomMappingSelection boardGroupLabel='Sensing Module' customMappingParentName={GAM_OUTER_MAPPING_NAME} boardOptions={boardOptions} portOptions={portOptions}  />
            </>
}

        </section>
    );

}

const AutomationTypeField = (props: {}) => {

    return (<>
        <Form.Item name={["automationType"]} label="Automation Type">
            <Radio.Group name="automationType">
                <Radio value={"DriStack"}>DriStack</Radio>
                <Radio value={"AutoBin"}>AutoBin</Radio>
                <Radio value={"AutoFan"}>AutoFan</Radio>
            </Radio.Group>

        </Form.Item>
    </>
    );
}

export interface RingListPropertyBaseProps {
    fieldName: NamePath,
    ringLabel: string,
}

export const OpiRhtHeightsSection = (props: RingListPropertyBaseProps) => {
    return (
        <Form.List name={props.fieldName}>
            {(fields, { add, remove }) => {
                return (
                    <div style={{ marginLeft: '20px' }}>
                        <Text>OPI RHT Height (ft): {props.ringLabel}</Text>
                        <PlusCircleOutlined style={{ margin: '8px' }}
                            onClick={() => {
                                add();
                            }}
                        />
                        {fields.length > 0 ? (
                            <MinusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    remove(fields[fields.length - 1].name);
                                }}
                            />
                        ) : null}
                        <div style={{ margin: '8px' }}>
                            {fields.map((field, index) => (
                                <Form.Item {...field} key={field.key} label={(1 + index)} name={[field.name]}
                                    rules={[{ required: true, message: 'Enter the OPI sensor height in ft' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                            ))}
                        </div>
                    </div>
                );
            }}
        </Form.List>
    );
}

export const RhtHeightsSection = (props: RingListPropertyBaseProps) => {
    return (
        <Form.List name={props.fieldName}>
            {(fields, { add, remove }) => {
                return (
                    <div style={{ marginLeft: '20px' }}>
                        <Text>Legacy RHT Height (ft): {props.ringLabel}</Text>
                        <PlusCircleOutlined style={{ margin: '8px' }}
                            onClick={() => {
                                add();
                            }}
                        />
                        {fields.length > 0 ? (
                            <MinusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    remove(fields[fields.length - 1].name);
                                }}
                            />
                        ) : null}
                        <div style={{ margin: '8px' }}>
                            {fields.map((field, index) => (
                                <Form.Item {...field} key={field.key} label={(1 + index)} name={[field.name]}
                                    rules={[{ required: true, message: 'Enter the Legacy RHT sensor height in ft' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                            ))}
                        </div>
                    </div>
                );
            }}
        </Form.List>
    );
}

export const OpiCableBearingSection = (props: RingListPropertyBaseProps) => {
    return (
        <Form.List name={props.fieldName}>
            {(fields, { add, remove }) => {
                return (
                    <div style={{ marginLeft: '20px' }}>
                        <Text>OPI Cable Bearings (deg): {props.ringLabel}</Text>
                        <PlusCircleOutlined style={{ margin: '8px' }}
                            onClick={() => {
                                add();
                            }}
                        />
                        {fields.length > 0 ? (
                            <MinusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    remove(fields[fields.length - 1].name);
                                }}
                            />
                        ) : null}
                        <div style={{ margin: '8px' }}>
                            {fields.map((field, index) => (
                                <Form.Item {...field} key={field.key} label={(1 + index)} name={[field.name]}
                                    rules={[{ required: true, message: 'Enter the OPI cable bearing' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                            ))}
                        </div>
                    </div>
                );
            }}
        </Form.List>
    );
}

export const LayoutHeightsSection = () => {
    return (
        <Form.List name={['layout', 'layersTopHeight']}>
            {(fields, { add, remove }) => {
                return (
                    <div style={{ marginLeft: '20px' }}>
                        <Text>Layer Heights for Bin (ft)</Text>
                        <PlusCircleOutlined style={{ margin: '8px' }}
                            onClick={() => {
                                add();
                            }}
                        />
                        {fields.length > 0 ? (
                            <MinusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    remove(fields[fields.length - 1].name);
                                }}
                            />
                        ) : null}
                        <div style={{ margin: '8px' }}>
                            {fields.map((field, index) => (
                                <Form.Item {...field} key={field.key} label={(1 + index)} name={[field.name]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                            ))}
                        </div>
                    </div>
                );
            }}
        </Form.List>
    );
}

class UpdateDevice extends React.Component<{}, State> {

    private readonly _formRef = React.createRef<FormInstance<UnifiedDeviceConfigDTO>>();
    constructor(props: {}) {
        super(props);
        this.state = {
            binIdList: [],
            chosenBinId: null,
            hardwareYear: null,
            currentBin: null,
            isDeviceTwin: false,
            connectionString: '',
            highPoweredFanCheck: false,
            showModalFromSheet: false,
        };

        this.handleModalSheetClose = this.handleModalSheetClose.bind(this);
        this.handleModalSheetOpen = this.handleModalSheetOpen.bind(this);
    }

    componentDidMount() {
        this.getBinIdData();
    }

    handleModalSheetClose = () => {
        this.setState({showModalFromSheet: false});
    }
    handleModalSheetOpen = () => {
        this.setState({showModalFromSheet: true});
    }
    

    hardwareYearSelected = (event: { target: any; }) => {
        const hardwareYear = Number(event.target!.value);
        this.setState({hardwareYear: hardwareYear});
    }

    hardwareYearInputted = (hardwareYear: number) => {
        this.setState({hardwareYear: hardwareYear});
    }

    populateWirelessMoistureCables = (ring: DesiredRingLayoutDTO) => {
        if (ring?.wirelessMoistureCablesInfo != null ) {
            return [...ring.wirelessMoistureCablesInfo];
        }
        else {
            return [];
        }
    }

    getDefaultForHasOuterRingGAM = (hardwareYear: number | null, layout: DesiredLayoutDTO | null): boolean | null => {
        if (hardwareYear == null) {
            return null;
        }
        if (hardwareYear === 2021) {
            if (layout?.gamHeight != null && layout?.gamHeight > 0) {
                return true;
            } else {
                return false;
            }
        }
        if (hardwareYear >= 2022) {
            if (layout?.outerRingGAMHeight != null && layout.outerRingGAMHeight > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        return null;
    }

    
    getDefaultForHasInnerRingGAM = (hardwareYear: number | null, layout: DesiredLayoutDTO | null): boolean | null => {
        if (hardwareYear == null) {
            return null;
        }
        if (hardwareYear === 2021) {
            return false;
        }
        if (hardwareYear >= 2022) {
            if (layout?.innerRingGAMHeight != null && layout.innerRingGAMHeight > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        return null;
    }

    render() {
        const { chosenBinId, currentBin } = this.state;
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        // Merge the two list arrays so that we can show them together in the form
        // Preferably this would be done already on the server side so that we don't have to mangle the model
        // Also this won't really a DesiredPropertiesDTO object anymore...
        let displayBin: UnifiedDeviceConfigDTO | null = null;
        let centerValveHeights: string = 'Valve Heights: ';
        let perimeterValveHeights: string = '';
        console.log('currentBin', currentBin);
        if (currentBin) {

            displayBin = {
                ...currentBin,
                hardwareYear: this.state.hardwareYear ?? 0,
                automationType: currentBin?.automationType ?? null,
                hasCamera: currentBin?.hasCamera ?? false,
                hardware: {
                    ...(currentBin.hardware || DesiredHardwareDTO.create()),
                    fans: (currentBin.hardware?.fans || 0),
                    highPoweredFans: (currentBin.hardware?.highPoweredFans != null ? currentBin.hardware?.highPoweredFans : true),
                    pneumaticBoardSolenoidCounts: [...(currentBin.hardware?.pneumaticBoardSolenoidCounts || [])],
                    sensorBoardsCnt: currentBin.hardware?.sensorBoardsCnt ?? null,
                    opiMcBoardsCnt: currentBin.hardware?.opiMcBoardsCnt ?? null,
                    rings: [...(currentBin.hardware?.rings?.map(r => ({ ...r })) || [(DesiredRingHardwareDTO.create())])]
                },
                layout: {
                    ...(currentBin.layout || DesiredLayoutDTO.create()),
                    binDiameter: (currentBin.layout?.binDiameter || 0.0),
                    fanBearings: [...(currentBin.layout?.fanBearings || [])],
                    fanDuctArea: [...(currentBin.layout?.fanDuctArea || [])],
                    hasInnerRingGAM: currentBin?.layout?.hasInnerRingGAM ?? this.getDefaultForHasInnerRingGAM(currentBin.hardwareYear, currentBin.layout),
                    hasOuterRingGAM: currentBin?.layout?.hasOuterRingGAM ?? this.getDefaultForHasOuterRingGAM(currentBin.hardwareYear, currentBin.layout),
                    innerGamMapping: currentBin?.layout?.innerGamMapping ?? null,
                    outerGamMapping: currentBin?.layout?.outerGamMapping ?? null,
                    layersTopHeight: currentBin.layout?.layersTopHeight ?? null, // []?
                    rings: currentBin.layout?.rings?.map(r => ({
                        ...r,
                        thermocoupleHeights: [...r.thermocoupleHeights || []],
                        valveHeights: [...r.valveHeights || []],
                        moistureCableBearings: [...r.moistureCableBearings || []],
                        // moistureCablePowercastIds: [...r.moistureCablePowercastIds || Array.from(Array(){length: r.moistureCableBearings?.length || 0}).fill(null)],
                        wirelessMoistureCablesInfo: this.populateWirelessMoistureCables(r),
                        // moistureCablePowercasts: [...r.moistureCablePowercasts || Array.from(Array(r.moistureCableBearings?.length || 0), () => Array.from({length: r.valveHeights?.length || 0}).fill(false)) ],
                        temperatureCableBearings: [...r.temperatureCableBearings || []],
                        stackBearings: [...r.stackBearings || []],
                        rhtsHeights: [...r.rhtsHeights || []],
                        opiRhtsHeights: [...r.opiRhtsHeights || []],
                        temperatureCableMappings: [...r.temperatureCableMappings || []],
                        ...(currentBin.hardware?.rings?.find(hr => hr.ring === r.ring) || {}),

                    } as DesiredRingLayoutDTO)) || [],
                },
                overrides: {
                    ...(currentBin.overrides || DesiredOverridesDTO.create()),
                    keepFansOff: (currentBin.overrides!.keepFansOff != null ? currentBin.overrides!.keepFansOff : true),
                    keepCompressorOff: (currentBin.overrides!.keepCompressorOff != null ? currentBin.overrides!.keepCompressorOff : true),
                    disableErrorEmails: (currentBin.overrides!.disableErrorEmails != null ? currentBin.overrides!.disableErrorEmails : true),
                    grainHeightAtMiddle: (currentBin.overrides?.grainHeightAtMiddle !== undefined ? currentBin.overrides.grainHeightAtMiddle : null),
                    grainHeightAtPeak: (currentBin.overrides?.grainHeightAtPeak ?? null),
                    // !== undefined ? currentBin.overrides.grainHeightAtPeak : null),
                    // enableManualDrying: (currentBin.overrides!.enableManualDrying != null ? currentBin.overrides!.keepFansOff : true),
                    // disableIntelligentDrying: (currentBin.overrides!.disableIntelligentDrying != null ?
                    //    currentBin.overrides!.disableIntelligentDrying : true),
                    emcOffsetsByLayer: [...currentBin.overrides?.emcOffsetsByLayer || [0.0]],
                    emcOffsetString: currentBin.overrides?.emcOffsetString ?? '',
                    dynamicMC: currentBin.overrides?.dynamicMC ?? this.createDynamicMcDefaults(),
                    weatherConditionsMinMc: currentBin?.overrides?.weatherConditionsMinMc ?? null,
                    weatherConditionsMaxMc: currentBin?.overrides?.weatherConditionsMaxMc ?? null,
                    weatherConditionsMinTemperatureF: currentBin?.overrides?.weatherConditionsMinTemperatureF ?? null,
                    weatherConditionsMaxTemperatureF: currentBin?.overrides?.weatherConditionsMaxTemperatureF ?? null,
                    noHeater: (currentBin.overrides?.noHeater ?? null),
                    monitoringBin: (currentBin.overrides?.monitoringBin ?? null),
                    compressorCalibration: (currentBin.overrides?.compressorCalibration ?? null),
                    fansCalibration: (currentBin.overrides?.fansCalibration ?? []),
                    heatersCalibration: (currentBin.overrides?.heatersCalibration ?? []),
                    plenumPressureOffsets: (currentBin.overrides?.plenumPressureOffsets ?? []),
                    plenumHighTempF: (currentBin.overrides?.plenumHighTempF ?? null),
                    commonCloseTimePeriod: (currentBin.overrides?.commonCloseTimePeriod ?? null),
                    restackExtraExhaust: (currentBin.overrides?.restackExtraExhaust ?? null),
                    restackExtraVent: (currentBin.overrides?.restackExtraVent ?? null),
                    crossflowExtraExhaust: (currentBin?.overrides?.crossflowExtraExhaust ?? true),
                    crossflowExtraVent: (currentBin.overrides?.crossflowExtraVent ?? true),
                    repeatPreDry: (currentBin.overrides?.repeatPreDry ?? null),
                    // fix for DatePicker failing to parse date.clone is not a function  https://github.com/ant-design/ant-design/discussions/35437
                    fanStaggerSeconds: (currentBin?.overrides?.fanStaggerSeconds ?? null),
                } as DesiredOverridesDTO
            };
            currentBin?.layout?.rings![0]?.valveHeights?.forEach((val, index) => {
                if (index > 0) {
                    centerValveHeights += ', ';
                }
                centerValveHeights += ' ' + (1 + index).toString() + ':' + val + 'ft ';
            });
            currentBin?.layout?.rings![1]?.valveHeights?.forEach((val, index) => {
                if (index > 0) {
                    perimeterValveHeights += ', ';
                }
                perimeterValveHeights += ' ' + (1 + index).toString() + ':' + val + 'ft';
            });

            centerValveHeights += ' Valves above grain height will not be used';
            perimeterValveHeights = 'Valve Heights: ' + perimeterValveHeights + ' Valves above grain height will not be used';
            console.log('displayBin', displayBin);
        }

        const ConfirmYear = () => {
            return <>
            <h1>Please confirm the bin hardware revision year</h1>
            <Radio.Group name="hardwareYearSelect" onChange={this.hardwareYearSelected}>
                <Radio value={2021}>2021</Radio>
                <Radio value={2022}>2022</Radio>
                <Radio value={2023}>2023</Radio>
                {/* <Radio value={2024}>2024</Radio> */}
            </Radio.Group>
            </>;
        }

        const PLENUM_HIGH_TEMP_DEFAULT = 105;

        return (
            <Layout.Content className="createbin">
                <Text>Device:</Text>
                <Select style={{width: "32ch", maxHeight: "300px", overflow: "auto"}} placeholder="Select Device" showSearch optionFilterProp="children" onSelect={(value: number | string | LabeledValue, option: typeof Option) => this.binIdClicked(value.toString(), this.state.isDeviceTwin)} >
                    {this.binIdDropdownValues()}
                </Select>
                {chosenBinId && displayBin && ((this.state.hardwareYear || 0) === 0) ? <ConfirmYear /> : null}
                {chosenBinId && displayBin && ((this.state.hardwareYear || 0) > 0) ?
                    <>
                    <Row>
                        <Col>
                            <Button icon={<EditOutlined />} onClick={this.handleModalSheetOpen}>Fill from Module Twin spreadsheet</Button>
                        </Col>
                    </Row>
                    <DeviceUpdateModal open={this.state.showModalFromSheet} formRef={this._formRef} onOk={this.handleModalSheetClose} onCancel={this.handleModalSheetClose} />
                        <Row justify="end">
                            <Col span={20}>
                                <Descriptions layout="vertical" style={{ fontWeight: 'bold', paddingBottom: 16 }} bordered={true} >
                                    <Descriptions.Item label="Connection String">{this.state.connectionString}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LogViewer deviceId={this.state.chosenBinId!} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form scrollToFirstError={true} autoComplete="off" onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} initialValues={displayBin} ref={this._formRef}>
                                    <div>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>

                                    {/*Overrides*/}
                                    <fieldset>
                                        <legend><b>Overrides</b>  -
                                            <i><small>Changes to this section will be picked up by the edge device automatically on save</small></i>
                                        </legend>
                                        <div>
                                            <Form.Item
                                                {...layout}
                                                label="No Heater"
                                                name={['overrides', 'noHeater']}
                                                valuePropName="checked"
                                                rules={[]}>
                                                <Switch defaultChecked={displayBin.overrides?.noHeater ?? false} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Keep Fans Off"
                                                name={['overrides', 'keepFansOff']}
                                                valuePropName="checked"
                                                rules={[{ required: false, message: 'Set If The Fans should Stay off' }]}>
                                                <Switch defaultChecked={displayBin.overrides?.keepFansOff} />
                                            </Form.Item>
                                            <Form.Item
                                                {...layout}
                                                label="Keep Compressor Off"
                                                name={['overrides', 'keepCompressorOff']}
                                                valuePropName="checked"
                                                rules={[{ required: false, message: 'Set If The Compressor should Stay off' }]}>
                                                <Switch defaultChecked={displayBin.overrides?.keepCompressorOff}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...layout}
                                                label="Disable Error Emails"
                                                name={['overrides', 'disableErrorEmails']}
                                                valuePropName="checked"
                                                rules={[{ required: false, message: 'Set To Disable Error Emails' }]}>
                                                <Switch defaultChecked={displayBin.overrides?.disableErrorEmails} />
                                            </Form.Item>
                                            <Form.Item
                                                {...layout}
                                                label="High Powered Fans"
                                                name={['hardware', 'highPoweredFans']}
                                                valuePropName="checked"
                                                rules={[{ required: false, message: 'Check if Fans Are High Powered' }]}>
                                                <Switch defaultChecked={displayBin.hardware?.highPoweredFans} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Repeat PreDry"
                                                name={['overrides', 'repeatPreDry']}
                                                valuePropName="checked"
                                                extra="Repeat PreDry when entering dry mode, even if the PreDry cycle has been completed before"
                                                >
                                                    <Switch />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Monitoring Bin (rare)"
                                                name={['overrides', 'monitoringBin']}
                                                valuePropName="checked"
                                                rules={[]}>
                                                <Switch defaultChecked={displayBin.overrides?.monitoringBin ?? false} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Restack Extra Exhaust"
                                                name={['overrides', 'restackExtraExhaust']}
                                                valuePropName="checked"
                                                extra="During Restack: Adds an extra exhaust layer below the target layer"
                                                >
                                                    <Switch defaultChecked={displayBin.overrides?.restackExtraExhaust ?? undefined} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Restack Extra Vent"
                                                name={['overrides', 'restackExtraVent']}
                                                valuePropName="checked"
                                                extra="During Restack: Opens an extra valve (vent) above the target layer"
                                                >
                                                    <Switch defaultChecked={displayBin.overrides?.restackExtraVent ?? undefined} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Crossflow Extra Exhaust"
                                                name={['overrides', 'crossflowExtraExhaust']}
                                                valuePropName="checked"
                                                extra="During Crossflow: Opens two valve (vent) above the target layer if not floor and true"
                                                >
                                                    <Switch defaultChecked={displayBin.overrides?.crossflowExtraExhaust ?? true} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Crossflow Extra Vent"
                                                name={['overrides', 'crossflowExtraVent']}
                                                valuePropName="checked"
                                                extra="During Crossflow: Opens two exhaust (exhaust) below the target layer if not floor and true"
                                                >
                                                    <Switch defaultChecked={displayBin.overrides?.crossflowExtraVent ?? true} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Plenum High Temp (°F)"
                                                extra={`Plenum temperature (${PLENUM_HIGH_TEMP_DEFAULT} if not set) that triggers heater shutoff and possibly fan shutoff. Change to 120 if a high temp bin`}
                                                name={['overrides', 'plenumHighTempF']}
                                                rules={[{ required: false, message: 'Change to 120 if a high temp bin' }]}>
                                                <InputNumber placeholder={`${PLENUM_HIGH_TEMP_DEFAULT}`} min={0} style={{width: "8ch"}} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="commonCloseTimePeriod (Seconds)"
                                                extra="The amount of seconds common close solenoids must be turned on to completely close spools"
                                                name={['overrides', 'commonCloseTimePeriod']}
                                                rules={[{ required: false }]}>
                                                <InputNumber min={0} style={{ width: '75px' }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                help={centerValveHeights}
                                                label="Grain Height-Center Stack (ft)"
                                                name={['overrides', 'grainHeightAtPeak']}
                                                rules={[{ required: false, message: 'Enter Grain Height at Center Stack' }]}>
                                                <InputNumber min={0} style={{ width: '75px' }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                help={perimeterValveHeights}
                                                label="Grain Height-Perimeter Stacks (ft)"
                                                name={['overrides', 'grainHeightAtMiddle']}
                                                rules={[{ required: false, message: 'Enter Grain Height at Permiter Stacks' }]}>
                                                <InputNumber min={0} style={{ width: '75px' }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Fan Stagger Seconds"
                                                extra="The amount of seconds each fan startup is staggered by, default 1 second"
                                                name={['overrides', 'fanStaggerSeconds']}
                                                rules={[{ required: false }]}>
                                                <InputNumber min={0} style={{ width: '75px' }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                help="Enter comma separated values (ex: 1,1.5,-1)"

                                                label="EMC Offsets By Layer"
                                                name={['overrides', 'emcOffsetString']}
                                                rules={[{ required: false, message: 'Enter The EMC Offsets for each latyer ' }]}>
                                                <Input style={{ width: '250px' }} placeholder="Enter comma separated values (ex: 1,1.5,-1)" />
                                            </Form.Item>
                                            {this.getDynamicMcSection()}
                                            {/* {this.getLayerDryTimeSection()} */}

                                            <Form.Item
                                                {...layout}
                                                label="Initial Grain Temperature (°F)"
                                                name={['overrides', 'initialGrainTempF']}
                                                rules={[{ required: false, message: 'Enter the initial temperature of the grain' }]}>
                                                <InputNumber min={0} max={213} style={{ width: '75px' }}  />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Hotspot Threshold (°F)"
                                                extra="Sensor temperatures above this value are detected as hotspots"
                                                name={['hotSpotThresholdTemperatureF']}>
                                                <InputNumber style={{ width: '75px' }}  />
                                            </Form.Item>

                                            <Form.Item hidden
                                                {...layout}
                                                label="Weather Min MC (%)"
                                                name={['overrides', 'weatherConditionsMinMc']}
                                                help="System will pause if below value. Applies to FIll, Dry, and Storage"
                                                >
                                                <InputNumber style={{ width: '75px' }}
                                                    min={0} max={100} />
                                            </Form.Item>

                                            <Form.Item hidden
                                                {...layout}
                                                label="Weather Max MC (%)"
                                                name={['overrides', 'weatherConditionsMaxMc']}
                                                help="System will pause if above value. Applies to FIll, Dry, and Storage"
                                                >
                                                <InputNumber style={{ width: '75px' }}
                                                    min={0} max={100} />
                                            </Form.Item>

                                            <Form.Item hidden
                                                {...layout}
                                                label="Weather Min Temperature (℉)"
                                                name={['overrides', 'weatherConditionsMinTemperatureF']}
                                                help="System will pause if below value. Applies to FIll, Dry, and Storage"
                                                >
                                                <InputNumber style={{ width: '75px' }} />
                                            </Form.Item>

                                            <Form.Item hidden
                                                {...layout}
                                                label="Weather Max Temperature (℉)"
                                                name={['overrides', 'weatherConditionsMaxTemperatureF']}
                                                help="System will pause if above value. Applies to FIll, Dry, and Storage"
                                                >
                                                <InputNumber style={{ width: '75px' }} />
                                            </Form.Item>



                                            <Typography.Title level={4}>Calibrations</Typography.Title>
                                            {this.getFansCalibrationOverrideSection()}
                                            {this.getCompressorCalibrationOverrideSection()}
                                            {this.getHeatersCalibrationOverrideSection()}
                                            {this.getPlenumPressureOffsetsSection()}
                                            <Typography.Title level={5}>Preferred sensors for reading types</Typography.Title>
                                            {this.getPreferredSensorTypeOverrideSection()}
                                            <Typography.Title level={5}>Board Hardware Year (change if differs from bin-level hardware year)</Typography.Title>
                                            {this.getHardwareYearBoardSection()}
                                            <Typography.Title level={5}>PID Controller auto layer time adjustment</Typography.Title>
                                            {this.getPIDControllerOverridesSection()}
                                            
                                        </div>
                                    </fieldset>
                                    <hr />

                                    {/*Misc*/}
                                    <fieldset> <legend><b>Misc</b>  -
                                        <i><small>various settings</small></i>
                                    </legend>
                                        <div>
                                            <Form.Item {...layout} label="To Email" name="toEmail"
                                                rules={[{ required: true, message: 'Grower email address' }]}>
                                                <Input style={{ width: '40%' }} />
                                            </Form.Item>
                                            <Form.Item {...layout} label="Additional Properties" name="additionalProperties">
                                                <Input style={{ width: '40%' }} />
                                            </Form.Item>
                                        </div>
                                    </fieldset>
                                    <hr />

                                    {/*Hardware*/}
                                    <fieldset> <legend><b>Hardware & Layout</b>  -
                                        <i><small>Changes to this section require restarting the module</small></i>
                                    </legend>
                                    <Row style={{justifyContent: "flex-end"}}>
                                        <Col >
                                            <RestartDriStackModuleButton deviceId={this.state.chosenBinId!} />
                                        </Col>
                                    </Row>
                                        <div>
                                            <Row>
                                                <Col span={12}>

                                                    <Form.Item {...layout} label="Bin Name" name="binName"
                                                        rules={[{ required: true, message: 'Enter the name of the bin' }]}>
                                                        <Input style={{ width: '50%' }} />
                                                    </Form.Item>

                                                    <Form.Item
                                                    {...layout}
                                                        label="Hardware Year"
                                                        extra="Only change this if the hardware revision year was improperly selected. Reverify the GAM settings are correct"
                                                        name={'hardwareYear'}
                                                        rules={[{ required: true, message: 'Hardware revision year - 2021 or 2022' }]}>
                                                        <Radio.Group name="hardwareYearSelect" onChange={this.hardwareYearSelected}>
                                                            <Radio value={2021}>2021</Radio>
                                                            <Radio value={2022}>2022</Radio>
                                                            <Radio value={2023}>2023</Radio>
                                                            {/* <Radio value={2024}>2024</Radio> */}
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    <Form.Item
                                                    {...layout}
                                                        label="Has Camera"
                                                        extra="If the device has a camera"
                                                        valuePropName="checked"
                                                        name={['hasCamera']}
                                                        >
                                                        <Switch />
                                                    </Form.Item>

                                                    <AutomationTypeField />
                                                    <Form.Item
                                                        {...layout}
                                                        label="Fans"
                                                        name={['hardware', 'fans']}
                                                        rules={[{ required: true, message: 'Enter the number of fans' }]}>
                                                        <InputNumber min={0} style={{ width: '75px' }} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...layout}
                                                        label="Bin Diameter"
                                                        name={['layout', 'binDiameter']}
                                                        rules={[{ required: true, message: 'Enter the bin diameter' }]}>
                                                        <InputNumber min={0} style={{ width: '75px' }} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...layout}
                                                        label="Grain Eave Height"
                                                        name={['layout', 'grainEaveHeight']}
                                                        extra="Maximum height of the grain at the eaves, in feet, not including the plenum height."
                                                        rules={[{ required: true, message: 'Enter Grain Eave Height' }]}>
                                                        <InputNumber min={0} style={{ width: '75px' }} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...layout}
                                                        label="Sensing Module Count"
                                                        name={['hardware', 'sensorBoardsCnt']}
                                                        help="Fill if SHB(s) require custom port mapping. MUST fill in port mapping for GAM(s) & Temperature cables "
                                                        >
                                                        <InputNumber min={0} style={{ width: '75px' }} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...layout}
                                                        label="OPI Module Count"
                                                        name={['hardware', 'opiMcBoardsCnt']}
                                                        help="Fill if OPI boards require custom port mapping. MUST fill in port mapping for OPI cables"
                                                        >
                                                        <InputNumber min={0} style={{ width: '75px' }} />
                                                    </Form.Item>

                                                    <LayoutHeightsSection />

                                                    {this.getGamList(this.state.hardwareYear)}
                                                    <Form.Item
                                                {...layout}
                                                label="Has Inner GAM"
                                                name={['layout', 'hasInnerRingGAM']}
                                                valuePropName="checked">
                                                <Switch />
                                            </Form.Item>

                                            <Form.Item
                                                {...layout}
                                                label="Has Outer GAM"
                                                name={['layout', 'hasOuterRingGAM']}
                                                valuePropName="checked">
                                                <Switch />
                                            </Form.Item>


                                                    {this.getPneumaticBoardSection()}
                                                    {this.getFanBearingsSection()}
                                                    {this.getFanDuctAreaSection()}
                                                </Col>
                                                <Col span={8}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <figure >
                                                            <Image title="Reference compass for bearing in degrees" alt="Compass degree reference inputting bearing of items. N0, E90, S180, W270" src={compassUrl} />
                                                            <figcaption >Compass bearing degrees
                                                                <br />
                                                                <a target="_blank noreferrer noopener" href="https://commons.wikimedia.org/wiki/File:Compass_Card_B%2BW.svg"> Original: Denelson83 Derivative work: smial</a>, <a href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>, via Wikimedia Commons
                                                            </figcaption>
                                                        </figure>
                                                    </div>

                                                </Col>
                                            </Row>
                                            {this.getRingsLayoutSection()}

                                        </div>
                                    </fieldset>
                                    <div>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>

                            </Col>
                        </Row>
                    </>
                    : undefined
                }
            </Layout.Content>
        );
    }

    private getBinIdData = () => {
        AdminApiService.getAllBins().then(binIDs => {
            this.setState({
                binIdList: binIDs
            });

        }).catch(error => {
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }

    private binIdClicked = (id: string, isDeviceTwin: boolean) => {
        AdminApiService.getBinByID(id, isDeviceTwin).then(bin => {
            this.setState({
                chosenBinId: id,
                currentBin: bin,
                hardwareYear: bin.hardwareYear,
                highPoweredFanCheck: bin.hardware?.highPoweredFans || false
            });

            this._formRef.current?.resetFields();
        }).catch(error => {
            notification.error({
                message: error.message,
                description: error.description
            });
        });
        AdminApiService.getDeviceConnectionString(id).then(connectionString => {
            this.setState({
                connectionString: connectionString
            });
        });
    }

    private binIdDropdownValues = () => {
        return (
            <>
                {this.state.binIdList?.map((id, index) => (
                    <Option key={id} value={id}>{id}</Option>
                ))}
            </>
        );
    }

    private onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
      };

    
    private createDynamicMcDefaults = (): DesiredDynamicMCDTO => {
        const fresh = DesiredDynamicMCDTO.create();
        fresh.gainByLayerString = '';
        fresh.mcOffsetsByLayerString = '';
        return fresh;
    }


    private ParseDynamicMCForm = (dynamicMcFormValues: DesiredDynamicMCDTO): DesiredDynamicMCDTO => {
        const nextState = produce(dynamicMcFormValues, draft => {

            // empty strings result in [NaN] array
            if (draft.gainByLayerString?.length === 0) {
                draft.gainByLayer = [];
            }
            else {
                draft.gainByLayer = draft.gainByLayerString?.split(',')?.map((a: string) => parseFloat(a)) ?? [];
            }

            if (draft.mcOffsetsByLayerString?.length === 0) {
                draft.mcOffsetsByLayer = [];
            }
            else {
                draft.mcOffsetsByLayer = draft.mcOffsetsByLayerString?.split(",")?.map((a: string) => parseFloat(a)) ?? [];
            }
        });
        return nextState;
    }

    private onFinish = (values: any) => {
        let layoutRings: DesiredRingLayoutDTO[] = [];
        let hardwareRings: DesiredRingHardwareDTO[] = [];
        console.log('values', values);

        const automationType = selectAutomationType(values.automationType);
        values.layout.rings.map((element: any, index: number) => {
            layoutRings.push({
                ring: element.ring,
                radius: element.radius,
                thermocoupleHeights: element.thermocoupleHeights,
                valveHeights: element.valveHeights,
                stackHeight: element.stackHeight,
                stackBearings: element.stackBearings,
                moistureCableBearings: element.moistureCableBearings ?? [],
                temperatureCableBearings: element.temperatureCableBearings,
                temperatureCableMappings: this.state.currentBin?.layout?.rings?.[index]?.temperatureCableMappings,
                //temperatureCableMappings: null,
                wirelessMoistureCablesInfo: element.wirelessMoistureCablesInfo ?? [],
                opiMcCableMappings: element.opiMcCableMappings,
                opiRhtsHeights: element.opiRhtsHeights,
                opiMoistureCableBearings: element.opiMoistureCableBearings,
                rhtsHeights: element.rhtsHeights,
                layers: element.layers,
                newTemperatureCableMappings: element.newTemperatureCableMappings,
            } as DesiredRingLayoutDTO);
            let rhtsPerCable = element.rhtsHeights;
            if (automationType == AutomationType.AutoBin || AutomationType.AutoFan) {
                // only for autobin/autoFan
                rhtsPerCable = element.rhtsHeights?.length ?? 0;
            }
            hardwareRings.push({
                ring: element.ring,
                stacks: element.stacks ?? 0,
                valves: element.valveHeights?.length ?? 0,
                thermocouples: element.thermocoupleHeights?.length ?? 0,
                moistureCables: element.moistureCableBearings?.length ?? 0,
                temperatureCables: element.temperatureCableBearings?.length ?? 0,
                wirelessMoistureCables: element.wirelessMoistureCablesInfo?.length ?? 0,
                opiMoistureCables: element.opiMoistureCableBearings?.length ?? 0,
                opiRhtsPerCable: element.opiRhtsHeights?.length ?? 0,
                layers: element.layers,
                rhtsPerCable: rhtsPerCable,
            } as DesiredRingHardwareDTO);
        });
        let fanBearings: number[] = [];
        values.layout.fanBearings.map((fanBearing: number) => {
            fanBearings.push(fanBearing);
        });
        let pneumaticBoardSolenoidCounts: number[] = [];
        // let layerDryTimeAdjustFactors: number[] = [];
        values.hardware.pneumaticBoardSolenoidCounts.map((count: number) => {
            pneumaticBoardSolenoidCounts.push(count);
        });
        // values.overrides.layerDryTimeAdjustFactors.map((count: number) => {
        //     layerDryTimeAdjustFactors.push(count);
        // });

        // todo: This is a workaround until it is figured out how to set the parent form item to null in Form.Item layout.InnerGamMapping = null
        let innerGamMappingSumbission = null;
        if (values.layout?.innerGamMapping?.port != null) {
            innerGamMappingSumbission = values.layout?.innerGamMapping;
        }
        let outerGamMappingSubmission = null;
        if (values.layout?.outerGamMapping?.port != null) {
            outerGamMappingSubmission = values.layout?.outerGamMapping;
        }

        let hasInnerRingGAM = values.layout.hasInnerRingGAM;
        let hasOuterRingGAM = values.layout.hasOuterRingGAM;
        hasInnerRingGAM = this.getDefaultForHasInnerRingGAM(values.hardwareYear, values.layout);
        hasOuterRingGAM = this.getDefaultForHasOuterRingGAM(values.hardwareYear, values.layout);

        let layout = {
            binDiameter: values.layout.binDiameter,
            grainEaveHeight: values.layout.grainEaveHeight,
            rings: layoutRings,
            gamRadius: values.layout.gamRadius,
            gamHeight: values.layout.gamHeight,
            gamBearing: values.layout.gamBearing,
            gams: values.layout.gams,
            hasInnerRingGAM: hasInnerRingGAM,
            hasOuterRingGAM: hasOuterRingGAM,
            innerRingGAMBearing: values.layout.innerRingGAMBearing,
            innerRingGAMHeight: values.layout.innerRingGAMHeight,
            innerRingGAMRadius: values.layout.innerRingGAMRadius,
            outerRingGAMBearing: values.layout.outerRingGAMBearing,
            outerRingGAMHeight: values.layout.outerRingGAMHeight,
            outerRingGAMRadius: values.layout.outerRingGAMRadius,
            fanBearings: values.layout.fanBearings,
            fanDuctArea: values.layout.fanDuctArea,
            // check here for invalid mappings
            outerGamMapping: outerGamMappingSubmission,
            innerGamMapping: innerGamMappingSumbission,
            layersTopHeight: values.layout.layersTopHeight,
        } as DesiredLayoutDTO;
        let hardware = {
            ...values.hardware ?? {},
            hardwareYear: values.hardwareYear,
            fans: values.hardware.fans,
            highPoweredFans: values.hardware.highPoweredFans,
            rings: hardwareRings,
            pneumaticBoardSolenoidCounts: (pneumaticBoardSolenoidCounts?.length ?? 0) === 0 ? undefined : pneumaticBoardSolenoidCounts,
            sensorBoardsCnt: values.hardware?.sensorBoardsCnt,
            opiMcBoardsCnt: values.hardware?.opiMcBoardsCnt,
        } as DesiredHardwareDTO;

        const valueOverrides = values.overrides as DesiredOverridesDTO;
        let overrides = {
            keepFansOff: values.overrides.keepFansOff,
            keepCompressorOff: values.overrides.keepCompressorOff,
            disableErrorEmails: values.overrides.disableErrorEmails,
            grainHeightAtMiddle: values.overrides.grainHeightAtMiddle,
            grainHeightAtPeak: values.overrides.grainHeightAtPeak,
            emcOffsetString: values.overrides.emcOffsetString,
            emcOffsetsByLayer: values.overrides.emcOffsetString.split(',').filter((maybeNumber: string) => Number.isFinite(parseFloat(maybeNumber))).map((a: any) => parseFloat(a)),
            dynamicMC: this.ParseDynamicMCForm(values.overrides.dynamicMC),
            // fanPause_RHThreshold: values.overrides.fanPause_RHThreshold,
            // wipe out old values, replaced with EMCThreshold
            weatherConditionsMinMc: valueOverrides?.weatherConditionsMinMc,
            weatherConditionsMaxMc: valueOverrides?.weatherConditionsMaxMc,
            weatherConditionsMinTemperatureF: valueOverrides?.weatherConditionsMinTemperatureF,
            weatherConditionsMaxTemperatureF: valueOverrides?.weatherConditionsMaxTemperatureF,
            noHeater: values.overrides.noHeater,
            monitoringBin: values.overrides.monitoringBin,
            compressorCalibration: values.overrides?.compressorCalibration,
            fansCalibration: values.overrides?.fansCalibration,
            heatersCalibration: values.overrides?.heatersCalibration,
            plenumPressureOffsets: values.overrides?.plenumPressureOffsets,
            initialGrainTempF: values.overrides?.initialGrainTempF,
            plenumHighTempF: values.overrides?.plenumHighTempF,
            commonCloseTimePeriod: values.overrides?.commonCloseTimePeriod,
            restackExtraExhaust: values.overrides?.restackExtraExhaust,
            restackExtraVent: values.overrides?.restackExtraVent,
            crossflowExtraExhaust: values.overrides?.crossflowExtraExhaust,
            crossflowExtraVent: values.overrides?.crossflowExtraVent,
            repeatPreDry: values.overrides?.repeatPreDry,
            fanStaggerSeconds: values?.overrides?.fanStaggerSeconds,
            kpLayerTimeAdjustment: values?.overrides?.kpLayerTimeAdjustment,
            kiLayerTimeAdjustment: values?.overrides?.kiLayerTimeAdjustment,
            kdLayerTimeAdjustment: values?.overrides?.kdLayerTimeAdjustment,
            rhSensorsType: values?.overrides?.rhSensorsType,
            temperatureSensorsType: values?.overrides?.temperatureSensorsType,
        } as DesiredOverridesDTO;

        let bin = {
            ...values,
            binId: this.state.currentBin?.binId,
            binName: values.binName,
            hardwareYear: values.hardwareYear,
            hasCamera: values.hasCamera,
            automationType: values.automationType,
            toEmail : values.toEmail,
            additionalProperties : values.additionalProperties,
            layout: layout,
            hardware: hardware,
            overrides: overrides
        } as UnifiedDeviceConfigDTO;

        console.log(bin);
        AdminApiService.setBin(this.state.chosenBinId || '', this.state.isDeviceTwin, bin).then(() => {
            notification.success({
                message: 'Device Updated Successfully'
            });
            this.setState({
                chosenBinId: null,
                hardwareYear: null,
            });
        }).catch(error => {
            console.log(error)
            notification.error({
                message: error.message,
                description: error.errorDetails.detail
            });
        });
    }

    private getPlenumPressureOffsetsSection = () => {
        return (
            <Form.List name={["overrides", "plenumPressureOffsets"]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Plenum InH2O offsets for each fan</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Row>
                                        <Col span={18}>
                                            <Form.Item {...field} name={[field.name, "pressureOffset_InH2O"]} key={field.key} label={`F${index + 1} pressure offset (InH2O)`}
                                                rules={[{ required: false, message: 'Enter the pressure InH2O offset' }]}>
                                                <InputNumber style={{ width: '75px' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getFansCalibrationOverrideSection = () => {
        return (
            <Form.List name={["overrides", "fansCalibration"]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Fans On MilliAmps</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <React.Fragment key={field.name}>

                                        <Row>
                                            <Col span={18}>
                                                <Form.Item {...field} name={[field.name, "onCurrent_mA"]} key={`${field.key}-onCurrent_mA`} label={`F${index + 1} on (mA)`}
                                                    rules={[{ required: false, message: 'Enter the on mA current' }]}>
                                                    <InputNumber min={0} style={{ width: '75px' }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getHeatersCalibrationOverrideSection = () => {
        return (
            <Form.List name={["overrides", "heatersCalibration"]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>heaters On milliAmps</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <React.Fragment key={field.name}>

                                        <Row>
                                            <Col span={18}>
                                                <Form.Item {...field} name={[field.name, "onCurrent_mA"]} key={`${field.key}-onCurrent_mA`} label={`F${index + 1} Heater on (mA)`}
                                                    rules={[{ required: false, message: 'Enter the on mA current' }]}>
                                                    <InputNumber min={0} style={{ width: '75px' }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getCompressorCalibrationOverrideSection = () => {
        const compressoroverrideName = ["overrides", "compressorCalibration"];
        return <>
            <Text>Compressor On MilliAmps</Text>
            <div style={{ margin: '8px' }}>

                <Row>
                    <Col span={18}>
                        <Form.Item name={[...compressoroverrideName, "onCurrent_mA"]} key={`onCurrent_mA`} label={`on (mA)`}
                            rules={[{ required: false, message: 'Enter the on mA current' }]}>
                            <InputNumber min={0} style={{ width: '75px' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>;
    }

    private getPIDControllerOverridesSection = () => {
        const layout = {
            labelCol: { xs: 8, md: 6},
            wrapperCol: { xs: 16, md: 18},
        };
        return <>

            <Form.Item {...layout} name={["overrides", "kpLayerTimeAdjustment"]}
                extra="Kp constant for the auto layer time adjustment PID controller"
                label={`PID kpLayerTimeAdjustment`}>
                <InputNumber style={{ width: '75px' }} />
            </Form.Item>
            
            <Form.Item {...layout} name={["overrides", "kiLayerTimeAdjustment"]}
                extra="Ki constant for the auto layer time adjustment PID controller"
                label={`PID kiLayerTimeAdjustment`}>
                <InputNumber style={{ width: '75px' }} />
            </Form.Item>

            <Form.Item {...layout} name={["overrides", "kdLayerTimeAdjustment"]}
                extra="Kd constant for the auto layer time adjustment PID controller"
                label={`PID kdLayerTimeAdjustment`}>
                <InputNumber style={{ width: '75px' }} />
            </Form.Item>
        </>;
    }

    private getPreferredSensorTypeOverrideSection = () => {

        const rhSensorTypes = Object.values(RhSensorEnum).map(sensorType => {
            return {label: sensorType, value: sensorType};
        })
        const temperatureSensorTypes = Object.values(TemperatureSensorEnum).map(sensorType => {
            return {label: sensorType, value: sensorType};
        })
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return <>

            <Form.Item {...layout} name={["overrides", "rhSensorsType"]}
                extra="The preferred sensor type to use for RH readings. If blank, OPI is the default"
                label={`RH preferred sensor type`}>
                <Select style={{maxWidth: "196px"}} allowClear showSearch options={rhSensorTypes} />
            </Form.Item>
            
            <Form.Item {...layout} name={["overrides", "temperatureSensorsType"]}
                extra="The preferred sensor type to use for temperature readings. If blank, thermocouples are the default"
                label={`Temperature preferred sensor type`}>
                <Select style={{maxWidth: "196px"}} allowClear showSearch options={temperatureSensorTypes} />
            </Form.Item>
        </>;
    }

    private getHardwareYearBoardSection = () => {
        const hardwareYearValues = Object.values(HardwareYear).flatMap(year => {
            // only show integer years
            if (typeof year === 'string') {
                return [];
            }
            return [{label: year, value: year}];
        })

        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        const selectWidth = "128px";
        return <>

            <Form.Item {...layout} name={["hardware", "hubBoardYear"]}
                extra="Hardware year of hub board. If blank, system hardware year is used by system"
                label={`Hub Board Year`}>
                <Select style={{maxWidth: selectWidth}} allowClear showSearch options={hardwareYearValues} />
            </Form.Item>
            
            <Form.Item {...layout} name={["hardware", "fanBoardsYear"]}
                extra="Hardware year of fan board(s). If blank, system hardware year is used by system"
                label={`Fan Board Year`}>
                <Select style={{maxWidth: selectWidth}} allowClear showSearch options={hardwareYearValues} />
            </Form.Item>

            <Form.Item {...layout} name={["hardware", "stackBoardsYear"]}
                extra="Hardware year of Stack board(s). If blank, system hardware year is used by system. Note: Must change bin hardware year to 2022+ for this setting to take effect"
                label={`Stack Board Year (2022+ only)`}>
                <Select style={{maxWidth: selectWidth}} allowClear showSearch options={hardwareYearValues} />
            </Form.Item>

            <Form.Item {...layout} name={["hardware", "sensorBoardsYear"]}
                extra="Hardware year of Sensor board(s). If blank, system hardware year is used by system"
                label={`Sensor Board Year`}>
                <Select style={{maxWidth: selectWidth}} allowClear showSearch options={hardwareYearValues} />
            </Form.Item>
        </>;
    }


    private getGamList = (hardwareYear: number | null) => {
        if (hardwareYear == null) {
            console.warn("hardwareYear == null");
        }
        const formLayout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };

        const innerGamName = "Ring 0 GAM";
        const outerGamName = "Ring 1 GAM";

        const gams2021Form =<>
        <Text>Gams</Text>
        <br />
        <Text><b>{outerGamName}</b></Text>
        <br />
        <Form.Item
            {...formLayout}
            label={`Radius (ft)`}
            name={['layout', 'gamRadius']}
            rules={[{ required: true, message: 'Enter GAM Radius' }]}>
            <InputNumber min={0} style={{ width: '75px' }} />
        </Form.Item>
        <Form.Item
            {...formLayout}
            label={`Height (ft)`}
            name={['layout', 'gamHeight']}
            rules={[{ required: true, message: 'Enter GAM Height' }]}>
            <InputNumber min={0} style={{ width: '75px' }} />
        </Form.Item>
        <Form.Item
            {...formLayout}
            label={`Bearing (deg)`}
            name={['layout', 'gamBearing']}
            rules={[{ required: true, message: 'Enter GAM Bearing' }]}>
            <InputNumber min={0} style={{ width: '75px' }} />
        </Form.Item>

        <GamCustomMapping />
        
    </>;


        if (hardwareYear == 2020 || hardwareYear == 2021) {
            return gams2021Form;
        }

        if ((hardwareYear ?? 0) >= 2022) {
            return (<>
                        <Text>Gams</Text>
                        <br />
                        <Text><b>{innerGamName}</b></Text>
                        <br />
            <Form.Item
                                    {...formLayout}
                                    label={`Radius (ft)`}
                                    name={['layout', 'innerRingGAMRadius']}
                                    rules={[{ required: true, message: 'Enter GAM Radius' }]}>
                                    <InputNumber min={0.00} style={{ width: '75px' }} />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label={`Height (ft)`}
                                    name={['layout', 'innerRingGAMHeight']}
                                    rules={[{ required: true, message: 'Enter GAM Height' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label={`Bearing (deg)`}
                                    name={['layout', 'innerRingGAMBearing']}
                                    rules={[{ required: true, message: 'Enter GAM Bearing' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>

                                <br />
                                <Text><b>{outerGamName}</b></Text>
                                <Form.Item
                                    {...formLayout}
                                    label={`Radius (ft)`}
                                    name={['layout', 'outerRingGAMRadius']}
                                    rules={[{ required: true, message: 'Enter GAM Radius' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label={`Height (ft)`}
                                    name={['layout', 'outerRingGAMHeight']}
                                    rules={[{ required: true, message: 'Enter GAM Height' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label={`Bearing (deg)`}
                                    name={['layout', 'outerRingGAMBearing']}
                                    rules={[{ required: true, message: 'Enter GAM Bearing' }]}>
                                    <InputNumber min={0} style={{ width: '75px' }} />
                                </Form.Item>
                                <GamCustomMapping />

                            </>
        );
        }

        // return 2021 gam form if hardwareYear is null
        return gams2021Form;

    }

    private getFanBearingsSection = () => {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };

        return (
            <>
            <Form.List name={['layout', 'fanBearings']}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Fan Bearings</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={`F${index + 1} Bearing (deg)`}
                                        rules={[{ required: true, message: 'Enter the fan bearing' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
            </>
        );
    }

    private getFanDuctAreaSection = () => {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={['layout', 'fanDuctArea']}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Fan Duct Area (square inches)</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={`F${index + 1} Duct Area`}
                                        rules={[{ required: true, message: 'Enter the fan duct area' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getPneumaticBoardSection = () => {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={['hardware', 'pneumaticBoardSolenoidCounts']}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Pneumatic Board Solenoid Count</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field) => (
                                    <Form.Item {...field} key={field.key} {...layout} label="Solenoid Count"
                                        rules={[{ required: false, message: 'Enter the Pneumatic Board Solenoid Count' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getDynamicMcSection = () => {
        const formField = ['overrides', 'dynamicMC'];
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };

        return <div>
            <Text strong>Dynamic MC Adjustments</Text>
            <Form.Item
            {...layout}
            help="Enter comma separated values (ex: 1,1.5,-1)"

            label="MC% offsets per layer"
            name={[...formField, 'mcOffsetsByLayerString']}
            rules={[{ required: false, }]}>
                <Input style={{ width: '250px' }} placeholder="Enter comma separated values (ex: 1,1.5,-1)" />
            </Form.Item>

            <Form.Item
            {...layout}
            help="Enter comma separated values (ex: 1,1.5,-1)"

            label="GainByLayer"
            extra="Scaler applied to MDot values (Outflow Only)"
            name={[...formField, 'gainByLayerString']}
            rules={[{ required: false,}]}>
                <Input style={{ width: '250px' }} placeholder="Enter comma separated values (ex: 1,1.5,-1)" />
            </Form.Item>

            <Form.Item
                {...layout}
                label="Gain AH"
                extra={`need description for what Gain AH does`}
                name={[...formField, "gainAH"]}
                rules={[{ required: false }]}>
                <InputNumber min={0} style={{width: "8ch"}} />
            </Form.Item>

            <Form.Item
                {...layout}
                label="Gain CFM"
                extra={`need description for what Gain CFM does`}
                name={[...formField, "gainCFM"]}
                rules={[{ required: false }]}>
                <InputNumber min={0} style={{width: "8ch"}} />
            </Form.Item>
        </div>
    }

    // private getLayerDryTimeSection = () => {
    //     const layout = {
    //         labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
    //         wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
    //     };
    //     return (
    //         <Form.List name={['overrides', 'layerDryTimeAdjustFactors']}>
    //             {(fields, { add, remove }) => {
    //                 return (
    //                     <div>
    //                         <Text>Layer Dry Time Adjust Factors</Text>
    //                         <PlusCircleOutlined style={{ margin: '8px' }}
    //                             onClick={() => {
    //                                 add();
    //                             }}
    //                         />
    //                         {fields.length > 0 ? (
    //                             <MinusCircleOutlined style={{ margin: '8px' }}
    //                                 onClick={() => {
    //                                     remove(fields[fields.length - 1].name);
    //                                 }}
    //                             />
    //                         ) : null}
    //                     </div>
    //                 );
    //             }}
    //         </Form.List>
    //     );
    // }

    // private getRingsHardwareSection = () => {
    //    const layout = {
    //        labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 4 },
    //        wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 20 },
    //    };
    //    return (
    //        <Form.List name={['hardware', 'rings']}>
    //            {(fields, { add, remove }) => {
    //                return (
    //                    <div>
    //                        <Text>Ring Hardware</Text>
    //                        <PlusCircleOutlined style={{ margin: '8px' }}
    //                            onClick={() => {
    //                                add({ ring: fields.length });
    //                            }}
    //                        />
    //                        {fields.length > 0 ? (
    //                            <MinusCircleOutlined style={{ margin: '8px' }}
    //                                onClick={() => {
    //                                    remove(fields[fields.length - 1].name);
    //                                }}
    //                            />
    //                        ) : null}
    //                        <div style={{ margin: '8px' }}>
    //                            {fields.map((field) => (
    //                                <Space key={field.key} style={{ display: 'block', margin: '16px 8px' }} align="start">
    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Ring"
    //                                        name={[field.name, 'ring']}
    //                                        fieldKey={[field.fieldKey, 'ring']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} disabled={true} />
    //                                    </Form.Item>

    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Stacks"
    //                                        name={[field.name, 'stacks']}
    //                                        fieldKey={[field.fieldKey, 'stacks']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} />
    //                                    </Form.Item>
    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Valves"
    //                                        name={[field.name, 'valves']}
    //                                        fieldKey={[field.fieldKey, 'valves']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} />
    //                                    </Form.Item>
    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Thermocouples"
    //                                        name={[field.name, 'thermocouples']}
    //                                        fieldKey={[field.fieldKey, 'thermocouples']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} />
    //                                    </Form.Item>
    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Temperature Cables"
    //                                        name={[field.name, 'temperatureCables']}
    //                                        fieldKey={[field.fieldKey, 'temperatureCables']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} />
    //                                    </Form.Item>
    //                                    <Form.Item
    //                                        {...field}
    //                                        {...layout}
    //                                        label="Moisture Cables"
    //                                        name={[field.name, 'moistureCables']}
    //                                        fieldKey={[field.fieldKey, 'moistureCables']}
    //                                        style={{ marginBottom: '14px', marginTop: '8px' }}>
    //                                        <InputNumber min={0} />
    //                                    </Form.Item>
    //                                </Space>
    //                            ))}
    //                        </div>
    //                    </div>
    //                );
    //            }}
    //        </Form.List>
    //    );
    // }

    private getValveHeightsSection = (ringIndex: number) => {

        const ringLabel = this.formatRingName(ringIndex);
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={[ringIndex, 'valveHeights']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ marginLeft: '20px' }}>
                            <Text>Valve Heights (ft) : {ringLabel}</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={(1 + index)} name={[field.name]}
                                        rules={[{ required: true, message: 'Enter the valve height' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private formatRingName = (ringIndex: number): string => {
        let ringLabel = ' (Center)';
        if (ringIndex > 0) {
            ringLabel = ` (Perimeter)`;
        }
        return ringLabel;
    }

    private getStackBearingsSection = (ringIndex: number) => {
        const ringLabel = this.formatRingName(ringIndex);
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={[ringIndex, 'stackBearings']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ marginLeft: '20px' }}>
                            <Text>Stack Bearings (deg): {ringLabel}</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={(1 + index)} name={[field.name]}
                                        rules={[{ required: true, message: 'Enter the stack bearing' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private handleSerialNumberChange = (event: any) => {
        if (event?.target?.value == null) {
            return;
        }
        const res = event.target.value.toUpperCase();
        event.target.value = res;

    }

    private getWirelessMoistureCableSection = (ringIndex: number) => {
        // const layout = {
        //     labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 4 },
        //     wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 20 },
        // };
        return (
            <div>
                <Row key="wirelessMoistureCablesInfo-Section">
                    <Form.List name={[ringIndex, 'wirelessMoistureCablesInfo']}>
                        {(fields, { add, remove }) => {
                            // const form = this._formRef.current!;
                            // window.deviceForm = form;
                            return (
                                <div >
                                    <Text>Wireless Moisture Cables</Text>
                                    <PlusCircleOutlined style={{ margin: '8px' }}
                                        onClick={() => {
                                            add({bearing: undefined, cableId: fields.length + 1});
                                        }}
                                    />
                                    {fields.length > 0 ? (
                                        <MinusCircleOutlined style={{ margin: '8px' }}
                                            onClick={() => {
                                                remove(fields[fields.length - 1].name);
                                            }}
                                        />
                                    ) : null}
                                    <Space direction="vertical" size="large" style={{ display: "flex" }}>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={field.name}>
                                                <div>
                                                    <Form.Item name={[field.name, "cableId"]}
                                                        key={'cableId' + field.name}

                                                        label={`Wireless Moisture cable R${ringIndex}-RF(X)?`}
                                                        rules={[{ required: false, message: 'Enter Wireless Moisture Cable number. ex. 1 or 2' }]}
                                                    >
                                                        <InputNumber min={0} />
                                                    </Form.Item>

                                                    <Form.Item name={[field.name, "bearing"]}
                                                        key={'bearing' + field.name}
                                                        label={`Bearing (deg)`}
                                                        rules={[{ required: true, message: 'Enter Wireless Moisture bearing' }]}
                                                    >
                                                        <InputNumber min={0} max={360} style={{width: "75px"}} />
                                                    </Form.Item>

                                                    <Form.List name={[field.name, "sensors"]}>
                                                        {(wirelessIds, { add, remove }) => {
                                                            // rule: powercastIds = valves+1 for 2022 systems
                                                            // debugger;
                                                            return (
                                                                <div style={{ marginLeft: "32px" }}>
                                                                    <Row>
                                                                        <Text>Sensor Serial Number For</Text>
                                                                        <PlusCircleOutlined style={{ margin: '8px' }}
                                                                        onClick={() => {
                                                                            add();
                                                                        }}
                                                                        />
                                                                                                                                            {wirelessIds.length > 0 ? (
                                                                        <MinusCircleOutlined style={{ margin: '8px' }}
                                                                            onClick={() => {
                                                                                remove(wirelessIds[wirelessIds.length - 1].name);
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    </Row>
                                                                    <Row>
                                                                        <Text type="secondary">for 2022+ systems, number of sensors = valve heights entered + 1 (ex. 6 sensors, 5 valve heights, +1 for above last valve)</Text>
                                                                    </Row>

                                                                    {wirelessIds.map(wirelessId => (
                                                                        <Row key={wirelessId.name}>
                                                                            <Col span={20}>
                                                                                <Form.Item label={`Valve ${Number(wirelessId.name) + 1}`}
                                                                                    {...wirelessId}
                                                                                    name={[wirelessId.name, "serialNumber"]}
                                                                                    key={field.key}
                                                                                    rules={[{ required: true, message: 'Missing Serial Number for Wireless Sensor' },
                                                                                    { pattern: new RegExp("^[0-9A-F]{8}$"), message: "Serial Number for sensor must be 8 characters long and only use 0123456789ABCDEF" }
                                                                                    ]}
                                                                                >
                                                                                    <Input onChange={this.handleSerialNumberChange} onInput={this.handleSerialNumberChange} placeholder="200EF00F" minLength={8} maxLength={8} />
                                                                                </Form.Item>

                                                                            </Col>
                                                                        </Row>
                                                                    ))}

                                                                </div>
                                                            );
                                                        }}
                                                    </Form.List>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </Space>

                                </div>
                            );
                        }}
                    </Form.List>
                </Row>
            </div>
        );
    }
    

    private getMoistureCableBearingsSection = (ringIndex: number) => {

        const ringLabel = this.formatRingName(ringIndex);
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={[ringIndex, 'moistureCableBearings']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ marginLeft: '20px' }}>
                            <Text>Moisture Cable Bearings (deg): {ringLabel}</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={(1 + index)} name={[field.name]}
                                        rules={[{ required: true, message: 'Enter the moisture cable bearing' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getTemperatureCableBearingsSection = (ringIndex: number) => {

        const ringLabel = this.formatRingName(ringIndex);
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={[ringIndex, 'temperatureCableBearings']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ marginLeft: '20px' }}>
                            <Text>Temperature Cable Bearings (deg): {ringLabel}</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={(1 + index)} name={[field.name]}
                                        rules={[{ required: true, message: 'Enter the temperature cable bearing' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getThermoCoupleHeightsSection = (ringIndex: number) => {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };
        return (
            <Form.List name={[ringIndex, 'thermocoupleHeights']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ marginLeft: '20px' }}>
                            <Text>Thermocouple Heights (ft)</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add();
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <div style={{ margin: '8px' }}>
                                {fields.map((field, index) => (
                                    <Form.Item {...field} key={field.key} {...layout} label={index + 1} name={[field.name]}
                                        rules={[{ required: true, message: 'Enter the thermocouple height' }]}>
                                        <InputNumber min={0} style={{ width: '75px' }} />
                                    </Form.Item>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </Form.List>
        );
    }

    private getRingsLayoutSection = () => {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
        };


        return (
            <Form.List name={['layout', 'rings']}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            <Text>Rings</Text>
                            <PlusCircleOutlined style={{ margin: '8px' }}
                                onClick={() => {
                                    add({ ring: fields.length });
                                }}
                            />
                            {fields.length > 0 ? (
                                <MinusCircleOutlined style={{ margin: '8px' }}
                                    onClick={() => {
                                        remove(fields[fields.length - 1].name);
                                    }}
                                />
                            ) : null}
                            <Collapse>
                                {fields.map((field) => {
                                            const ringLabel = this.formatRingName(Number(field.name));
                                    return (<Panel header={field.key === 0 ? 'Center Ring' : 'Perimeter Ring ' + field.key}
                                        key={field.key}
                                        forceRender={true}>
                                        <Form.Item
                                            {...field}
                                            {...layout}
                                            label="Ring Index"
                                            name={[field.name, 'ring']}
                                            key={'ring' + field.name}
                                            style={{ marginBottom: '14px', marginTop: '8px' }}>
                                            <InputNumber min={0} disabled={true} style={{ width: '75px' }} />
                                        </Form.Item>
                                        <Form.Item {...field}
                                            {...layout}
                                            label="Stacks"
                                            name={[field.name, 'stacks']}
                                            key={'stacks' + field.name}
                                            rules={[{ required: true, message: 'Enter the number of stacks' }]}
                                            style={{ marginBottom: '14px', marginTop: '8px' }}>
                                            <InputNumber min={0} style={{ width: '75px' }} />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            {...layout}
                                            label="Radius (ft)"
                                            name={[field.name, 'radius']}
                                            key={'radius' + field.name}
                                            rules={[{ required: true, message: 'Enter the radius' }]}
                                            style={{ marginBottom: '14px', marginTop: '8px' }}>
                                            <InputNumber min={0} style={{ width: '75px' }} />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            {...layout}
                                            label="Stack Height (ft)"
                                            name={[field.name, 'stackHeight']}
                                            key={'stackHeight' + field.name}
                                            rules={[{ required: true, message: 'Enter the Stack Height' }]}
                                            style={{ marginBottom: '14px', marginTop: '8px' }}>
                                            <InputNumber min={0} style={{ width: '75px' }} />
                                        </Form.Item>

                                        <Form.Item
                                            {...field}
                                            {...layout}
                                            label="Layers"
                                            name={[field.name, "layers"]}
                                            help={"number of layers for this ring, only enter for AutoBin or AutoFan"}
                                            >
                                                <InputNumber min={0} style={{width: '75px'}} />
                                        </Form.Item>

                                        {this.getValveHeightsSection(field.name)}
                                        {this.getThermoCoupleHeightsSection(field.name)}
                                        {this.getStackBearingsSection(field.name)}
                                        {this.getMoistureCableBearingsSection(field.name)}
                                        <CustomMappingOPI fieldName={[field.name, 'opiMcCableMappings']} />
                                        {this.getTemperatureCableBearingsSection(field.name)}
                                        <RhtHeightsSection fieldName={[field.name, 'rhtsHeights']} ringLabel={ringLabel} />
                                        <OpiRhtHeightsSection fieldName={[field.name, "opiRhtsHeights"]} ringLabel={ringLabel} />
                                        <OpiCableBearingSection fieldName={[field.name, "opiMoistureCableBearings"]} ringLabel={ringLabel} />
                                        <CustomMappingTemperatureCable fieldName={[field.name, 'newTemperatureCableMappings']} />
                                        {this.getWirelessMoistureCableSection(field.name)}

                                    </Panel>
                                    );
                })}
                            </Collapse>
                        </div>
                    );
                }}
            </Form.List>
        );
    }
}


export default UpdateDevice;
