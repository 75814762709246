import * as React from 'react';
import History from 'src/utils/HistoryUtil';
import HistoryUtil from 'src/utils/HistoryUtil';
import Role from 'src/consts/Role';
import RoleUtil from 'src/utils/RoleUtil';
import Routes from 'src/consts/Routes';
import Scrollbars from 'react-custom-scrollbars-2';
import UserSecurity from 'src/models/UserSecurity';
import { connect } from 'react-redux';
import {
    // FileTextOutlined,
    HomeOutlined,
    TeamOutlined,
    ToolOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import './SideNav.less';

// @ts-ignore
import logo from 'src/statics/STIG - App - S only.png';
// @ts-ignore
import logoWithText from "src/statics/Shivvers  STIG - Liberator font (1).png";

interface SideNavProps {
    user: UserSecurity | null;
    logoutAction: () => void;
    collapsed: boolean;
}

interface SideNavState {
    current: string;
}

class SideNav extends React.Component<SideNavProps, SideNavState> {
    private homeUrl: string;
    private reportsUrl: string;
    private usersUrl: string;
    private adminUrl: string;
    private profileUrl: string;
    private binOverviewUrl: string;
    constructor(props: SideNavProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.getSelectedNavItem = this.getSelectedNavItem.bind(this);

        this.homeUrl = Routes.generate(Routes.HOME_ROUTE);
        this.usersUrl = Routes.generate(Routes.USERS_BASE);
        this.reportsUrl = Routes.generate(Routes.REPORTS_BASE);
        this.adminUrl = Routes.generate(Routes.ADMIN);
        this.profileUrl = Routes.generate(Routes.PROFILE);
        this.binOverviewUrl = Routes.generate(Routes.BIN_OVERVIEW);

        this.state = { current: this.getSelectedNavItem(HistoryUtil.location.pathname) };

        History.listen((location, action) => {
            this.setState({ current: this.getSelectedNavItem(location.pathname) });
        });
    }

    handleClick = (e: any) => {
        this.setState({
            current: e.key,
        });
    }

    render() {

        let selectedKeys = [this.state.current];

        return (
            <React.Fragment>
                <div className="logo-container">
                    <NavLink to={this.homeUrl}>
                        <img src={logoWithText} className="App-logo" alt="logo" />
                    </NavLink>
                </div>
                <Scrollbars autoHide={true} className="scrollbar-sidenav">
                    <Menu
                        onClick={this.handleClick}
                        defaultSelectedKeys={['home']}
                        selectedKeys={selectedKeys}
                        mode="inline"
                    >
                        <Menu.Item key="home" icon={<HomeOutlined />}>
                            <NavLink to={this.homeUrl}>
                                <span>Home</span>
                            </NavLink>
                        </Menu.Item>

                        {RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? (
                            <Menu.Item key="binOverview" icon={<UnorderedListOutlined />}>
                                <NavLink to={this.binOverviewUrl}>
                                    <span>Bin Overview</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}
                        <Menu.Divider />

                        {RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? (
                            <Menu.Item key="admin" icon={<ToolOutlined />}>
                                <NavLink to={this.adminUrl}>
                                    <span>Admin</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}

                        {RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? (
                            <Menu.Item key="users" icon={<TeamOutlined />}>
                                <NavLink to={this.usersUrl}>
                                    <span>Users</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}
                        <Menu.Divider />

                    </Menu>
                </Scrollbars>
            </React.Fragment>
        );
    }

    private getSelectedNavItem(location: string): string {
        const initialLocation = location;
        let selectedItem = '';

        if (initialLocation.indexOf(this.usersUrl) >= 0) {
            selectedItem = 'users';
        } else if (initialLocation.indexOf(this.reportsUrl) >= 0) {
            selectedItem = 'reports';
        } else if (initialLocation.indexOf(this.adminUrl) >= 0) {
            selectedItem = 'admin';
        } else if (initialLocation.indexOf(this.profileUrl) >= 0) {
            selectedItem = '';
        } else if (initialLocation.indexOf(this.binOverviewUrl) >= 0) {
            selectedItem = 'binOverview';
        } else if (initialLocation.indexOf(this.homeUrl) >= 0) {
            selectedItem = 'home';
        }

        return selectedItem;
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        logoutAction: () => dispatch(UserSessionActions.logout())
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        user: state.UserSession.Value
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
