import { useMutation } from "@tanstack/react-query";
import { Button, Card, Col, Form, InputNumber, Row, Space, Tag, Typography, message, Tooltip } from "antd"
import { useForm } from "antd/es/form/Form";
import { round } from "lodash";
import React, { useEffect } from "react"
import BinApiService from "src/api/BinApiService";
import BinDTO from "src/models/BinDTO";
import LayerPercentageDTO from "src/models/LayerPercentageDTO";
import { formatNumber } from "./HeaterControls";
import { CheckCircleOutlined } from "@ant-design/icons";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import { getDefaultTemperatureSensorType } from "src/pages/shared/binDTOUtils";

export const useDryLayerPercentAdjustMutation = (deviceId: string) => {
    return useMutation({
        mutationFn: async (params: {layerPercentages: LayerPercentageDTO[]}) => {
            return await BinApiService.setLayersTimePercentages(deviceId, params.layerPercentages);
        },
    })
}

const StatTagColor = "rgb(254,213,64)";
const activeLayerTagColor = "rgb(64,131,255)";

interface DryLayerAdjustProps {
    binDTO: BinDTO;
    deviceId: string;
}

export interface LayerAdjustment {
    percentage: number,
    number: number,
}
export interface FormValues {
    layers: LayerAdjustment[],
}

interface DryLayerRowProps {
    binDTO: BinDTO,
    layerNumber: number,
    fieldName: number,
}

export interface LayerFormatProps {
    binDTO: BinDTO;
    layerNumber: number;
}

export const LayerFormat = (props: LayerFormatProps) => {

    const maxLayers = props.binDTO?.layersAir?.length ?? 0;
    const isTopLayer = maxLayers === props.layerNumber;
    const isBottomLayer = props.layerNumber === 1;
    const targetLayer = props.binDTO?.routineEngine?.targetLayer;

    const layerInProgress = targetLayer == null && props.binDTO?.manualRoutineSettings?.targetLayer === props.layerNumber;

    let additionalLayerText = "";
    if (isTopLayer) {
        additionalLayerText = " (top layer)";
    }
    if (isBottomLayer) {
        additionalLayerText = " (bottom layer)";
    }

    return (
        <Space wrap direction="horizontal">
        <Typography style={{color: "inherit"}}>layer {props.layerNumber}{additionalLayerText}</Typography>
        {layerInProgress && <Tag color="blue-inverse" icon={<CheckCircleOutlined spin />}>Processing</Tag> }
        {targetLayer === props.layerNumber && <Tag color={activeLayerTagColor}>CURRENTLY ACTIVE</Tag>}
        {false && <Typography style={{color: "inherit"}}>HH:MM remaining</Typography>}
    </Space>
    );
}
export interface GrainLayerSummaryInfoProps {
    binDTO: BinDTO;
    layerNumber: number;
}
export const GrainLayerSummaryInfo = (props: GrainLayerSummaryInfoProps) => {
    const preferredTemperatureSensorType = props.binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(props.binDTO);
    const layerHasGrain = props.binDTO?.layerGrainStates?.[props.layerNumber - 1]?.hasGrain ?? false;
    const layer = props.binDTO?.layerGrainStates?.[props.layerNumber - 1];
    const grainTemp = formatNumber([TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(preferredTemperatureSensorType) ? layer?.temperatureF : layer?.thermocoupleTemperatureF , {decimalPlaces:0, filler:"--"})
    const grainRH = formatNumber(layer?.relitiveHumidity, {decimalPlaces: 0, filler:"--", suffix:"%"});
    const grainMc  = formatNumber(layer?.moistureContent, {decimalPlaces:1, filler:"--", suffix:"%"});

    return (
                           <Space direction="horizontal" size="small" >
                                <Tooltip title="Average of moisture cable readings for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>MC: {grainMc}</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Average of temperature cable readings for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>Temp: {grainTemp}°F</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Average of the relative humidity readings (from sensors on the moisture cables) for this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>RH: {grainRH}</Typography.Text>
                                    </Tag>
                                </Tooltip>
                                {layerHasGrain &&                                 
                                <Tooltip title="Headspace sensor indicates that this layer is covered in grain">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>Grain</Typography.Text>
                                    </Tag>
                                </Tooltip>}
                                {!layerHasGrain &&                                 
                                <Tooltip title="Headspace sensor indicates there is no grain covering this layer. Set layer to 0% to skip this layer">
                                    <Tag color={StatTagColor} style={{ color: "black" }}>
                                        <Typography.Text strong>No Grain</Typography.Text>
                                    </Tag>
                                </Tooltip>}    
                            </Space>
    );
}

const DryLayerRow = (props: DryLayerRowProps) => {

    const maxLayers = props.binDTO?.layersAir?.length ?? 0;

    const placeholderValue = round(100 / maxLayers, 1);

    return <>

        <Row align="middle" gutter={[0, 8]} className="dry-layer-adjust-row">
            <Col xs={24}>
                <Card size="small" bodyStyle={{paddingTop: "8px", paddingBottom: "8px"}} >
                    <Row gutter={[0, 8]} align='middle'>
                        <Col xs={8}>
                            <LayerFormat binDTO={props.binDTO} layerNumber={props.layerNumber} />
                        </Col>
                        <Col xs={2} md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <GrainLayerSummaryInfo binDTO={props.binDTO} layerNumber={props.layerNumber} />
                        </Col>
                        <Col xs={10} md={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Form.Item hidden name={[props.fieldName, "number"]}>
                            </Form.Item>
                                <Form.Item name={[props.fieldName, "percentage"]}>
                                    <InputNumber required style={{width: "16ch"}} addonAfter="%" min={0} max={100} placeholder={`${placeholderValue}%`}></InputNumber>
                                </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>;
}

interface LayerAdjustmentListProps {
    binDTO: BinDTO,
}

export const LayerAdjustmentList = (props: LayerAdjustmentListProps) => {

    return <>

<section>
                <Form.List name="layers"
                    rules={[
                        {
                            validator: async (_, names) => {
                                console.log("validation names", names);

                                let sum = 0;
                                for (const layer of names) {
                                    sum += layer.percentage;
                                }
                                if (sum <= 99 || sum >= 100.5) {
                                    return Promise.reject(new Error(`Combined time allocation must be 100%. It was ${round(sum, 2)}%`));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => {

                        return (<>

                            
                            <Space direction="vertical" size="small" style={{display: "flex", flexDirection: "column"}}>
                                {[...fields].reverse().map((field) => (
                                    <React.Fragment key={field.key}>
                                        <DryLayerRow binDTO={props.binDTO} layerNumber={field.key + 1} fieldName={field.name} />
                                    </React.Fragment>
                                ))}
                            </Space>

                            <Form.Item>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                    <Form.ErrorList errors={errors} />
                                </div>
                            </Form.Item>

                        </>);
                    }
                    }
                </Form.List>

            </section>
    </>;
}

export const DryLayerAdjustHelpText = () => {

    return (
        <Typography.Paragraph>Enter the percentage of time you would like each layer to be targeted with airflow. Set to 0% to skip a layer. Air will be directed layer by layer, starting from the top and proceeding to the bottom of the bin.</Typography.Paragraph>
    );
}

const dryLayerAdjustFormId = "dry-layer-adjust-form-id";
export const DryLayerAdjust = (props: DryLayerAdjustProps) => {

    const [form] = useForm<FormValues>();
    //console.log("binDTO props", props.binDTO);

    const numberOfLayers = props.binDTO?.layersAir?.length ?? 0;

    const initialValues: Partial<FormValues> = {};
    initialValues.layers = [];
    for (let layer of props.binDTO?.layersAir ?? []) {
        initialValues.layers.push({
            percentage: props.binDTO?.routineEngine?.layersPercentages?.[layer.number -1 ]?.percentage ?? round((1/numberOfLayers) * 100.0, 1),
            number: layer.number,
        });
    }

    useEffect(() => {
        if (form.isFieldsTouched()) {
            console.log("dry layer field are touched");
            return;
        }
        else {
            console.log("dry layer fields are not touched");
            form.resetFields();
            return;
        }
    }, [props.binDTO]);

    console.log("layer form initial values: ", initialValues);

    const layerAdjustMutation = useDryLayerPercentAdjustMutation(props.deviceId);

    return <Card title="Layer Target Settings">
        <DryLayerAdjustHelpText />
        <Form id={dryLayerAdjustFormId} form={form} onFinish={async (values) => {
            //console.log("layer adjust data values: ", values);
            layerAdjustMutation.mutate({layerPercentages: values.layers},
                {
                    onSuccess(data, variables, context) {
                        if (data.success === true) {
                            message.info("Updated target layer settings");
                        }
                        else {
                            message.error("Problem updating target layer settings");
                        }
                    },
                    onError(error, variables, context) {
                        console.error(error, "Error updating target layer settings");
                        message.error("Error updating target layer settings");
                    },
                }
                );
        }} initialValues={initialValues} validateTrigger={['onBlur', 'onFocus', 'onInput']}>

            <LayerAdjustmentList binDTO={props.binDTO} />
 
        </Form>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
            <Button loading={layerAdjustMutation.isLoading} disabled={layerAdjustMutation.isLoading} form={dryLayerAdjustFormId} htmlType='submit' type='primary'>Apply</Button>
        </div>
    </Card>
}