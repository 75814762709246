import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState, useCallback } from "react";
import { queryClient } from "src";
import BinApiService from "src/api/BinApiService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { tooltipItemRound, useFanHeaterChangeHistory } from "./shared";
import { BaseChartProps, htmlLegendPlugin } from "./TemperatureCableChart";
import _uniqueId from 'lodash/uniqueId';
import { Line } from "react-chartjs-2";
import { ChartDatePicker } from "./Co2Chart";
import { Skeleton } from "antd";
import { ambientFanDatasetColor, plenumFanDatasetColor } from "./AmbientPlenumChart";
import { useModeChangeHistory } from "./LayerMCHistoryChart";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";

export const LayerTemperatureHistoryChart = (props: BaseChartProps & {yTitle?: string} & {preferredSensorType: TemperatureSensorEnum}) => {
    const currentDate = dayjs();
    const priorDate = currentDate.subtract(14, 'days');
    
    const [legendId] = useState(_uniqueId('legend-'));
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
    const preferredSensorType = props.preferredSensorType ?? TemperatureSensorEnum.Thermocouple;
  
    // Internally, we need to deal with some value. Depending on whether
      // the component is controlled or not, that value comes from its
      // props or from its internal state.
  
      const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);
  
  
    const chartQuery = useQuery({
      queryKey: [...binDBKeys.bin(props.binId), "charts", "LayerSummary", { startDate: value?.[0], endDate: value?.[1] }],
      queryFn: async (q) => {
        const now = value?.[1];
        const priorMonth = value?.[0];
  
        const result = await BinApiService.getLayerSummaryHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
        queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
        return result;
      },
      refetchInterval: Infinity, retry: false, keepPreviousData: true,
      enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
    });

    const chartAmbientPlenumFanQuery = useQuery({
        queryKey: [...binDBKeys.bin(props.binId), "charts", "Ambient_Vs_Plenum", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
          const results = await BinApiService.getAmbientPlenumHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
          queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});
    
          return results;
        }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
      });
  
      const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
      const fanModeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);


    return (<div>
      {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
      <Skeleton loading={chartQuery.data == null || chartAmbientPlenumFanQuery.data == null}>
        {chartQuery.data && chartAmbientPlenumFanQuery.data && <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
          <Line options={{
            responsive: true,
            animation: false,
            spanGaps: false,
            resizeDelay: 250,
            maintainAspectRatio: false,
            datasets: {
              line: {
                pointRadius: 0 // disable for all `'line'` datasets
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  minUnit: 'day',
                  displayFormats: {
                    //day: 'l'
                  }
                },
              },
              y: {
                min: props.min ?? undefined,
                max: props.max ?? undefined,
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  text: props.yTitle ?? "Temperature °F",
                }
              }
            },
            interaction: {
              includeInvisible: true,            
            },
            plugins: {
              annotation: {
                annotations: [
                  ...modeHistory.annotations,
                  ...fanModeHistory.annotations,
                ],
              },
              legend: {
                position: 'top' as const,
                display: true,
              },
              title: {
                display: true,
                text: 'Temperature (°F)',
              },
  
              tooltip: {
                mode: 'index',
                
                intersect: false,
                callbacks: {
                  label: tooltipItemRound,
                },
              },
  
            },
          }}
            data={

                { datasets: [
                    {
                        data: chartAmbientPlenumFanQuery.data?.[0]?.data!,
                        label: chartAmbientPlenumFanQuery.data?.[0]?.label!,
                        backgroundColor: plenumFanDatasetColor,
                        borderColor: plenumFanDatasetColor,
                    },
                    {
                        data: chartAmbientPlenumFanQuery.data?.[2]?.data!,
                        label: chartAmbientPlenumFanQuery.data?.[2]?.label!,
                        backgroundColor: ambientFanDatasetColor,
                        borderColor: ambientFanDatasetColor,
                    },
                    // @ts-ignore
                ...[...chartQuery.data ?? []]?.reverse()?.map(dataset => {

                return {
                    data: dataset.data,
                    label: dataset.label!,
                    parsing: {
                        yAxisKey: [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(preferredSensorType) ? 'moistureCableTemperatureF' : 'temperatureF',
                    }
                };
            }) ?? [],

            ] }} />
        </section>
        }
        <section id={`legend-layerSummary-container${legendId}`}></section>
      </Skeleton>
    </div>);
  }
  