import { Badge, ConfigProvider, Skeleton, Space, Tabs, TabsProps, Typography } from "antd";
import React from "react";
import Role from "src/consts/Role";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import BinDTO from "src/models/BinDTO";
import { getDefaultTemperatureSensorType } from "src/pages/shared/binDTOUtils";
import { useBinDTOContext } from "src/queries/BinDTOContext";
import CurrentUser from "src/utils/CurrentUser";
import { AutomationType, selectAutomationType } from "src/utils/deriveAutomationType";
import { CellStrengthChart } from "../charts/CellStrengthChart";
import { Co2Chart } from "../charts/Co2Chart";
import { CompressorChart } from "../charts/CompressorChart";
import { DryingDashboard } from "../charts/DryingDashboard";
import { FansChart } from "../charts/FanAndHeaterChart";
import { FillLevelChartTab } from "../charts/FillLevelChartTab";
import { IncomingAirConditionsChartTab } from "../charts/IncomingAirConditionsChartTab";
import { MoistureCableChartTab } from "../charts/MoistureCableChartTab";
import { fanOnBackgroundColor, FanPausedBackgroundColor, heaterOnBackgroundColor } from "../charts/shared";
import { TargetLayerChart } from "../charts/TargetLayerChart";
import { TemperatureCableChartTab } from "../charts/TemperatureCableChartTab";

interface ChartsTabProps {
    binDTO: BinDTO,
    binId: number,
    deviceId: string,
}

export const ChartsTab = (props: ChartsTabProps) => {

    if (props.binDTO == null || props.binId == null || props.deviceId == null) {
        return <Skeleton loading active />
    }

    const binDTOContext = useBinDTOContext();

    const automationType = selectAutomationType(props.binDTO);
    const isAdmin = CurrentUser.Get()?.isInRole(Role.ADMIN);

    const preferredTemperatureSensorType = props.binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(props.binDTO);
    
    const chartsToShow: TabsProps["items"] = [];

    if (props.binDTO?.opiMoistureCables?.length) {
        chartsToShow.push({
            label: "Moisture Cables",
            key: "Moisture Cables",
            children: <MoistureCableChartTab binId={props.binId} deviceId={props.deviceId!} preferredTemperatureSource={preferredTemperatureSensorType} />
        });
    }

    if (props.binDTO?.temperatureCables?.length && [TemperatureSensorEnum.Thermocouple].includes(preferredTemperatureSensorType)) {
        chartsToShow.push({
            label: "Temperature Cables",
            key: "Temperature Cables",
            children: <TemperatureCableChartTab binDTO={props.binDTO} binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    if (props.binDTO?.innerCO2 != null || props.binDTO?.outerCO2 != null) {
        chartsToShow.push({
            label: "CO2",
            key: "CO2",
            children: <Co2Chart binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    if (binDTOContext.hasRangeFinder()) {
        chartsToShow.push({
            label: "Fill Level",
            key: "Fill Level",
            children: <FillLevelChartTab binId={props.binId} deviceId={props.deviceId} />
        });
    }

    if (props.binDTO?.fans?.length) {
        chartsToShow.push({
            label: "Incoming Air Conditions",
            key: "Drying Paramaters",
            children: <IncomingAirConditionsChartTab binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    if (automationType === AutomationType.DriStack) {
        chartsToShow.push({
            label: "Target Layer",
            key: "TargetLayer",
            children: <TargetLayerChart binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    if (props.binDTO?.compressorState != null) {
        chartsToShow.push({
            label: "Compressor",
            key: "Compressor",
            children: <CompressorChart binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    // we don't control heaters on autobins this year (2023)
    let hasHeater = !(props.binDTO?.desiredProperties?.overrides?.noHeater === true) || automationType === AutomationType.DriStack;

    if (props.binDTO?.fans?.length) {
        let label = "Fan & Heater Status";
        if (hasHeater === false) {
            label = "Fan Status";
        }
        chartsToShow.push({
            label: label,
            key: "Fan&Heater Status",
            children: <FansChart binId={props.binId} deviceId={props.deviceId!} hasHeater={hasHeater} />
        });
    }

    if (isAdmin) {
        chartsToShow.push({
            label: "Cell Strength",
            key: "Cellular",
            children: <CellStrengthChart binId={props.binId} deviceId={props.deviceId!} />
        });
    }

    if (automationType === AutomationType.DriStack && isAdmin) {
        chartsToShow.push({
            label: "Drying Dashboard",
            key: "DryingDashboard",
            children: <DryingDashboard binId={props.binId} deviceId={props.deviceId!} binDTO={props.binDTO} />
        });
    }


    return <Tabs destroyInactiveTabPane={false} items={chartsToShow} />

}

export const FanHeaterChangeBackgroundLegend = () => {

    const binDTOContext = useBinDTOContext();

    return <ConfigProvider theme={{ components: { Badge: { fontSizeSM: 32} } }}>
        <Space direction="horizontal" size="middle">
            <Badge color="transparent" text="Fan Off" styles={{
                indicator: {
                    outline: "1px solid gray",
                }
            }} />
            <Badge color={FanPausedBackgroundColor} text="Fan Paused" />
            <Badge color={fanOnBackgroundColor} text="Fan On" />
            {    binDTOContext.hasHeaters() && <Badge color={heaterOnBackgroundColor} text="Heater On" />}
        </Space>
    </ConfigProvider>
}