import { Modal, message } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import BinAssemblySubmittalDTO from "src/models/BinAssemblySubmittalDTO";


interface TwinSheetFormValues {
    text: string,
}

interface DeviceUpdateFromSheetPropsV2 {
    open: boolean,
    submittalForm: FormInstance<BinAssemblySubmittalDTO>;
    onOk: () => void,
    onCancel: () => void,
    binId: number,
    deviceId:string,
    createSubmittalFromText: (text: string, binId: number, deviceId: string) => Promise<BinAssemblySubmittalDTO>;
}

export const DeviceUpdateModalV2: React.FC<DeviceUpdateFromSheetPropsV2> = ({open, submittalForm, onOk, onCancel, createSubmittalFromText, binId, deviceId}) => {

    const formId = "assemblyFromSheet";
    const onFinish = useCallback(async (values: TwinSheetFormValues) => {
        try {
            const text = values.text;
            if (text == null || text?.length === 0) {
                console.log("no text entered / null. Skipping", text);
                return;
            }

            const myPatch = await createSubmittalFromText(text, binId, deviceId);

            submittalForm.setFieldsValue(myPatch as any);
            
            onOk?.();
        } catch (err) {
            console.log("form crash", err);
            message.error("Parsing error. Please post pasted text on Slack/ZenDesk");
        }

    }, [submittalForm, onOk]);

    return (<Modal title="Paste the bin's assembly" open={open} onCancel={onCancel} okText="Apply" okButtonProps={{ htmlType: "submit", form: formId }}>
        <Form scrollToFirstError id={formId} onFinish={onFinish} initialValues={{ text: "" }}>
            <Form.Item name="text" rules={[{type: "string", required: true, min: 1, message: "No text entered"}]}>
                <TextArea id={formId} autoSize={{ minRows: 4, maxRows: 10 }} wrap="off" />
            </Form.Item>
        </Form>
    </Modal>);
}