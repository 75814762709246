import BinDTO from "src/models/BinDTO";
import { BaseChartProps, OPICableChart, TemperatureCableChart, TemperatureCableOpiChart } from "./TemperatureCableChart";
import React, { useCallback, useMemo, useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { useIsFetching } from "@tanstack/react-query";
import { Space, Button, SelectProps, Select, Switch, Divider } from "antd";
import dayjs from "dayjs";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { RangeValue } from "./ChartContainer";
import { ChartDatePicker } from "./Co2Chart";
import { ExtraCharts } from "./DryingDashboard";
import { LayerMCHistoryChart } from "./LayerMCHistoryChart";
import { LayerTemperatureHistoryChart } from "./LayerTemperatureHistoryChart";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";

export const MoistureCableChartTab = (props: BaseChartProps & {preferredTemperatureSource: TemperatureSensorEnum}) => {

    const currentDate = dayjs();
    const priorDate = currentDate.subtract(14, 'days');
  
  
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
    const inprogress = useIsFetching([...binDBKeys.bin(props.binId), "charts"]);
  
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
  
    const value = isControlled ? props.value : internalValue;
  
    const onDateChange = useCallback((values: RangeValue, formatter?: any) => {
      if (props.onChange) {
        props.onChange(values);
      }
      else {
        setIntervalValue(values);
      }
    }, [props.onChange]);
  


    const [showPerSensorChart, setShowPerSensorChart] = useState(false);
  
  
    return <>
      <div>
        <Space direction="horizontal" wrap>
          <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
          <Button type="primary" disabled={inprogress > 0} icon={<RedoOutlined spin={inprogress > 0} />} onClick={() => {
            onDateChange([value?.[0]!, value?.[1]!], ['', '']);
          }}>
          </Button>

          <Switch unCheckedChildren="By Layer" checkedChildren="By Sensor" checked={showPerSensorChart} onChange={(checked, event) => {
            setShowPerSensorChart(checked);
          }} />
  
        </Space>
  
        <div style={{ width: "100%" }}>

        {!showPerSensorChart && <LayerMCHistoryChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={props.preferredTemperatureSource === TemperatureSensorEnum.Thermocouple ? "60vh" : "30vh"} />}
        {!showPerSensorChart && [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(props.preferredTemperatureSource) && <>
          <LayerTemperatureHistoryChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} chartHeight={props.preferredTemperatureSource === TemperatureSensorEnum.Thermocouple ? "60vh" : "30vh"} preferredSensorType={props.preferredTemperatureSource} />
        </>
        }
        {showPerSensorChart && <>
          <OPICableChart value={value} binId={props.binId} deviceId={props.deviceId} chartHeight={props.preferredTemperatureSource === TemperatureSensorEnum.Thermocouple ? "60vh" : "30vh"} />
          {[TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(props.preferredTemperatureSource) && <>
            <TemperatureCableOpiChart binId={props.binId} value={value} deviceId={props.deviceId} chartHeight={props.preferredTemperatureSource === TemperatureSensorEnum.Thermocouple ? "60vh" : "30vh"} />
          </>
          }
        </>}

        </div>
      </div>
    </>

}