import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { binDBKeys } from '../binOverview/BinCommander';

interface UploadRoutineProps {
    binId: number;
}


export const UploadRoutine = (props: UploadRoutineProps) => {
  const queryClient = useQueryClient();
  const uploadProps: UploadProps = {  
    name: 'file',
    action: `/api/grainbin/UploadCustomRoutine?binId=${props.binId}`,
    // fileList: [],
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // uploadProps.name = info.file.name;
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      queryClient.invalidateQueries([...binDBKeys.bin(props.binId), "customRoutineList"]);
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />}>Upload Routine File</Button>
    </Upload>
  );
};

export const UploadAndRunRoutine = (props: UploadRoutineProps) => {
  const queryClient = useQueryClient();
  const uploadProps: UploadProps = {  
    name: 'file',
    action: `/api/grainbin/UploadAndRunCustomRoutine?binId=${props.binId}`,
    
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        
        // uploadProps.name = info.file.name;
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      queryClient.invalidateQueries([...binDBKeys.bin(props.binId), "customRoutineList"]);
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />}>Upload and Run Routine File</Button>
    </Upload>
  );
};