// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import BinHistoryDataDTO from './BinHistoryDataDTO';
import EnterpriseCropDataDTO from './EnterpriseCropDataDTO';

interface BinInfoDTO {
    id: number;
    growerId: number;
    name: string | null;
    ecDeviceID: string | null;
    deviceId: string | null;
    description: string | null;
    notes: string | null;
    binLat: number | null;
    binLng: number | null;
    height: number | null;
    diameter: number | null;
    manufacturer: string | null;
    fanModel: string | null;
    targetMoisture: number | null;
    estimatedCurrentBu: number | null;
    totalCapacityBu: number | null;
    levelData: BinHistoryDataDTO[] | null;
    binStats: EnterpriseCropDataDTO | null;
    isActive: boolean;
}
const BinInfoDTO: InterfaceConstructor<BinInfoDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            id: 0,
            growerId: 0,
            name: null,
            ecDeviceID: null,
            deviceId: null,
            description: null,
            notes: null,
            binLat: null,
            binLng: null,
            height: null,
            diameter: null,
            manufacturer: null,
            fanModel: null,
            targetMoisture: null,
            estimatedCurrentBu: null,
            totalCapacityBu: null,
            levelData: [],
            binStats: null,
            isActive: false,
        },
        initValues);
    }
};

export default BinInfoDTO;
