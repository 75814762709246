import * as React from 'react';
import { Layout, Tabs } from 'antd';
import UpdateDevice from './UpdateDevice';
import CreateUpdateBin from './CreateUpdateBin';
import GrowerAdministration from './GrowerAdminstration';
import { useLocation, useHistory } from 'react-router-dom';
import BinInfoDTO from 'src/models/BinInfoDTO';
// import { UpdateDeviceV2 } from './UpdateDeviceV2';
import { UpdateDeviceV3 } from './UpdateDeviceV3';
import { AddBoardLookup } from './AddBoardLookup';
import { AddSoftwareLookup } from './AddSoftwareLookup';
import RoleUtil from 'src/utils/RoleUtil';


export interface AdminTabsProps {
    tab?: number;
}

export type adminHistoryState = {
    passedBin?: BinInfoDTO;
    passedGrower?: [number, string];
};

const AdminTabs = (props: AdminTabsProps) => {
    const location = useLocation<adminHistoryState>();
    const history = useHistory();

    return (
        <Layout.Content className="admin" key={props.tab || 'default'}>
            <Tabs defaultActiveKey={props.tab ? props.tab.toString() : '1'} 
            onChange={(key) => {
                history.push(`/admin/${key}`)
            }}
            >
                <Tabs.TabPane tab="Growers" key="1">
                    <GrowerAdministration isSettings={false}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Bins" key="2">
                    <CreateUpdateBin startBin={location.state?.passedBin}
                        startGrowerFilter={location.state?.passedGrower}/>
                </Tabs.TabPane>
                
                <Tabs.TabPane tab="OLD Devices" key="3">
                    <UpdateDevice />
                </Tabs.TabPane>

                {
                    RoleUtil.currentUserName() === "X@haber-tech.com" && 
                    <>
                        <Tabs.TabPane tab="System Configurations" key="4">
                            <UpdateDeviceV3 />
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Add PCB Lookup" key="5">
                            <AddBoardLookup />
                        </Tabs.TabPane>

                        {/* <Tabs.TabPane tab="Add Software Lookup" key="6">
                            <AddSoftwareLookup />
                        </Tabs.TabPane> */}
                    </>
                }


            </Tabs>
        </Layout.Content>
    );
};

export default AdminTabs;
