import RhSensorEnum from "src/consts/RhSensorEnum";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import BinDTO from "src/models/BinDTO";

export const getDefaultTemperatureSensorType = (binDTO: BinDTO): TemperatureSensorEnum => {

    if (binDTO == null) {
        return TemperatureSensorEnum.Thermocouple;
    }

    if (binDTO.temperatureCables?.length) {
        return TemperatureSensorEnum.Thermocouple;
    }
    else if (binDTO.opiMoistureCables?.length) {
        return TemperatureSensorEnum?.Opi;
    }
    else {
        return TemperatureSensorEnum.PowerCast;
    }
}

export const getDefaultRhSensorType = (binDTO: BinDTO): RhSensorEnum => {

    if (binDTO == null) {
        return RhSensorEnum.Opi;
    }

    if (binDTO.opiMoistureCables?.length) {
        return RhSensorEnum.Opi;
    }
    else {
        return RhSensorEnum.PowerCast;
    }
}