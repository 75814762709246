import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Descriptions, Table } from "antd";
import AdminApiService from "src/api/AdminApiService";
import { useForm } from "react-hook-form";
import { sortBy } from 'lodash';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Layout,
    Tabs,
    notification,
    Typography,
    Card,
    Modal,
    Switch,
    Col,
    Row,
    Collapse,
    Select,
    Radio,
    Divider,
    message,
    Spin,
    FormInstance
} from "antd";
import { BorderRightOutlined, CopyFilled, EditOutlined, FastBackwardFilled, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
// @ts-ignore transformed by webpack into svg url later
import { Option } from "antd/lib/mentions";
import UnifiedDeviceConfigDTO from "src/models/UnifiedDeviceConfigDTO";
import { LabeledValue } from "antd/lib/select";
// import { AutomationType } from "src/utils/deriveAutomationType";

import BoardGroup from "src/consts/BoardGroup";
import { DeviceUpdateModalV2 } from "./TextUpdateV2";
import BinAssemblyDTO from "src/models/BinAssemblyDTO";
import PCBLookupDTO from "src/models/PCBLookupDTO";
import DeviceProfileDTO from "src/models/DeviceProfileDTO";
import { DeviceProfileTable } from "./DeviceProfileTable";
import CableType from "src/consts/CableTypeId";
import RingAssemblyDTO from "src/models/RingAssemblyDTO";
import { stat } from "fs";
import StackEmcConfigDTO from "src/models/StackEmcConfigDTO";
import SensorAssemblyDTO from "src/models/SensorAssemblyDTO";
import SensorType from "src/consts/SensorType";
import StackAssemblyDTO from "src/models/StackAssemblyDTO";
import _, { sum } from "lodash";
import HardwareYear from "src/consts/HardwareYear";
import AutomationType from "src/consts/AutomationType";
import { FormListFieldData } from "antd/lib";
import FanAssemblyDTO from "src/models/FanAssemblyDTO";
import ActuatorAssemblyDTO from "src/models/ActuatorAssemblyDTO";
import ActuatorType from "src/consts/ActuatorType";
import { fa, sr_RS_latin } from "@faker-js/faker";
import { number } from "prop-types";
import BinAssemblySubmittalDTO from "src/models/BinAssemblySubmittalDTO";
import { NamePath } from "antd/es/form/interface";
import { GreaterEqualDepth } from "three";

const { TabPane } = Tabs;
const { Text } = Typography;
const { Panel } = Collapse;

interface StateV2 {
    deviceIdList: string[];
    chosenDeviceId: string;
    binID: number;
    pcbLookups: PCBLookupDTO[];
}

export const UpdateDeviceV3 = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [enableSubmitButton, setEnableSubmitButton] = useState<boolean>(false);
    const [form] = Form.useForm<BinAssemblySubmittalDTO>();
    const values = Form.useWatch([], { form, preserve: true });
    const [deviceProfileModalOpen, setDeviceProfModalOpen] = useState<boolean>(false);
    const [areYouSureClearAllModalOpen, setAreYouSureClearAllModalOpen] = useState<boolean>(false);

    const [showModalFromSheet, setShowModalFromSheet] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("1");
    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const [state, setState] = React.useState<StateV2>({
        deviceIdList: [],
        chosenDeviceId: "",
        pcbLookups: [],
        binID: 0
    });

    useEffect(() => {
        AdminApiService.getAllBins()
            .then(binIDs => {
                setState(prevState => ({
                    ...prevState,
                    deviceIdList: binIDs
                }));
            })
            .catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });

        AdminApiService.getPCBLookups()
            .then(pcbs => {
                setState(prevState => ({
                    ...prevState,
                    pcbLookups: pcbs
                }));
            })
            .catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
    }, []);

    useEffect(() => {
        setEnableSubmitButton(false);
    }, [values])

    const binIdClicked = async (id: string, isDeviceTwin: boolean) => {
        try {
            setLoading(true);
            const result = await AdminApiService.getBinAssemblySubmittal(id);
            if (result.binAssembly === null) {
                form.resetFields();
            } else {
                // result.binAssembly?.rings!.sort((a, b) => a.number - b.number);
                form.resetFields();
                form.setFieldsValue(result as any);
            }
    
            setState(prevState => ({
                ...prevState, // Copy existing state
                chosenDeviceId: result.deviceID ?? "N/A",
                binID: result.binID
            }));

            form.setFieldValue("binID", result.binID);
            form.setFieldValue("deviceID", result.deviceID);

            setLoading(false);
        } catch (error) {
            console.log(error);

            notification.error({
                message: error?.errorDetails?.detail ?? "Contact support",
                description: error?.detail
            });
            setLoading(false);
        }
    };

    const binIdDropdownValues = () => {
        return (
            <>
                {state.deviceIdList?.map(id => (
                    <Option key={id} value={id}>
                        {id}
                    </Option>
                ))}
            </>
        );
    };

    const pcbLookUpDropdownValues = (...types: BoardGroup[]) => {
        let devices = form.getFieldValue(["binAssembly", "devices"]) as DeviceProfileDTO[];
        return (
            <>
                {devices?.map(
                    id =>
                        types.includes(id.boardLookupDTO!.boardGroupId) && (
                            <Option 
                            key={id.name!} 
                            value={`${id.boardNumber}`}
                            >
                                {id.name}
                            </Option>
                        )
                )}
            </>
        );
    };

    const InputListForm = (path: NamePath, helpText: string, inRing: boolean, dependentArrayNames: string[] | null) => {
        return (
            <Form.List name={[...path]}>
                {(fields, { add, remove }) => (
                    <Row style={{ paddingBottom: "20px" }}>
                        <Col style={{ paddingRight: "10px" }}>
                            <span>{helpText}</span>
                        </Col>
                        <Col style={{ paddingRight: "10px" }}>
                            <Button
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                    if(fields.length > 1){
                                        remove(fields.length - 1);
                                    }else if(fields.length <= 1){

                                        if(fields.length == 1){
                                            remove(fields.length - 1)
                                        }
                                        if(dependentArrayNames !== null && dependentArrayNames.length > 0){
                                            if (inRing === true) {
                                                dependentArrayNames.forEach( n => {
                                                    form.setFieldValue(["rings", path[0], n], []);
                                                })
                                                
                                            } else {
                                                dependentArrayNames.forEach( n => {
                                                    form.setFieldValue([n], []);
                                                })
                                            }
                                        }
                                    }
                                }}
                            />
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                            </Form.Item>
                        </Col>

                        {fields.map((field, index) => {
                            return (
                                <Row style={{paddingBottom: "10px" }} gutter={8} align="middle">
                                    <Col style={{paddingBottom: "10px" }} flex="auto">
                                        <Form.Item
                                            key={`${path}-${field.name}`}
                                            //layerStartHeights[0]
                                            name={[field.name]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:"Enter the desired value"
                                                }
                                            ]}
                                        >

                                                <InputNumber addonBefore={`${field.name + 1}`} min={0} style={{ width: "100px" }} />

                                        </Form.Item>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Row>
                )}
            </Form.List>
        );
    };

    const onFinish = async (values: any) => {
        let assemblySubmittal = values as BinAssemblySubmittalDTO;
        try {
            setLoading(true);
            const result = await AdminApiService.uploadBinAssembly(assemblySubmittal.binAssembly!);
            notification.success({ message: "Bin assembly upload successful" });

            setState(prevState => ({
                ...prevState,
                chosenDeviceId: ""
            }));

            setEnableSubmitButton(false);
            setLoading(false);
            
        } catch (err) {
            console.log(err);
            notification.error({
                message: err?.errorDetails?.detail ?? "Contact support",
                description: err?.detail
            });
        }
        setLoading(false);
    };


    const regenerateAssembly = async (values: any) => {

        let submittal = values === null ? form.getFieldsValue() : values as BinAssemblySubmittalDTO;
        submittal.deviceID = state.chosenDeviceId;
        submittal.binID = state.binID;
        submittal.layerTopHeights = submittal.layerTopHeights === undefined ? [] : submittal.layerTopHeights;
        submittal.fanInletAreaFtSq = submittal.fanInletAreaFtSq === undefined ? [] : submittal.fanInletAreaFtSq;
        submittal.fanBearings = submittal.fanBearings === undefined ? [] : submittal.fanBearings;
        submittal.rings = submittal.rings === undefined ? [] : submittal.rings;

        try{
            setLoading(true);
            const validated = await form.validateFields();
        }catch(err){
            // console.log("Error regenerating bin assembly", err);
            notification.error({
                message: err?.errorDetails?.detail ?? "Validation error. Check all your inputs",
                description: err?.detail
            });

            setLoading(false);
            return null;
        }

        try {
        
            const result = await AdminApiService.generateAssembly(submittal);
            form.setFieldValue("binAssembly", result as any);

            setLoading(false);
            notification.success({ message: "Bin assembly validation successful" });
            setEnableSubmitButton(true);
            return result;
        } catch (err) {
            console.log("Error regenerating bin assembly", err);
            notification.error({
                message: err?.errorDetails?.detail ?? "Contact support",
                description: err?.detail
            });

            setLoading(false);
            return null;
        }
    };

    const createSubmittalFromText = (text: string): Promise<BinAssemblySubmittalDTO> => {
        return new Promise((resolve, reject) => {
            AdminApiService.createSubmittalFromText(text, state.binID, state.chosenDeviceId)
                .then(res => resolve(res))
                .catch(err => {
                    // Handle error here
                    console.log("Error attempting to tie bin assembly", err);
                    notification.error({
                        message: err.errorDetails.detail,
                        description: err.detail
                    });
                    reject(err); // Reject the promise with the error
                });
        });
    };

    interface SensorWindowInterface{
        sensorName: string;
        sensorController: string;
        portNumber: string;
        indexOnPort: string;
        sensorType: string;
    }

    const SensorWindow = () => {
        let devices = form.getFieldValue(["binAssembly", "devices"]) as DeviceProfileDTO[]
        const allStackRhts = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards).flatMap(profile => profile.sensors);
        const allOpiRhts = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.OPIBoards).flatMap(profile=>profile.sensors);
        const allThermocouples = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards).flatMap(profile => profile.sensors)
        .filter(s=>s?.sensorTypeId === SensorType.Thermocouple);
        const allRhtBoardSensors = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.RHTBoards).flatMap(profile => profile.sensors)

        const allGamSensors =devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.SensingBoards).flatMap(profile => profile.sensors)
        .filter(s=>s?.sensorTypeId !== SensorType.Thermocouple);
        const allFanSensors = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Fans).flatMap(profile => profile.sensors);
        
        let rhtData  = allStackRhts?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));

        let rhtBoardData  = allRhtBoardSensors?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));

        const sortedOpiRht = allOpiRhts?.sort((codeA, codeB) => {
            let [aA, bA, cA] = (codeA?.name ?? "").match(/\d+/g)?.map(Number) ?? [0,0,0];
            let [aB, bB, cB] = (codeB?.name ?? "").match(/\d+/g)?.map(Number) ?? [0,0,0];
        
            if (aA !== aB) {
                return aA - aB;
            }
            if (bA !== bB) {
                return bA - bB;
            }
            return cA - cB;
        });


        let opiRhtData = sortedOpiRht?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));

        const sortedThermocouples = allThermocouples?.sort((codeA, codeB) => {
            let [aA, bA, cA] = (codeA?.name ?? "").match(/\d+/g)?.map(Number) ?? [0,0,0];
            let [aB, bB, cB] = (codeB?.name ?? "").match(/\d+/g)?.map(Number) ?? [0,0,0];
        
            if (aA !== aB) {
                return aA - aB;
            }
            if (bA !== bB) {
                return bA - bB;
            }
            return cA - cB;
        });

        
        // sortedThermocouples now contains allThermocouples sorted by SensingBoardName first,
        // then by PortNumber, and finally by IndexOnPort
        

        let tempCableData = sortedThermocouples?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));

        let fanData = allFanSensors?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));

        let gamData = allGamSensors?.map( sensor => ({
            sensorName: sensor?.name ?? "--",
            sensorController: sensor?.sensingBoardName ?? "--",
            portNumber: sensor?.portNumber ?? "--",
            indexOnPort: sensor?.indexOnPort ?? "--",
            sensorType: sensor?.sensorTypeId ?? "--"
        }));


        let data : SensorWindowInterface[] = []
        rhtData?.forEach(d=>data.push(d as SensorWindowInterface));
        opiRhtData?.forEach(d=>data.push(d as SensorWindowInterface));
        tempCableData?.forEach(d=>data.push(d as SensorWindowInterface));
        fanData?.forEach(d=>data.push(d as SensorWindowInterface));
        gamData?.forEach(d=>data.push(d as SensorWindowInterface));
        rhtBoardData?.forEach(d=>data.push(d as SensorWindowInterface));

        const columns = [
            {
                title: "Name",
                dataIndex: "sensorName"
            },
            {
                title: "Controller",
                dataIndex: "sensorController"
            },
            {
                title: "Port",
                dataIndex: "portNumber"
            },
            {
                title: "Position on port",
                dataIndex: "indexOnPort"
            }
        ];

        return (
            <div style={{ maxHeight: 700, maxWidth: 700, overflowY: "auto", border: "1px solid #e8e8e8" }}>
                <Table dataSource={data} columns={columns} pagination={false} scroll={{ y: 500 }} />
            </div>
        );
    };

    interface ActuatorWindowInterface{
        actuatorName: string;
        actuatorController: string;
        portNumber: number;
        valveNumber: number;
    }

    const ActuatorWindow = () => {
        let devices = form.getFieldValue(["binAssembly", "devices"]) as DeviceProfileDTO[]
        const allSols = devices?.filter(b => b.boardLookupDTO?.boardGroupId === BoardGroup.StackBoards)
            .flatMap(profile => profile.actuators)

        const fanActuators = devices?.filter(b=>b.boardLookupDTO?.boardGroupId === BoardGroup.Fans)
        .flatMap(profile=> profile.actuators);
    
        let updatedData = allSols?.map(actuator => ({
            actuatorName: actuator?.name ?? "--",
            actuatorController: actuator?.actuatingBoardName ?? "--",
            portNumber: actuator?.portNumber ?? -1,
        }));
    
        // Sort by actuatorName using lodash's sortBy
        updatedData = sortBy(updatedData, [
            actuator => actuator.actuatorName.substring(0, 2),
            (actuator) => {
                const lastChar = actuator.actuatorName.slice(-1);
                if (lastChar === 'S') return 1;
                if (lastChar === 'G') return 2;
                if (lastChar === 'C') return 3;
                return 4; // If the last character is not 'S', 'G', or 'CC', consider them the same
              
            },
        ]);

        fanActuators?.forEach(a => updatedData.push({
            actuatorName: a?.name ?? "--",
            actuatorController: a?.actuatingBoardName ?? "--",
            portNumber: a?.portNumber ?? -1,
        }));

        
        const columns = [
            {
                title: "Actuator Name",
                dataIndex: "actuatorName"
            },
            {
                title: "Actuator Controller",
                dataIndex: "actuatorController"
            },
            {
                title: "Port Number",
                dataIndex: "portNumber"
            }
        ];
    
        return (
            <div style={{ maxHeight: 700, maxWidth: 700, overflowY: "auto", border: "1px solid #e8e8e8" }}>
                <Table dataSource={updatedData} columns={columns} pagination={false} scroll={{ y: 500 }} />
            </div>
        );
    };

    const getReviewSection = () => {
        const binName = state?.chosenDeviceId ?? "N/A";
        const binId = state?.binID ?? 0;
        const diameter = values?.diameter ?? 0.0;
        const maxGrainEaveHeight = values?.maxGrainEaveHeight ?? 0.0;

        return (
            <Card title="Review section" extra={<span style={{justifyContent:"left"}}>click validate to re-render</span>}>
                <Descriptions bordered={true} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} size="small">
                    <Descriptions.Item label={"Device ID"}>{binName}</Descriptions.Item>
                    <Descriptions.Item label={"Bin ID"}>{binId}</Descriptions.Item>
                    <Descriptions.Item label={"Diameter"}>{diameter}</Descriptions.Item>
                    <Descriptions.Item label={"Max Grain Eave Height"}>{maxGrainEaveHeight}</Descriptions.Item>
                    <Descriptions.Item label="Sensors">
                        {" "}
                        <SensorWindow />{" "}
                    </Descriptions.Item>
                    <Descriptions.Item label="Actuators">
                        {" "}
                        <ActuatorWindow />{" "}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        );
    };

    return (
        <Layout.Content className="createBinAssembly">
            <div>
                <Text>Device: </Text>
                <Select
                    style={{ width: "32ch", maxHeight: "300px", overflow: "auto" }}
                    placeholder="Select Device"
                    showSearch
                    optionFilterProp="children"
                    onSelect={(value: number | string | LabeledValue, option: typeof Option) => binIdClicked(value.toString(), false)}
                >
                    {binIdDropdownValues()}
                </Select>
            </div>

            {state.chosenDeviceId && (
                <div>
                    <fieldset>
                        <legend>
                            <b>Hardware & Layout</b> -{" "}
                            <i>
                                {" "}
                                <small>Changes to this section require restarting the module</small>{" "}
                            </i>
                            
                        </legend>
                    </fieldset>

                    <Row>
                        <Col span={13} style={{ paddingRight: "10px" }}>
                            <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                                <TabPane tab="Basic" key="1">
                                <i>
                                    <Row>
                                        <small>If any problems with generation of devices is noticed, delete the devices of that board family and click validate</small>
                                    </Row>
                                    <Row>
                                        <small>Auto-generation of 2021 systems is not supported. you will have to add devices manually.</small>
                                    </Row>
                                </i>
                                    <Form
                                        scrollToFirstError={true}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                        // ref={_formRef}
                                        form={form}
                                    >
                                        <Row>
                                            <Col>
                                                <Form.Item name={"binID"} />
                                            </Col>
                                            <Col>
                                                <Form.Item name={"deviceID"} />
                                            </Col>
                                            <Col>
                                                <Form.Item name={"binAssembly"} />
                                            </Col>
                                        </Row>
                                        <div>
                                            <Row style={{ justifyContent: "right" }}>
                                                <Col>
                                                    <Button icon={<EditOutlined />} onClick={() => setShowModalFromSheet(true)}>
                                                        Fill from Module Twin spreadsheet
                                                    </Button>
                                                </Col>
                                                <Col style={{ paddingLeft: "10px" }}>
                                                    <Form.Item>
                                                        <Button type="primary" 
                                                        onClick={() => {
                                                            setAreYouSureClearAllModalOpen(true);
                                                            
                                                        }} 
                                                        style={{ float: "right" }}
                                                        >
                                                            Clear All
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ paddingLeft: "10px" }}>
                                                    <Form.Item>
                                                        <Button disabled={!enableSubmitButton} type="primary" htmlType="submit" style={{ float: "right" }}>
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ paddingLeft: "10px" }}>
                                                    <Form.Item>
                                                        <Button type="primary" 
                                                        onClick={() => {
                                                            regenerateAssembly(null);
                                                            // regenerateAssembly(null)
                                                        }} 
                                                        style={{ float: "right" }}
                                                        >
                                                            Validate
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* <Button icon={<EditOutlined />} onClick={() => setShowModalFromSheet(true)}>
                                                    Fill from Module Twin spreadsheet
                                                </Button> */}
                                        <Card title="General" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                            <Row gutter={8} style={{ paddingBottom: "20px" }}>
                                                <Col style={{ paddingRight: "30px" }}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>System Year</span>}
                                                        name={"systemYear"}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value={2021}>2021</Radio>
                                                            <Radio value={2022}>2022</Radio>
                                                            <Radio value={2023}>2023</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>

                                                <Col style={{ paddingRight: "30px" }} >
                                                    <Form.Item
                                                        name={"binType"}
                                                        label={<span style={{ fontWeight: "bold" }}>Automation Type</span>}
                                                    >
                                                        <Radio.Group name="automationType">
                                                            <Radio value={AutomationType.DriStack}>DriStack</Radio>
                                                            <Radio value={AutomationType.AutoBin}>AutoBin</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row style={{ paddingBottom: "20px" }}>
                                                <Col style={{ paddingRight: "30px" }}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Bin Diameter Ft.</span>}
                                                        name={"diameter"}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <InputNumber min={0} style={{ width: "75px" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col style={{ paddingRight: "30px" }}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Grain Eave Height Ft.</span>}
                                                        name={"maxGrainEaveHeight"}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <InputNumber min={0} style={{ width: "75px" }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Fans have heater?</span>}
                                                        name={"fansHaveHeaters"}
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col style={{paddingRight:"10px"}}>
                                                    <Button onClick={() => setDeviceProfModalOpen(true)}>Device Profiles</Button>
                                                </Col>

                                                <Col style={{paddingRight:"10px"}}>
                                                    <Form.Item
                                                    name={"hasCamera"}
                                                    key={"hasCamera"}
                                                    label={"Bin Has Camera?"}
                                                    >
                                                        <Switch/>

                                                    </Form.Item>
                                                </Col>
                                                
                                            </Row>
                                            {/* <Row style={{justifyContent:"center"}}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Has Camera?</span>}
                                                        name={"hasCamera"}
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Has HMI?</span>}
                                                        name={"hasHMI"}
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>
                                            </Row> */}
                                        </Card>

                                        <Card title="Layers" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                            {InputListForm(["layerTopHeights"], "Layer top heights Ft.", false, null)}
                                        </Card>

                                        <Card title="Fans" style={{ margin: "10px 0" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                            {InputListForm(["fanBearings"], "Fan Bearings Degrees", false, ["fanInletAreaFtSq"])}
                                            {InputListForm(["fanInletAreaFtSq"], "Fan areas FtSq.", false, ["fanBearings"])}
                                            {/* {InputListForm(["fanInletAreaInSq"], "Fan areas InSq.", false, ["fanBearings"])} */}
                                        </Card>

                                        <Card
                                            title="Rings" headStyle={{ backgroundColor: "#f0f0f0" }}
                                        >
                                            <Form.List name={"rings"}>
                                                {(fields, { add, remove }) => {
                                                    
                                                    return (
                                                        <div style={{ marginLeft: "10px" }}>
                                                            <Row>
                                                                <PlusCircleOutlined
                                                                    style={{ margin: "8px" }}
                                                                    onClick={() => {
                                                                        add();
                                                                    }}
                                                                />
                                                                {fields.length > 0 ? (
                                                                    <MinusCircleOutlined
                                                                        style={{ margin: "8px" }}
                                                                        onClick={() => {
                                                                            remove(fields.length - 1);
                                                                        }}
                                                                    />
                                                                ) : null}
                                                            </Row>

                                                            {fields.map((field, index) => {


                                                                return (
                                                                    <Card title={`Ring ${index}`} style={{ margin: "10px 0", marginLeft: "25px", paddingBottom: "25px" }} headStyle={{ backgroundColor: "#f0f0f0" }}>
                                                                        <div style={{ margin: "10px 0" }}>
                                                                            <Row gutter={16} style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                                                <Col>
                                                                                    <Form.Item 
                                                                                    name={[field.name, "ringNumber"]} 
                                                                                    key={`ringNumber-${field.name}`} 
                                                                                    initialValue={index}
                                                                                    />                                                                                   
                                                                                </Col>
                                                                                <Col style={{ paddingRight: "5px" }}>
                                                                                    <Form.Item
                                                                                        label="Radius Ft."
                                                                                        name={[field.name, "radiusFt"]}
                                                                                        key={`radiusFt-${field.name}`}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Please enter the ring radius"
                                                                                            }
                                                                                        ]}
                                                                                    >
                                                                                        <InputNumber style={{ width: "75px" }} />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                {
                                                                                    values.binType === AutomationType.DriStack &&
                                                                                    <Col style={{ paddingRight: "5px" }}>
                                                                                        <Form.Item
                                                                                            label="Stack Height Ft."
                                                                                            name={[field.name, "stackHeightFt"]}
                                                                                            key={`stackHeightFt-${field.name}`}
                                                                                        >
                                                                                            <InputNumber style={{ width: "75px" }} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                }

                                                                                <Col style={{ paddingRight: "5px" }}>
                                                                                    <Form.Item
                                                                                        label="Cable Height Ft."
                                                                                        name={[field.name, "sensorCableHeightFt"]}
                                                                                        key={`sensorCableHeightFt-${field.name}`}
                                                                                    >
                                                                                        <InputNumber style={{ width: "75px" }} />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row style={{ paddingBottom: "10px" }}>
                                                                                <Form.Item
                                                                                    label={"Has GAM?"}
                                                                                    name={[field.name, "hasGam"]}
                                                                                    key={`hasGam-${field.name}`}
                                                                                >
                                                                                    <Switch 
                                                                                    onChange={ (val) =>{
                                                                                        if(val === false){  
                                                                                            form.setFieldValue(["rings", field.name, "gamHeightFt"], null);
                                                                                            form.setFieldValue(["rings", field.name, "gamBearing"], null);
                                                                                            form.setFieldValue(["rings", field.name, "gamBoardNumber"], null);
                                                                                            form.setFieldValue(["rings", field.name, "gamPort"], null);
                                                                                        }
                                                                                    }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Row>
                                                                            {values.rings?.[field.name]?.hasGam && (
                                                                                <>
                                                                                    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                                                        <Col style={{ paddingRight: "5px" }}>
                                                                                            <Form.Item
                                                                                                label="GAM Height Ft."
                                                                                                name={[field.name, "gamHeightFt"]}
                                                                                                key={`gamHeightFt-${field.name}`}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: values.rings?.[field.name].hasGam
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <InputNumber style={{ width: "75px" }} />
                                                                                            </Form.Item>
                                                                                        </Col>

                                                                                        <Col style={{ paddingRight: "5px" }}>
                                                                                            <Form.Item
                                                                                                label="GAM Bearing"
                                                                                                name={[field.name, "gamBearing"]}
                                                                                                key={`gamBearing-${field.name}`}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: values.rings?.[field.name].hasGam
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <InputNumber style={{ width: "75px" }} />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                                                                                        <Col style={{ paddingRight: "5px" }}>
                                                                                            <Form.Item
                                                                                            key={`gamBoardNumber-${field.key}`}
                                                                                            label={`Controller`}
                                                                                            name={[field.name, "gamBoardNumber"]}
                                                                                            rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Enter the controller name"
                                                                                            }]}
                                                                                            >
                                                                                                <InputNumber/>
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                        <Col style={{ paddingRight: "5px" }}>
                                                                                            <Form.Item
                                                                                                label="Port"
                                                                                                name={[field.name, "gamPort"]}
                                                                                                key={`gamPort-${field.name}`}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: values.rings?.[field.name].hasGam
                                                                                                    }
                                                                                                ]}
                                                                                            >
                                                                                                <InputNumber style={{ width: "75px" }} />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                            )}
                                                                        </div>

                                                                        {
                                                                            values.binType === AutomationType.DriStack &&
                                                                            <div style={{ margin: "10px 0" }}>

                                                                                {InputListForm([field.name, "stackBearings"], "Stack bearing", true, ["valveHeights"])}

                                                                                {InputListForm([field.name, "valveHeights"], "Valve height Ft.", true, null)}

                                                                                <Divider />
                                                                            </div>
                                                                        }

                                                                        <div style={{ margin: "10px 0" }}>
                                                                            {InputListForm([field.name, "opiCableBearings"], "OPI cable bearing", true, ["opiSensorHeights", "opiCableMappings"])}
                                                                            {
                                                                                (values.rings?.[field.name]?.opiCableBearings?.length ?? 0) > 0 &&
                                                                                <>
                                                                                    {InputListForm([field.name, "opiSensorHeights"], "OPI sensor height Ft.", true, null)}

                                                                                    <Form.List name={[field.name, "opiCableMappings"]}>
                                                                                        {(fields, { add, remove }) => (
                                                                                            <>         
                                                                                                <Row style={{ paddingBottom: "20px" }}>
                                                                                                    <Col style={{ paddingRight: "10px" }}>
                                                                                                        <span>OPI Cable mappings</span>
                                                                                                    </Col>
                                                                                                    <Col style={{ paddingRight: "10px" }}>
                                                                                                        <Button
                                                                                                            icon={<MinusCircleOutlined />}
                                                                                                            onClick={() => {
                                                                                                                if (fields.length > 0) {
                                                                                                                    remove(fields.length - 1);
                                                                                                                }
                                                                                                            }}
                                                                                                        />

                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Form.Item>
                                                                                                            <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    {fields.map( (field, index) => {

                                                                                                        return (
                                                                                                            <div style={{marginLeft:"25px"}}>
                                                                                                                <Row>
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`cableNumber-${field.name}`}
                                                                                                                            label={"Cable"}
                                                                                                                            name={[field.name, "cableNumber"]}
                                                                                                                            rules={[
                                                                                                                            {
                                                                                                                                required: true
                                                                                                                            }]}
                                                                                                                            >

                                                                                                                                <InputNumber min={0} style={{ width: "100px" }} />

                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`boardNumber-${field.key}`}
                                                                                                                            label={`Controller`}
                                                                                                                            name={[field.name, "boardNumber"]}
                                                                                                                            rules={[
                                                                                                                            {
                                                                                                                                required: true,
                                                                                                                                message: "Enter the controller name"
                                                                                                                            }]}
                                                                                                                        
                                                                                                                            >
                                                                                                                                <InputNumber min={0} style={{width:"75px"}}/>
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>

                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`port-${field.name}`}
                                                                                                                            label={"Port"}
                                                                                                                            name={[field.name, "port"]}
                                                                                                                            rules={[
                                                                                                                                {
                                                                                                                                    required: true
                                                                                                                                }
                                                                                                                            ]}
                                                                                                                            >
                                                                                                                                <InputNumber min={0}  style={{ width: "100px" }} />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </Row>
                                                                                                                <Divider/>
                                                                                                            </div>  
                                                                                                        );
                                                                                                    })}
                                                                                                </Row>
                                                                                            </>
                                                                                        )}
                                                                                    </Form.List>
                                                                                </>
                                                                            }
                                                                        </div>

                                                                        <div style={{ margin: "10px 0" }}>
                                                                            {InputListForm([field.name, "tempCableBearings"], "Temp. Cable Bearings", true, ["thermoucoupleHeights", "tempCableMappings"])}
                                                                            {
                                                                                (values.rings?.[field.name]?.tempCableBearings?.length ?? 0) > 0 &&
                                                                                <>
                                                                                    {InputListForm([field.name, "thermoucoupleHeights"], "Thermocouple height Ft.", true, null)}
                                                                                    <Form.List name={[field.name, "tempCableMappings"]}>
                                                                                        {(fields, { add, remove }) => (
                                                                                            <>         
                                                                                                <Row style={{ paddingBottom: "20px" }}>
                                                                                                    <Col style={{ paddingRight: "10px" }}>
                                                                                                        <span>Temp Cable mappings</span>
                                                                                                    </Col>
                                                                                                    <Col style={{ paddingRight: "10px" }}>
                                                                                                        <Button
                                                                                                            icon={<MinusCircleOutlined />}
                                                                                                            onClick={() => {
                                                                                                                if (fields.length > 0) {
                                                                                                                    remove(fields.length - 1);
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Form.Item>
                                                                                                            <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    {fields.map( (field, index) => {

                                                                                                        return (
                                                                                                            <div style={{marginLeft:"25px"}}>
                                                                                                                <Row>
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`cableNumber-${field.name}`}
                                                                                                                            label={"Cable"}
                                                                                                                            name={[field.name, "cableNumber"]}
                                                                                                                            rules={[
                                                                                                                                {
                                                                                                                                    required: true
                                                                                                                                }
                                                                                                                            ]}
                                                                                                                            >
                                                                                                                                <InputNumber min={0} style={{ width: "75px" }} />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`sensorStartNumber-${field.name}`}
                                                                                                                            label={"Sensor Start"}
                                                                                                                            name={[field.name, "sensorStartNumber"]}
                                                                                                                            >
                                                                                                                                <InputNumber min={0}  style={{ width: "75px" }} />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`sensorEndNumber-${field.name}`}
                                                                                                                            label={"Sensor End"}
                                                                                                                            name={[field.name, "sensorEndNumber"]}
                                                                                                                            >
                                                                                                                                <InputNumber min={0}  style={{ width: "75px" }} />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    
                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                        <Col flex="auto">
                                                                                                                            <Form.Item
                                                                                                                            key={`boardNumber-${field.key}`}
                                                                                                                            label={`Controller`}
                                                                                                                            name={[field.name, "boardNumber"]}
                                                                                                                            rules={[
                                                                                                                            {
                                                                                                                                required: true,
                                                                                                                                message: "Enter the controller name"
                                                                                                                            }]}
                                                                                                                            >
                                                                                                                                <InputNumber min={0} style={{ width:"75px"}} />
                                                                                                                            </Form.Item>
                                                                                                                        </Col>
                                                                                                                    </Row>

                                                                                                                    <Row gutter={8} align="middle">
                                                                                                                            <Col flex="auto">
                                                                                                                                <Form.Item
                                                                                                                                key={`port-${field.name}`}
                                                                                                                                label={"Port"}
                                                                                                                                name={[field.name, "port"]}
                                                                                                                                rules={[
                                                                                                                                {
                                                                                                                                    required: true
                                                                                                                                }]}
                                                                                                                                >
                                                                                                                                    <InputNumber min={0}  style={{ width: "75px" }} />
                                                                                                                                </Form.Item>
                                                                                                                        </Col> 
                                                                                                                    </Row>
                                                                                                                </Row>
                                                                                                                <Divider/>
                                                                                                            </div>  
                                                                                                        );
                                                                                                    })}
                                                                                                </Row>
                                                                                            </>
                                                                                        )}
                                                                                    </Form.List>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </Card>
                                                                );
                                                            })}

                                                            <Row style={{ justifyContent: "right" }}>
                                                                <Col style={{ paddingLeft: "10px" }}>
                                                                    <Form.Item>
                                                                        <Button type="primary" 
                                                                        onClick={() => {
                                                                            setAreYouSureClearAllModalOpen(true);
                                                                            
                                                                        }} 
                                                                        style={{ float: "right" }}
                                                                        >
                                                                            Clear All
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col style={{ paddingLeft: "10px" }}>
                                                                    <Form.Item>
                                                                        <Button disabled={!enableSubmitButton} type="primary" htmlType="submit" style={{ float: "right" }}>
                                                                            Submit
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col style={{ paddingLeft: "10px" }}>
                                                                    <Form.Item>
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                regenerateAssembly(null)
                                                                                // regenerateAssembly(null)
                                                                            }}
                                                                            style={{ float: "right" }}
                                                                        >
                                                                            Validate
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }}
                                            </Form.List>
                                        </Card>

                                    </Form>
                                </TabPane>
                                {/* <TabPane tab="Advanced" key="2">

                                    <h1>Coming soon....</h1>
                                </TabPane> */}
                            </Tabs>
                        </Col>

                        <Col span={11}>{getReviewSection()}</Col>
                    </Row>
                </div>
            )}

            <Modal
            title="Device Profile"
            cancelButtonProps={{ style: { display: "none" } }} // or { hidden: true }
            open={deviceProfileModalOpen}
            onOk={() => setDeviceProfModalOpen(false)}
            onCancel={() => setDeviceProfModalOpen(false)}
            >
                <DeviceProfileTable
                    deviceProfiles={(form.getFieldValue(["binAssembly", "devices"]) ?? []) as DeviceProfileDTO[]}
                    pcbLookups={state.pcbLookups}
                    onUpdateDevices ={(assemb: DeviceProfileDTO[]) => {
                        form.setFieldValue(["binAssembly", "devices"], assemb);
                    }}

                />
            </Modal>

            <DeviceUpdateModalV2
                open={showModalFromSheet}
                submittalForm={form}
                onOk={() => setShowModalFromSheet(false)}
                onCancel={() => setShowModalFromSheet(false)}
                createSubmittalFromText={createSubmittalFromText}
                binId={state.binID}
                deviceId={state.chosenDeviceId}
            />

            <Modal 
            open={areYouSureClearAllModalOpen} 
            centered 
            closable={false} 
            footer={null} 
            width={300} 
            style={{textAlign: "center"}}
            >
                <h2>Are you sure you want to clear all fields?</h2>
                <Button onClick={() => setAreYouSureClearAllModalOpen(false)}
                style={{marginRight: "10px"}}
                >
                    No
                </Button>
                <Button onClick={() => {
                    form.resetFields()
                    setAreYouSureClearAllModalOpen(false);
                }} 
                style={{marginRight: "10px"}}
                >
                    Yes
                </Button>
            </Modal>
            <Modal open={loading} centered closable={false} footer={null} width={100} style={{ textAlign: "center" }}>
                <Spin size="large" />
            </Modal>
        </Layout.Content>
    );
};
