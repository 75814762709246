import React, {PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, InputNumber, message, Modal, Popconfirm, Popover, Row, Space, Tag, Tooltip, Typography } from "antd";

// @ts-ignore
import style from "./form.module.css";

import FanDTO from "src/models/FanDTO";
import BinDTO from "src/models/BinDTO";
import { OperatingMode, fanDurationFormatted } from "../dashboard/BinStatusPage/BinVisualThree";
import { formatFanAmps } from "../dashboard/BinStatusPage/FanCard";

import ReportedFanSettingsDTO from "src/models/ReportedFanSettingsDTO";
import { fanStatusText, pauseReasonText, WeatherConditionsLimitTooltipText } from "./shared";
import { fillEmpty, formatTotalSecondsToDays, SettingsFormValues, WeatherMonitorForm } from "./WeatherMonitorForm";
import { Prompt } from "react-router";
import { FormInstance } from "antd/lib/form/Form";
import { formatPlenumPressure } from "src/utils/formatting";
import { CheckCircleFilled, CiCircleFilled, CloseCircleFilled, EditOutlined, InfoCircleFilled, QuestionCircleOutlined, WarningFilled } from "@ant-design/icons";
import { useForm, useWatch } from "antd/es/form/Form";
import { useMutation } from "@tanstack/react-query";
import BinAPIService from '../../api/BinApiService';
import { layout2, warningIconColor } from "../dashboard/BinStatusPage/BinStatsPage";
import dayjs from "dayjs";
import {bind, round} from 'lodash-es';
import { formatNumber } from "../dashboard/BinStatusPage/HeaterControls";
import { Duration, DurationUnitType } from "dayjs/plugin/duration";
import FanOffReason from "src/consts/FanOffReason";
import { getDefaultTemperatureSensorType } from "../shared/binDTOUtils";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import { useBinDTOContext } from "src/queries/BinDTOContext";
import { weatherConditionEvaluation } from "src/queries/BinDTOContext";
import { WeatherConditionRangeStatus } from "src/queries/BinDTOContext";
import { computeWeatherConditionInfo } from "src/queries/BinDTOContext";

export const isAnyFanOn = (fans: FanDTO[] | null): boolean | null => {
    if (fans == null) {
        return null;
    }

    for (const fan of fans) {
        if (fan.isOn === true) {
            return true;
        }
    }
    return false;
}
interface WeatherMonitorProps {
    binDTO: BinDTO,
    deviceId: string,
    loading: boolean,
    form?: FormInstance<SettingsFormValues>,
    onCancel?: () => void,
    /**
     *
     * @param data the fan form settings to apply
     * @returns if submit to server was successful
     */
    onSubmit: (data: SettingsFormValues) => Promise<boolean>,
    newFanSettings: Partial<ReportedFanSettingsDTO> | null | undefined,
    operatingMode: OperatingMode,

}

interface FanReadingProps {
    ambientAirCombined: BinDTO['ambientAir'],
    plenumAirCombined: BinDTO['plenumAir'],
    fans: BinDTO['fans'],
    bin: BinDTO,
    fanRunTimeSeconds: number | undefined,
}


export const isAtLeastOneLayerBelowDewpoint = (binDTO: BinDTO): boolean | null => {
    let grainLayerAtRiskOfCondensation: boolean | null = null;
    const preferredTemperatureSensorType = binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(binDTO);

    const theLayerBelowDewpoint = binDTO?.layerGrainStates?.find(layer => {

        const temperatureF = [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(preferredTemperatureSensorType) ? layer.temperatureF : layer.thermocoupleTemperatureF;
        if (temperatureF == null) {
            return false;
        }
        if (binDTO?.plenumAir?.dp == null) {
            return false;
        }
        return temperatureF < binDTO?.plenumAir?.dp;
    });
    grainLayerAtRiskOfCondensation = theLayerBelowDewpoint != null;
    return grainLayerAtRiskOfCondensation;
}

export interface AdditionalWeatherConditionDerived {
    inRangeInstant: boolean | null,
    inRangeLong: boolean,
    emcInRange: boolean | null,
    temperatureInRange: boolean | null,
    grainLayerAtRiskOfCondensation: boolean | null,
    heaterOffsetApplied: boolean,
    timeTillPhaseChange: Duration | null,
    weatherConditionRangeStatus: WeatherConditionRangeStatus,
}


interface WeatherConditionStatusIndicatorProps {
    binDTO: BinDTO,
}

interface WeatherConditionRangeTooltip {

}

const WeatherConditionRangeTooltip = (props: PropsWithChildren<WeatherConditionRangeTooltip>) => {
    
    return <Tooltip overlayStyle={{minWidth: "400px"}} title={<WeatherConditionsLimitTooltipText />}>
        <span>
            {props.children}
        </span>
    </Tooltip>
}

export const AmbientConditionsStatusIndicator = (props: WeatherConditionStatusIndicatorProps) => {

    if (props.binDTO == null) {
        return null;
    }
    

    const weatherConditionExtraInfo = useMemo(() => computeWeatherConditionInfo(props.binDTO), [props.binDTO]);
    //console.log("weather conditions extra info: ", weatherConditionExtraInfo);
    if (weatherConditionExtraInfo == null) {
        return null;
    }

    // hide if in manual mode & not in auto fan mode.
    if (props.binDTO?.operatingMode === OperatingMode.Manual && (props.binDTO?.fanOperations?.desiredFanOn === false || (props.binDTO?.fanOperations?.desiredFanOn === true && props.binDTO?.fanOperations?.ignoreWeatherConditions))) {
        return null;
    }

    // only show in modes where weather monitor is used
    if (![OperatingMode.FillBin, OperatingMode.PreDry, OperatingMode.TopDry, OperatingMode.Dry, OperatingMode.Storage, OperatingMode.Manual].includes(props.binDTO?.operatingMode)) {
        return null;
    }

    const weatherConditionStatusResult = weatherConditionEvaluation(props.binDTO);
    
        if (weatherConditionStatusResult === WeatherConditionRangeStatus.FullyIn) {
            return <Tooltip overlayStyle={{minWidth: "320px"}} title={<WeatherConditionsLimitTooltipText />}>
                    <span>
                        <CheckPassedIcon />
                    </span>
                </Tooltip>
        }
        else if ([WeatherConditionRangeStatus.Entering, WeatherConditionRangeStatus.Exitting].includes(weatherConditionStatusResult)) {

            return <Tooltip overlayStyle={{minWidth: "400px"}} title={<WeatherConditionsLimitTooltipText />}>
                    <span>
                        <WarningInfoIcon />
                    </span>
                </Tooltip>
        }
        else if (weatherConditionStatusResult === WeatherConditionRangeStatus.Outside) {
                return <Tooltip overlayStyle={{minWidth: "320px"}} title={<WeatherConditionsLimitTooltipText />}>
                        <span>
                            <CheckFailedIcon />
                        </span>
                    </Tooltip>
            }
        else {
            return null;
        }
    }

export const DewPointWarningTooltipByFanReadings = () => {

    return <Tooltip title={"Grain at risk of condensation because the grain temperature of at least one layer is below the incoming air dew point."}>
        <span>
            <WarningInfoIcon />
        </span>
    </Tooltip>
}

export const DewPointOkTooltipByFanReadings = () => {

    return <Tooltip title="The grain temperature is above the incoming air dew point, which means the risk of condensation is low.">
            <span>
                <CheckPassedIcon />
            </span>
    </Tooltip>
}

export const CheckPassedIcon = () => {
    return <CheckCircleFilled style={{color: "green"}} />
}

export const WarningInfoIcon = () => {
    return <WarningFilled style={{color: warningIconColor}} />
}

export const CheckFailedIcon = () => {
    return <CloseCircleFilled style={{color: 'red'}} />
}

export const EMCInRangeToolTip = () => {

    return <WeatherConditionRangeTooltip>
        <span>
            <CheckPassedIcon />
        </span>
    </WeatherConditionRangeTooltip>
}

export const EMCRangeTransitionTooltip = () => {
    return <WeatherConditionRangeTooltip>
        <span>
            <WarningInfoIcon />
        </span>
    </WeatherConditionRangeTooltip>
}

export const EmcOutOfRangeTooltip = () => {
    return <WeatherConditionRangeTooltip>
        <span>
            <CheckFailedIcon />
        </span>
    </WeatherConditionRangeTooltip>
}


export const TemperatureInRangeToolTip = () => {

    return <WeatherConditionRangeTooltip>
        <span>
            <CheckPassedIcon />
        </span>
    </WeatherConditionRangeTooltip>
}

export const TemperatureRangeTransitionTooltip = () => {
    return <WeatherConditionRangeTooltip>
        <span>
            <WarningInfoIcon />
        </span>
    </WeatherConditionRangeTooltip>
}

export const TemperatureOutOfRangeTooltip = (props: {}) => {
    return <WeatherConditionRangeTooltip>
        <span>
            <CheckFailedIcon />
        </span>
    </WeatherConditionRangeTooltip>
}

export const TemperatureRangeIndicator = (props: {}) => {
    const {additionalWeatherInfo} = useBinDTOContext();
    if (additionalWeatherInfo?.temperatureInRange == null) {
        return null;
    }

    if (additionalWeatherInfo.temperatureInRange) {
        return <TemperatureInRangeToolTip />;
    }
    else {
        return <TemperatureOutOfRangeTooltip />;
    }
}

export const EmcRangeIndicator = () => {
    const {additionalWeatherInfo, binDTO} = useBinDTOContext();
    //console.log("additional weather info: ", {binDTO, additionalWeatherInfo});
    if (additionalWeatherInfo?.emcInRange == null) {
        return null;
    }

    if (additionalWeatherInfo.emcInRange) {
        return <EMCInRangeToolTip />;
    }
    else {
        return <EmcOutOfRangeTooltip />;
    }
}

export const CurrentReadings = (props: FanReadingProps) => {

    const {additionalWeatherInfo} = useBinDTOContext();

    const anyFanOn = isAnyFanOn(props.fans);
    const AmbientemcText = () => {
        if (props.ambientAirCombined?.mc == null) {
            return <span style={{ whiteSpace: "nowrap" }}>no data</span>;
        }
        return `${props.ambientAirCombined?.mc.toFixed(1)}%`;
    }

    const plenumEMCText = () => {
        if (props.plenumAirCombined?.mc == null) {
            return <span style={{ whiteSpace: "nowrap" }}>no data</span>;
        }
        return `${props.plenumAirCombined?.mc.toFixed(1)}%`;
    }

    const AmbientTempText = () => {
        if (props.ambientAirCombined?.temp == null) {
            return <span style={{ whiteSpace: "nowrap" }}>no data</span>;
        }
        return `${props.ambientAirCombined.temp.toFixed(1)}℉`;
    }

    const plenumTempText = () => {
        if (props.plenumAirCombined?.temp == null) {
            return <span style={{ whiteSpace: "nowrap" }}>no data</span>;
        }
        return `${props.plenumAirCombined.temp.toFixed(1)}℉`;
    }

    const isAtLeastOneLayerBelowDewpointResult = useMemo(() => isAtLeastOneLayerBelowDewpoint(props.bin), [props.bin]);


    return <>
        <Typography.Title level={5}>Incoming Air Conditions</Typography.Title>
        <Space direction="vertical" size="middle">
            <Row >
                <Space direction="vertical" size={0}>
                    {anyFanOn === false && <>
                        <Space direction="horizontal" size="small">
                        <Typography.Text>Ambient EMC: <b>{AmbientemcText()}</b></Typography.Text>
                        <EmcRangeIndicator />
                    </Space>
                    </>}
                    {anyFanOn === false && <>
                            <Space direction="horizontal" size="small">
                                <Typography.Text>Ambient Temp: <b>{AmbientTempText()}</b></Typography.Text>
                                <TemperatureRangeIndicator />
                            </Space>
                        </>
                    }
                    {anyFanOn === false && <><Typography.Text>Ambient Dew Point: <b>{formatNumber(props.bin?.ambientAir?.dp!, { suffix: " ℉", decimalPlaces: 1 })}</b>
                        {isAtLeastOneLayerBelowDewpointResult === true && <>&nbsp;&nbsp;<DewPointWarningTooltipByFanReadings /></>}
                        {isAtLeastOneLayerBelowDewpointResult === false && <>&nbsp;&nbsp;<DewPointOkTooltipByFanReadings /></>}
                    </Typography.Text>
                    </>
                    }
                    {anyFanOn === true && <>
                        <Space direction="horizontal" size="small">
                        <Typography.Text>Plenum EMC: <b>{plenumEMCText()}</b></Typography.Text>
                        <EmcRangeIndicator />
                    </Space>
                    </>}
                    {anyFanOn === true && <>
                        <Space direction="horizontal" size="small">
                            <Typography.Text>Plenum Temp: <b>{plenumTempText()}</b></Typography.Text>
                            <TemperatureRangeIndicator />
                        </Space>
                    </>}
                    {anyFanOn === true && <><Typography.Text>Plenum Dew Point: <b>{formatNumber(props.bin?.plenumAir?.dp!, { suffix: " ℉", decimalPlaces: 1 })}</b>
                        {isAtLeastOneLayerBelowDewpointResult === true && <>&nbsp;&nbsp;<DewPointWarningTooltipByFanReadings /></>}
                        {isAtLeastOneLayerBelowDewpointResult === false && <>&nbsp;&nbsp;<DewPointOkTooltipByFanReadings /></>}
                    </Typography.Text>
                    </>}

                </Space>
            </Row>
            <Row>
                <Col>
                    <Typography.Title level={5} >Fan Status</Typography.Title>
                    {fanStatusText(props.bin)}
                    <Typography.Paragraph>
                        {pauseReasonText(props.bin)}
                    </Typography.Paragraph>
                </Col>
            </Row>

            <Row>
                <Space direction="vertical">
                    {props.fans?.map(fan => {

                        return (
                            <section key={fan.id}>
                                <Row justify={"space-between"} style={{width: "180px"}}>
                                    <Col>
                                        <Typography.Text strong>Fan {fan.id}</Typography.Text>
                                    </Col>
                                    <Col>
                                        <Typography.Text strong>{fan.isOn ? "ON" : "OFF"}</Typography.Text>
                                    </Col>
                                </Row>
                                <Typography.Text strong>{formatFanAmps(fan.ampReading)}</Typography.Text> <Typography.Text> Amps</Typography.Text>
                                {fan.plenumPressure != null && <>
                                    <br />
                                    <Typography.Text strong>{formatPlenumPressure(fan.plenumPressure)}</Typography.Text> <Typography.Text> H2O Plenum Pressure</Typography.Text>
                                </>}
                            </section>
                        );


                    })}
                </Space>

            </Row>
            {props.fanRunTimeSeconds != null &&
                <Space direction="vertical">
                    <Typography.Text strong>Fan Runtime</Typography.Text>
                    <Typography.Paragraph>The fans have run for <span style={{ whiteSpace: "nowrap" }}>{fanDurationFormatted((props.fanRunTimeSeconds ?? 0))}</span> <br /> during the current batch.</Typography.Paragraph>

                </Space>

            }
        </Space>
    </>;

}

// https://usehooks.com/usePrevious/
export const usePrevious = <T,>(value: T, updateFn?: (prev: T, current: T) => boolean): T => {

  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    // skip update if instructed
    if (updateFn?.(ref.current, value) === false) {
        return;
    }
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;

}

interface NonManualSettingsProps {
    binDTO: BinDTO,
    deviceId: string,
}

interface NonManualSettingsForm {
    binDTO: BinDTO;
    onFinish: (values: NonManualSettingsFormValues) => void;
}

interface NonManualSettingsFormValues {
    weatherConditionsMaxMc: number | null;
    weatherConditionsMinMc: number | null;
    weatherConditionsMaxTemperatureF: number | null;
    weatherConditionsMinTemperatureF: number | null;
}


const updateWeatherMonitorNonManualFormId = 'weatherSettings-nonstorage';
const updateFanTimerOnlyFormId = "updateFanTimerOnly";
const updateFanTimerOnlyPerWeekFormId = 'updateFanTimerOnlyPerWeekFormId';
const NonManualSettingsForm = (props: NonManualSettingsForm) => {

    const [form] = useForm<NonManualSettingsFormValues>();
    const fanRangeSettingsFormValues = useWatch([], form);


    const initialValues: NonManualSettingsFormValues = {
        weatherConditionsMinMc: props.binDTO?.weatherMonitorSettings?.weatherConditionsMinMc ?? null,
        weatherConditionsMaxMc: props.binDTO?.weatherMonitorSettings?.weatherConditionsMaxMc ?? null,
        weatherConditionsMaxTemperatureF: props.binDTO?.weatherMonitorSettings?.weatherConditionsMaxTemperatureF ?? null,
        weatherConditionsMinTemperatureF: props.binDTO?.weatherMonitorSettings?.weatherConditionsMinTemperatureF ?? null,
    };

    return (
    <Form preserve={false} form={form} {...layout2} initialValues={{
        ...initialValues
    }} onFinish={props.onFinish} id={updateWeatherMonitorNonManualFormId}>
        <Typography.Paragraph>
            Fan will run when incoming air EMC is b/t {fillEmpty(fanRangeSettingsFormValues?.weatherConditionsMinMc)} & {fillEmpty(fanRangeSettingsFormValues?.weatherConditionsMaxMc)}% & temp is b/t  {fillEmpty(fanRangeSettingsFormValues?.weatherConditionsMinTemperatureF)} & {fillEmpty(fanRangeSettingsFormValues?.weatherConditionsMaxTemperatureF)}℉
        </Typography.Paragraph>
        <Form.Item required name='weatherConditionsMinMc' label="Min EMC">
            <InputNumber />
        </Form.Item>
        <Form.Item required name='weatherConditionsMaxMc' label="Max EMC">
            <InputNumber />
        </Form.Item>
        <Form.Item required name='weatherConditionsMinTemperatureF' label="Min Temperature">
            <InputNumber />
        </Form.Item>
        <Form.Item required name='weatherConditionsMaxTemperatureF' label="Max Temperature">
            <InputNumber />
        </Form.Item>


    </Form>
    );
}

export const useSetWeatherMonitorSettings = (deviceId: string) => {

    return useMutation({
        mutationFn: async (values: NonManualSettingsFormValues) => {
            return await BinAPIService.setWeatherMonitorSettings(deviceId, {
                weatherConditionsMinMc: values.weatherConditionsMinMc,
                weatherConditionsMaxMc: values.weatherConditionsMaxMc,
                weatherConditionsMinTemperatureF: values.weatherConditionsMinTemperatureF,
                weatherConditionsMaxTemperatureF: values.weatherConditionsMaxTemperatureF,
            })
        }
    });
}

export const useUpdateFanTimer = (deviceId: string) => {

    return useMutation({
        mutationFn: async (values: {seconds: number}) => {
            return await BinAPIService.setFanRemainingOnTime({
                deviceId: deviceId,
                seconds: values.seconds,
            })
        }
    });
}

export const useUpdateFanTimerPerWeek = (deviceId: string) => {

    return useMutation({
        mutationFn: async (values: {secondsPerWeek: number}) => {
            return await BinAPIService.setFanRemainingOnTimePerWeek({
                deviceId: deviceId,
                seconds: values.secondsPerWeek,
            })
        }
    });
}

interface UpdateFanTImerFormProps {
    binDTO: BinDTO;
    onFinish: (values: FanTimerFormValues) => void;
}

const UpdateFanTimerForm  = (props: UpdateFanTImerFormProps) => {

    const [form] = useForm<FanTimerFormValues>();
    const getFanTimerFormInitialValues = (binDTO: BinDTO): FanTimerFormValues => {
        return {
            fanTimerHours: round((binDTO?.fanOperations?.fanRemainingOnTimeSeconds ?? 3600 * 2) / 3600, 2),
        }
    };

    const fanTimerInitialValues = getFanTimerFormInitialValues(props.binDTO);

    return (
        <Form form={form} onFinish={props.onFinish} preserve={false} id={updateFanTimerOnlyFormId} {...layout2} initialValues={fanTimerInitialValues}>
            <Form.Item required name="fanTimerHours" label="Fan Timer Hours">
                <InputNumber addonAfter='hours' />
            </Form.Item>
        </Form>
    );
}

const UpdateFanTimerPerWeekForm  = (props: UpdateFanTImerFormProps) => {

    const [form] = useForm<FanTimerFormValues>();
    const getFanTimerFormInitialValues = (binDTO: BinDTO): FanTimerFormValues => {
        return {
            fanTimerHours: round((binDTO?.fanOperations?.fanRemainingOnTimeSeconds ?? 3600 * 2) / 3600, 2),
        }
    };

    const fanTimerInitialValues = getFanTimerFormInitialValues(props.binDTO);

    return (
        <Form form={form} onFinish={props.onFinish} preserve={false} id={updateFanTimerOnlyPerWeekFormId} {...layout2} initialValues={fanTimerInitialValues}>
            <Form.Item required name="fanTimerHours" label="Fan Timer Weekly">
                <InputNumber addonAfter='hours' />
            </Form.Item>
        </Form>
    );

}

interface FanTimerFormValues {
    fanTimerHours: number,
}


const NonManualSettings = (props: NonManualSettingsProps) => {

    const minEmcForm = props.binDTO?.weatherMonitorState?.minMcLimit;
    const maxEmcForm = props.binDTO?.weatherMonitorState?.maxMcLimit;
    const minTempForm = props.binDTO?.weatherMonitorState?.minTemperatureLimitF;
    const maxTempForm = props.binDTO?.weatherMonitorState?.maxTemperatureLimitF;
    const timeLimit = props.binDTO?.fanOperations?.fanRemainingOnTimeSeconds;

    const mutateSetWeatherMonitorSettings = useSetWeatherMonitorSettings(props.deviceId);
    const mutateFanTimer = useUpdateFanTimer(props.deviceId);
    const mutateFanTimerPerWeek = useUpdateFanTimerPerWeek(props.deviceId);

    const [openUpdateWeatherSettings, setOpenWeatherSettings] = useState(false);
    const [openChangeFanTimer, setOpenChangeFanTimer] = useState(false);
    const [openChangeFanTimerPerWeek, setOpenChangeFanTimerPerWeek] = useState(false);

    const showTimer = ![OperatingMode.FillBin, OperatingMode.Idle, OperatingMode.EmptyBin, OperatingMode.Dry, OperatingMode.PreDry, OperatingMode.TopDry].includes(props.binDTO?.operatingMode!);

    const formatFanEMCText = () => {

        const originalMinEMC = minEmcForm;
        const originalMaxEMC = maxEmcForm;

        let offsetMaxEMC = maxEmcForm;
        if (offsetMaxEMC != null) {
            offsetMaxEMC += props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset! ?? -5;
        }
        const offsetActive = props.binDTO?.heaterOffsetShouldApplyNext;
        return <>
            <Typography.Text>Fan will run between {fillEmpty(originalMinEMC)}% and </Typography.Text>
            <Typography.Text delete={offsetActive}>{fillEmpty(originalMaxEMC)}%</Typography.Text>
            {offsetActive && <><Typography.Text>{fillEmpty(offsetMaxEMC)}%</Typography.Text>. <Tag color="red"> +{props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset}% Heater EMC Offset</Tag></>}
            </>


    }

    return <>
        <Space direction="vertical" size={12}>
            <section>
                <Typography.Title level={4}>Automated Fan Settings</Typography.Title>
                <Typography.Paragraph>Starts and stops fan(s) based on <span style={{ whiteSpace: "nowrap" }}>weather conditions.</span></Typography.Paragraph>
            </section>

            <section>
                <Space direction="horizontal">
                    <Typography.Title level={5}>EMC & Temperature Setpoint <Button onClick={() => {
                        setOpenWeatherSettings(true);
                    }} icon={<EditOutlined />}></Button></Typography.Title>
                    {/* <Popover content={EMCHelp} >
                        <QuestionCircleOutlined size={48} />
                    </Popover> */}
                </Space>
                <Typography.Paragraph>
                    {formatFanEMCText()}
                    <br />
                        <Typography.Text>AND while between {fillEmpty(minTempForm)} ℉ and {fillEmpty(maxTempForm)} ℉.</Typography.Text>
                        </Typography.Paragraph>

            </section>

            {showTimer && <section>
                <Typography.Title level={5}>Fan Timer {props.binDTO?.operatingMode === OperatingMode.Storage ? " (Weekly)" : ""} <Button icon={<EditOutlined />} onClick={() => {
                    if (props.binDTO?.operatingMode === OperatingMode.Storage) {
                        setOpenChangeFanTimerPerWeek(true);
                    }
                    else {
                        setOpenChangeFanTimer(true);
                    }
                }}></Button></Typography.Title>
                <Typography.Paragraph>Fan is set to run while within the specified temp & EMC setpoints</Typography.Paragraph>

                 <Typography.Paragraph>Current timer has {formatTotalSecondsToDays(dayjs.duration((timeLimit ?? 0) * 1000))} remaining{props.binDTO?.operatingMode === OperatingMode.Storage ? " for the week" : ""}.</Typography.Paragraph>
            </section>}

        </Space>

        <Modal title="EMC & Temperature Settings"
        style={{minWidth: "40vw"}}
         open={openUpdateWeatherSettings} destroyOnClose okText="Update EMC & Temperature Settings" onCancel={() => {
            setOpenWeatherSettings(false);
        }} okButtonProps={{form: updateWeatherMonitorNonManualFormId, htmlType: 'submit', type: 'primary',
        loading: mutateSetWeatherMonitorSettings.isLoading,
        disabled: mutateSetWeatherMonitorSettings.isLoading,
        }}>
            <NonManualSettingsForm  binDTO={props.binDTO} onFinish={async (values) => {
                mutateSetWeatherMonitorSettings.mutate({
                    ...values
                }, {onSuccess: async (data) => {
                    if (data.success === true) {
                        message.info("EMC & Temperature Settings updated");
                        setOpenWeatherSettings(false);
                    }
                    else {
                        message.error("System did not update EMC & Temperature Settings");
                    }
                }, onError(error, variables, context) {
                    console.error("Error updating EMC & Temperature Settings", error);
                    message.error("Problem updating EMC & Temperature Settings. Try again");
                },})
            }
            } />
        </Modal>

        <Modal destroyOnClose title="Update Fan Timer" open={openChangeFanTimer} okText="Update Fan Timer" onCancel={() => {
            setOpenChangeFanTimer(false);
        }} okButtonProps={{form: updateFanTimerOnlyFormId, htmlType: 'submit', type: 'primary', loading: mutateFanTimer.isLoading, disabled: mutateFanTimer.isLoading}}>
            {props.binDTO?.operatingMode !== OperatingMode.Storage && <UpdateFanTimerForm binDTO={props.binDTO} onFinish={async (values) => {
                const seconds = round(values.fanTimerHours * 3600);
                mutateFanTimer.mutate({seconds: seconds}, {onSuccess: (result) => {
                    if (result.success === true) {
                        message.success("Updated fan timer");
                        setOpenChangeFanTimer(false);
                    }
                    else {
                        message.error("Error updating fan timer");
                    }
                }});
            }} />}

            {props.binDTO?.operatingMode === OperatingMode.Storage && <UpdateFanTimerPerWeekForm binDTO={props.binDTO} onFinish={async (values) => {
                const seconds = round(values.fanTimerHours * 3600);
                mutateFanTimerPerWeek.mutate({secondsPerWeek: seconds}, {onSuccess: (result) => {
                    if (result.success === true) {
                        message.success("Updated fan timer");
                        setOpenChangeFanTimer(false);
                    }
                    else {
                        message.error("Error updating fan timer");
                    }
                }});
            }} />}
        </Modal>


        
        <Modal destroyOnClose title="Update Fan Timer (Storage) Weekly" open={openChangeFanTimerPerWeek} okText="Update Fan Timer" onCancel={() => {
            setOpenChangeFanTimerPerWeek(false);
        }} okButtonProps={{form: updateFanTimerOnlyPerWeekFormId, htmlType: 'submit', type: 'primary', loading: mutateFanTimerPerWeek.isLoading, disabled: mutateFanTimerPerWeek.isLoading}}>

            <UpdateFanTimerPerWeekForm binDTO={props.binDTO} onFinish={async (values) => {
                const seconds = round(values.fanTimerHours * 3600);
                mutateFanTimerPerWeek.mutate({secondsPerWeek: seconds}, {onSuccess: (result) => {
                    if (result.success === true) {
                        message.success("Updated fan timer");
                        setOpenChangeFanTimerPerWeek(false);
                    }
                    else {
                        message.error("Error updating fan timer");
                    }
                }});
            }} />
        </Modal>
    </>
}

export const WeatherMonitor = (props: WeatherMonitorProps) => {

    console.log("newSettings for fan", props.newFanSettings);

    const [form] = Form.useForm<SettingsFormValues>(props.form);

    // const previousReportedFanSettings = usePrevious(props.newFanSettings, (prev, current) => {
    //     if (prev?.ts != null && current?.ts != null && prev?.ts >= current?.ts) {
    //         console.log("do not update", prev, current);
    //         false;
    //     }

    //     if (submissionDate != null && props.newFanSettings?.ts != null && !dayjs(props.newFanSettings?.ts).isAfter(dayjs(submissionDate))) {
    //         console.log("not update due to new settings <= submission date", submissionDate, prev, current);
    //         return false;
    //     }
    //     console.log("update the previous settings", prev, current);
    //     return true;
    // });

    if (props.loading) {
        return null;
    }

    console.log("weather monitor props", props);
    const shouldHideNonManualAutomationSettinsg = [OperatingMode.Idle, OperatingMode.EmptyBin].includes(props.binDTO?.operatingMode!);

    return <div  >
        <Prompt
        when={form.isFieldsTouched(false)}
        message="Fan settings are not saved. Leave anyway?"
        />

        <Row gutter={[16, 16]}>
            {props.operatingMode === OperatingMode.Manual && <Col xs={24} lg={14}>
                <WeatherMonitorForm deviceId={props.deviceId} onCancel={props.onCancel} greyControl={false} form={form} onSubmit={props.onSubmit} binDTO={props.binDTO} newFanSettings={props.newFanSettings} />
                     </Col>}

            {props.operatingMode === OperatingMode.Idle && <Col xs={24} lg={14}>
                <WeatherMonitorForm deviceId={props.deviceId} onCancel={props.onCancel} greyControl={true} form={form} onSubmit={props.onSubmit} binDTO={props.binDTO} newFanSettings={props.newFanSettings} />
                     </Col>}

            {props.binDTO?.operatingMode !== OperatingMode.Manual && !shouldHideNonManualAutomationSettinsg && <Col>
                <NonManualSettings deviceId={props.deviceId} binDTO={props.binDTO} />
            </Col>}
        <Col xs={24} md={10} >
            <CurrentReadings bin={props.binDTO} fans={props.binDTO.fans} ambientAirCombined={props.binDTO.ambientAir} plenumAirCombined={props.binDTO?.plenumAir} fanRunTimeSeconds={props.binDTO.grain?.fanRunTimeSeconds} />
        </Col>
        </Row>
    </div>;
}
