// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';

interface DesiredRingHardwareDTO {
    ring: number;
    stacks: number;
    valves: number;
    layers: number | null;
    thermocouples: number;
    temperatureCables: number;
    moistureCables: number;
    rhtsPerCable: number | null;
    opiRhtsPerCable: number;
    opiMoistureCables: number | null;
    wirelessMoistureCables: number;
}
const DesiredRingHardwareDTO: InterfaceConstructor<DesiredRingHardwareDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            ring: 0,
            stacks: 0,
            valves: 0,
            layers: null,
            thermocouples: 0,
            temperatureCables: 0,
            moistureCables: 0,
            rhtsPerCable: null,
            opiRhtsPerCable: 0,
            opiMoistureCables: null,
            wirelessMoistureCables: 0,
        },
        initValues);
    }
};

export default DesiredRingHardwareDTO;
