import React, { useState } from 'react';
import { Card, Descriptions, Typography, Space, Tooltip, Button, Modal, Row, Col, Form, Select, message } from 'antd';
import {FormItem} from 'react-hook-form-antd';
import { EditOutlined } from '@ant-design/icons';
import { formatNumber } from './HeaterControls';
import { hasRangeFinder } from 'src/pages/features/shared';
import BinDTO from 'src/models/BinDTO';
import dayjs, { Dayjs } from 'dayjs';
import {useForm as useFormRHF} from 'react-hook-form';
import GrainType from 'src/consts/GrainType';
import BinApiService  from 'src/api/BinApiService';
import BinInfoDTO from 'src/models/BinInfoDTO';
import { layout2 } from './BinStatsPage';
import { bind } from 'lodash';
import ThermocoupleDTO from 'src/models/ThermocoupleDTO';
import { getDefaultTemperatureSensorType } from 'src/pages/shared/binDTOUtils';
import TemperatureSensorEnum from 'src/consts/TemperatureSensorEnum';

export interface BinStatusCardProps{
    bin: BinDTO | undefined,
    binInfo: BinInfoDTO | undefined,
    inBinVisual: Boolean
}

export interface ChangeGrainTypeFormValues {
    grainType: GrainType;
}

export const BinStatusCard = (props: BinStatusCardProps) => {
    const [showChangeGrainType, setShowChangeGrainType] = useState(false);

    const calculateEstimatedBu = (binDTO: BinDTO) : number | null => {

        const percentFull = binDTO?.grain?.percentFullCalculated;
        const calculatedBinCapacity = binDTO?.capacityBushels;

        if (binDTO.grain == null) {
            return null;
        }
        if (percentFull == null) {
            return null;
        }
        if (calculatedBinCapacity == null) {
            return null;
        }

        return calculatedBinCapacity * (percentFull / 100.0);
    }

    const changeGrainTypeDefaultValues = {grainType: props.bin?.grain?.grainType ?? GrainType.Corn};

    const changeGrainTypeFormMethods = useFormRHF<ChangeGrainTypeFormValues>({
        defaultValues: changeGrainTypeDefaultValues,
        values: changeGrainTypeDefaultValues,
        resetOptions: {
            keepDirtyValues: true,
        },
    });

    const calculateBinCapacity = (binDTO: BinDTO) : number | null => {
        const calculatedBinCapacity = binDTO?.capacityBushels;
        if (calculatedBinCapacity == null) {
            return null;
        }

        return calculatedBinCapacity;

    }

    const modeWaitMessage = (meessageContent?: string) => {
        let toShow = "Applying mode change, site will update automatically in about 15 seconds";
        if (meessageContent != null) {
            toShow = meessageContent;
        }
        message.info(toShow);
    }

    const changeGrainTypeHandleSubmit = async (vals: ChangeGrainTypeFormValues) => {
        const grainType = vals.grainType;

        // setFillValues({ grainType: grainType });
        try {
            const result = await BinApiService.setGrainType(props.bin?.name!, {grainType: grainType});
            if (result.success) {
                modeWaitMessage();
                toggleChangeGrainTypeModal(false);
                //changeGrainTypeFormMethods.resetFields();
            } else {
                message.error("System did not change grain type", 3);
            }
        } catch (error) {
            console.log("error changing grain type", error);
            message.error('Problem encountered changing Grain Type', 3);
        } finally {
            // not called since the module should update
            //fetchBin(props.binInfo?.deviceId!);
        }
    }

    const formatEstimatedBuWithComma = (binDTO: BinDTO | undefined) : string | null => {
        if (binDTO == null) {
            return "---";
        }
        const calculatedBu = calculateEstimatedBu(binDTO);
        if (calculatedBu == null) {
            return "--";
        }else{
            const formattedNumber = Math.round(calculatedBu).toLocaleString(); // Format the number with commas
            return `${formattedNumber} bu.`;
        }
    }

    const formatEnteredBuWithComma = (binDTO: BinDTO | undefined) : string | null => {
        if (binDTO == null) {
            return "---";
        }
        const EnteredBu = binDTO.grain?.totalBushelsEntered;
        if (EnteredBu == null) {
            return "--";
        }else{
            const formattedNumber = Math.round(EnteredBu).toLocaleString(); // Format the number with commas
            return `${formattedNumber} bu.`;
        }
    }

    const formatBinCapacityBuWithComma = (binDTO: BinDTO | undefined) : string | null => {
        if (binDTO == null) {
            return "---";
        }
        const binCap = calculateBinCapacity(binDTO);
        if (binCap == null) {
            return "--";
        }else{
            const formattedNumber = Math.round(binCap).toLocaleString(); // Format the number with commas
            return `${formattedNumber} bu.`;
        }
    }

    // Method to toggle the change grain type modal
    const toggleChangeGrainTypeModal = (visible: boolean) => {
        // Implement your modal toggle logic here
        setShowChangeGrainType(visible);
    };

    const getAvgBinTemp = (binDTO: BinDTO) => {

        const preferredTemperatureSensorType = binDTO?.temperatureSensorsType ?? getDefaultTemperatureSensorType(binDTO);

        const useMoistureCables = [TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast].includes(preferredTemperatureSensorType);
        const useThermocouples = [TemperatureSensorEnum.Thermocouple].includes(preferredTemperatureSensorType);

        if (useThermocouples && !binDTO?.temperatureCables?.length) return null;
        if (useMoistureCables && !(binDTO?.opiMoistureCables?.length || binDTO?.moistureCables_RF?.length)) return null;

        let avgBinTemp = 0;
        let numSensors = 0;

        binDTO.layerGrainStates?.forEach( (layer) => {
            if(useThermocouples && layer.thermocoupleTemperatureF != null){
                avgBinTemp += layer.thermocoupleTemperatureF;
                numSensors++;
            }
            else if (useMoistureCables && layer.temperatureF != null) {
                avgBinTemp += layer.temperatureF;
                numSensors++;
            }
        });

       return avgBinTemp / numSensors;
    }

    const getAvgBinMC = (binDTO: BinDTO) => {
        if (!binDTO?.opiMoistureCables?.length) return null;


        let avgBinMc = 0;
        let numSensors = 0;
    
        binDTO.layerGrainStates?.forEach( (layer) => {
            if(layer.moistureContent != null){
                avgBinMc += layer.moistureContent;
                numSensors++;
            }
        });

       return avgBinMc / numSensors;
    }

    return (
        <>
            <Card title={props.inBinVisual ? "": "Bin Status"} size="small" bodyStyle={{ padding: 0 }}>
                <Descriptions
                    bordered={true}
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size="small"
                >

                    <Descriptions.Item label="Grain Type">
                        <Space direction="horizontal" size="small">
                            <Typography.Text>{props.bin?.grain?.grainType || '--'}</Typography.Text>
                            <Tooltip title="Change Grain type">
                                <Button
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        toggleChangeGrainTypeModal(true);
                                    }}
                                ></Button>
                            </Tooltip>
                        </Space>
                    </Descriptions.Item>
                    {
                        (props.bin?.innerCO2 != null || props.bin?.outerCO2 != null) &&
                        <Descriptions.Item label="CO2 (ppm)">
                            {formatNumber(props.bin?.cO2Level, { decimalPlaces: 0 })}
                        </Descriptions.Item>
                    }
                    <Descriptions.Item label="Bin Average Temperature">
                        {formatNumber(getAvgBinTemp(props.bin!), {decimalPlaces:1, suffix:"°F"})}
                    </Descriptions.Item>
                    <Descriptions.Item label="Bin Average MC">
                        {formatNumber(getAvgBinMC(props.bin!), {decimalPlaces:1, suffix:"%"})}
                    </Descriptions.Item>

                    
                    {hasRangeFinder(props.bin) && (
                        <>
                            <Descriptions.Item label="Fill Level (headspace sensor)">
                                {formatNumber(props.bin?.grain?.percentFullCalculated, { decimalPlaces: 0, suffix: '%' })} (
                                {formatEstimatedBuWithComma(props.bin)})
                            </Descriptions.Item>
                            {ENVIRONMENT_NAME != "DEMO" && <Descriptions.Item label="Fill Level (user entered)">
                                {formatNumber(props.bin?.grain?.percentFullEntered, { decimalPlaces: 0, suffix: '%' })} (
                                {formatEnteredBuWithComma(props.bin)}
                                {/* {formatNumber(props.bin?.grain?.totalBushelsEntered! / 1000.0, {
                                    decimalPlaces: 2,
                                    suffix: 'k bu.',
                                })} */}
                                )
                            </Descriptions.Item>}
                        </>
                    )}
                    {/* <Descriptions.Item label="Capacity bu.">{formatBinCapacityBuWithComma(props.bin)}</Descriptions.Item> */}

{ENVIRONMENT_NAME != "DEMO" && (<React.Fragment key="weighted MC section">
                    <Descriptions.Item label="Weighted Average MC Loads">
                        {formatNumber(props.bin?.grain?.weightedAverageMcIn, { decimalPlaces: 1, suffix: '%' })}
                    </Descriptions.Item>
                    <Descriptions.Item label="Weighted Average MC Unloads">
                        {formatNumber(props.bin?.grain?.weightedAverageMcOut, { decimalPlaces: 1, suffix: '%' })}
                    </Descriptions.Item>
</React.Fragment>
)}

                    <Descriptions.Item
                        style={{ cursor: 'default' }}
                        label={(
                            <span>
                                Fan Runtime{' '}
                                <span>
                                    <Tooltip
                                        title="Time based on the duration the fan was on (not-paused) during the current batch"
                                        style={{ cursor: 'default', fontWeight: 'bold' }}
                                    >
                                        (i)
                                    </Tooltip>
                                </span>
                            </span>
                        )}
                    >
                        {dayjs
                            .duration((props?.bin?.fanOperations?.fanRunTimeSeconds ?? 0) * 1000)
                            .format('D [days] H [hours] & M [minutes]')}
                    </Descriptions.Item>

                </Descriptions>
            </Card>
            <Modal
                key={`changeGrainType`}
                centered={true}
                open={showChangeGrainType}
                onCancel={() => toggleChangeGrainTypeModal(false)}
                destroyOnClose={true}
                title="Change Grain Type"
                bodyStyle={{ padding: 0 }}
                footer={[
                    <Button key="back" onClick={() => toggleChangeGrainTypeModal(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" htmlType='submit' form='changeGrainType'>
                        Change Grain Type
                    </Button>,]}
                    >
                <Row justify="center">
                <Col span={24}>
                    <Form
                        id="changeGrainType"
                        onFinish={changeGrainTypeFormMethods.handleSubmit(changeGrainTypeHandleSubmit)}
                    >
                        <FormItem {...layout2} label="Grain Type" name="grainType" control={changeGrainTypeFormMethods.control}>
                            <Select defaultValue={GrainType.Rye}>
                                <Select.Option value="Corn">Corn</Select.Option>
                                <Select.Option value="Rye">Rye</Select.Option>
                                <Select.Option value="Soybeans">Soybeans</Select.Option>
                                <Select.Option value="Wheat">Wheat</Select.Option>
                            </Select>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        </Modal>
    </>  
    );
};