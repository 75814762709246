import { Button, message, Row, Skeleton } from "antd";
import { StatusCodes } from "http-status-codes/build/cjs/status-codes";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetBinByIDQuery } from "src/queries/useGetBinByIDQuery";
import { deviceQueryKeys } from "../HomeScreen/BinDetails";
import BinStatsPage, { HistoryState } from "./BinStatsPage";
import { queryClient } from "src";
import { connect, useStore } from "react-redux";
import { UserSessionActions } from "src/redux/actions/UserSessionActions";

interface Props {
    growerChangeAction: (growerID: number) => void;
}

export const invalidateBinState = (deviceId: string): NodeJS.Timeout => {
    const timeoutId = setTimeout(async () => {
        console.log("invaliding binState at", Date());
        await queryClient.invalidateQueries(deviceQueryKeys.stateFromDevice(deviceId));
        message.info('Data Refreshing...', 2);
    }, 8000);
    return timeoutId;
};

const BinStatsPageParent = (props: Props) => {
    const params = useParams<any>();
    const history = useHistory<HistoryState>();
    const binIdParam = params.binId;

    const binId = parseInt(binIdParam, 10);
    // Nan, Ids < 0, non-numeric, all evaluate to false
    const validbinIdInUrl = binId >= 0;
    if (!validbinIdInUrl) {
        return <>
            <Row justify="center" >
                <h1><b>{binIdParam}</b> is not a valid bin identifer</h1>
            </Row>
            <Row justify="center">
                <Button onClick={() => {
                    history.push("/");
                }}>Go Home</Button>
            </Row>
        </>

    }

    const binInfoQuery = useGetBinByIDQuery(binId);

    useEffect(() => {
        const growerId = binInfoQuery.data?.growerId;
        if (!(growerId)) {
            return;
        }
        props.growerChangeAction(growerId);
    }, [binInfoQuery.data?.growerId]);

    const unauthorizedBin = binInfoQuery.error?.status === StatusCodes.FORBIDDEN;
    const binNotFound = binInfoQuery.error?.status === StatusCodes.NOT_FOUND;
    const notFoundOrUnauthorized = unauthorizedBin || binNotFound;

    if (notFoundOrUnauthorized) {
        console.log("Redirecting to homepage due to unauthorized or notfound bin");
        return <Redirect to="/" />;
    }

    if (binInfoQuery.data) {
        return <>
            <BinStatsPage binInfo={binInfoQuery.data} />
        </>;
    }

    if (binInfoQuery.isLoading) {
        return <>
            <Skeleton />
        </>
    }

    if (binInfoQuery.isError) {
        return <>
            <h2>An unexpected server error occurred loading the bin</h2>
            <Link to="/">Back to Dashboard</Link>
            <h2>Contact Haber support if this perists.</h2>
        </>;
    }

    return <></>

}


function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (growerID: number) => dispatch(UserSessionActions.changeGrower(growerID)),
    };
}

export default connect(null, mapDispatchToProps)(BinStatsPageParent);
