import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import SideNav from 'src/sideNav/SideNav';
import UnexpectedError from 'src/pages/UnexpectedError';
import { GlobalBreadcrumb } from 'src/pages/shared/GlobalBreadcrumb';
import { Layout, Button, Row, Col, Select, notification, Menu, Dropdown, Typography, Modal } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, SettingOutlined, LoginOutlined, LogoutOutlined, BarsOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RouteComponentProps, Link, NavLink, useHistory, useParams } from 'react-router-dom';
import UserSecurity from 'src/models/UserSecurity';
import CurrentUser from 'src/utils/CurrentUser';
import Routes from 'src/consts/Routes';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import { connect } from 'react-redux';
import HistoryUtil from 'src/utils/HistoryUtil';
import UserAlertSettings, { UserAlertSettingsModal } from 'src/pages/users/UserAlertSettings';
import { AlertsHistoryTable } from 'src/pages/dashboard/BinStatusPage/AlertsHistoryTable';
// @ts-ignore
//import logo from 'src/statics/haber_logo_borderless.png';
import logo from "STIG - App - S only.png";
// @ts-ignore
import logoWithText from "src/statics/Shivvers  STIG - Liberator font (1).png";
import { useEffect, useState } from 'react';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { HistoryState } from 'src/pages/dashboard/BinStatusPage/BinStatsPage';
import { useGetBinByIDQuery } from 'src/queries/useGetBinByIDQuery';
const { Header, Sider, Content } = Layout;
const { Text } = Typography;

interface FullScreenLayoutProps {
    portalAccess: boolean;
    growerChangeAction: (growerID: number) => void;
    logoutAction: () => void;
    userId: number;
    // hack
    growerID: number | null
}
const changeAlertSettingsFormId = "alert-settings-form-id";
interface FullScreenLayoutState {
    collapsed: boolean;
    user: UserSecurity | null;
    currentGrower: [number, string] | undefined;
    growerIdList: [number, string][];
    isAlertSettingsModalOpen: boolean;
    isAlertHistoryModalOpen: boolean;
}

const FullscreenLayout = (props: React.PropsWithChildren<FullScreenLayoutProps>) => {
    const [collapsed, setCollapsed] = useState(false);
    const [user, setUser] = useState(CurrentUser.Get());
    const currentGrowerId = props.growerID;
    const [growerIdList, setGrowerIdList] = useState<Array<[number, string]>>([]);
    const [isAlertSettingsModalOpen, setIsAlertSettingsModalOpen] = useState(false);
    const [isAlertHistoryModalOpen, setIsAlertHistoryModalOpen] = useState(false);

    const currentGrower = growerIdList?.find(grower => {
        grower[0] === props.growerID
    });
    const currentGrowerName = currentGrower?.[1];


    const params = useParams<any>();
    const binIdParam = params.binId;

    const binId = parseInt(binIdParam, 10);
    // Nan, Ids < 0, non-numeric, all evaluate to false
    const validbinIdInUrl = binId >= 0;

    const binInfoQuery = useGetBinByIDQuery(binId, {enabled: validbinIdInUrl, refetchOnReconnect: false, refetchOnWindowFocus: false});

    const openAlertSettingsModal = () => {
        setIsAlertSettingsModalOpen(true);
    }

    const closeAlertSettingsModal = () => {
        setIsAlertSettingsModalOpen(false);
    }

    const openAlertHistoryModal = () => {
        setIsAlertHistoryModalOpen(true);
    }

    const closeAlertHistoryModal = () => {
        setIsAlertHistoryModalOpen(false);
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={{ pathname: Routes.generate(Routes.PROFILE) }}>
                    <BarsOutlined /> Profile
                </Link>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" href="https://drive.google.com/drive/folders/1r4qdLzNIE4-o2IAowSckN8vh67N59Pt2?usp=drive_link">
                    <InfoCircleOutlined /> User Manuals
                </a>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => { props.logoutAction(); }}>
                    <LogoutOutlined /> Logout
                </div>
            </Menu.Item>
        </Menu>
    );

    const alertMenu = (
        <Menu>
            <Menu.Item>
                <div onClick={openAlertSettingsModal}>
                    <SettingOutlined /> Alert Settings
                </div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={openAlertHistoryModal}>
                    <InfoCircleOutlined /> Alert History
                </div>
            </Menu.Item>
        </Menu>
    );

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    useEffect(() => {
        if (validbinIdInUrl && binInfoQuery.isInitialLoading) {
            return;
        }
        getData();
    }, [user, binInfoQuery.isInitialLoading, validbinIdInUrl]);

    const getData = () => {
        if (user?.userId) {
            if (RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN])) {
                EnterpriseApiService.getGrowerIDs().then((growerIDs) => {
                    let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
                    const growerIdAlreadySet = !!props.growerID;
                    if (!growerIdAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0][0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            } else {
                EnterpriseApiService.getGrowerIDsOfUser(user.userId).then((growerIDs) => {
                    let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
                    const growerIdAlreadySet = !!props.growerID;
                    if (!growerIdAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0][0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            }
        }
    }

    const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);

    return (
        <Layout className="layout-fullscreen">
            {isAdmin &&
                <Sider
                    trigger={null}
                    collapsible={true}
                    collapsed={collapsed}
                    theme="light"
                    width={195}
                    collapsedWidth={70}
                    breakpoint="lg"
                    onBreakpoint={(broken) => { setCollapsed(broken); }}
                >
                    <SideNav collapsed={collapsed} />
                </Sider>
            }
            <Layout>
                <Header className={isAdmin ? 'app-header' : 'nonadmin'}>
                    <Row justify="space-between">
                        {isAdmin ?
                            <Col>{collapsed ?
                                <MenuUnfoldOutlined className="trigger" onClick={toggle} /> :
                                <MenuFoldOutlined className="trigger" onClick={toggle} />}
                            </Col>
                            :
                            <Col>
                                <div className="logo-container">
                                    <NavLink to={Routes.generate(Routes.HOME_ROUTE)}>
                                        <img src={logoWithText} className="App-logo" alt="logo" />
                                    </NavLink>
                                </div>
                            </Col>
                        }
                        <Col>
                            <Row>
                                {growerIdList.length > 0 &&
                                    <Col>
                                        {growerIdList.length > 1 ?
                                            <Select showSearch optionFilterProp='children' key="select" style={{ width: 300, marginRight: '16px' }} labelInValue={true}
                                                value={currentGrowerId ?
                                                    { value: currentGrowerId, label: currentGrowerName } : null}
                                                onChange={(val) => {
                                                    props.growerChangeAction(val.value);
                                                    HistoryUtil.push(Routes.HOME_ROUTE);
                                                }}>
                                                {growerIdList.map((grower) => {
                                                    return <Select.Option key={grower[0]} value={grower[0]}>{grower[1]}</Select.Option>;
                                                })}
                                            </Select>
                                            :
                                            <span style={{
                                                borderRadius: '3px', marginRight: '16px', background: 'rgba(150, 150, 150, 0.1)',
                                                border: '1px solid rgba(100, 100, 100, 0.2)', padding: '7px'
                                            }}>
                                                <Text>{growerIdList[0][1]}</Text>
                                            </span>
                                        }
                                    </Col>
                                }
                                <Col>
                                    <Dropdown overlay={alertMenu}>
                                        <Button type="primary" icon={<UserOutlined />} style={{ marginRight: '16px' }}> Alerts </Button>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    {user ?
                                        <Dropdown overlay={menu} placement="bottom">
                                            <Button type="primary" icon={<UserOutlined />} style={{ marginRight: '16px' }}>
                                                {user.userName}
                                            </Button>
                                        </Dropdown>
                                        :
                                        <Link to={Routes.generate(Routes.LOGIN)}>
                                            <Button type="primary" icon={<LoginOutlined />} style={{ marginRight: '16px' }}>
                                                Login
                                            </Button>
                                        </Link>
                                    }
                                </Col>
                                <Col>
                                    {user && isAdmin &&
                                        <Link to={{
                                            pathname: Routes.generate(Routes.SETTINGS),
                                            state: { passedGrower: currentGrowerId }
                                        }}>
                                            <Button type="primary" icon={<SettingOutlined />} style={{ marginRight: '16px' }} />
                                        </Link>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <GlobalBreadcrumb
                        separator={<b> / </b>}
                        finalItem={'b'}
                        finalProps={{
                            style: { color: 'red' }
                        }}
                    />
                </Header>
                <Content id="app-main" className={isAdmin ? 'page-content' : 'page-content-nonadmin'}>
                    <Scrollbars autoHide={true} className="page-scrollbar">
                        <UnexpectedError>
                            {props.children}
                        </UnexpectedError>
                    </Scrollbars>
                </Content>
            </Layout>

            <UserAlertSettingsModal userId={props.userId} open={isAlertSettingsModalOpen} requestToClose={closeAlertSettingsModal} />

            <Modal
                centered={true}
                footer={null}
                closable={true}
                bodyStyle={{ padding: 16 }}
                width={'80%'}
                open={isAlertHistoryModalOpen}
                onCancel={closeAlertHistoryModal}
                destroyOnClose={true}
            >
                <AlertsHistoryTable growerId={currentGrowerId?.[0]} />
            </Modal>
        </Layout>
    );
}

function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (growerID: number) => dispatch(UserSessionActions.changeGrower(growerID)),
        logoutAction: () => dispatch(UserSessionActions.logout())
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        growerID: state.UserSession.GrowerID
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenLayout);
