import * as React from 'react';
import { Switch, Input, InputNumber, Form, Layout, Button, notification, Dropdown, Menu, Select, Popconfirm, Row, Col, Typography, FormInstance } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import BinInfoDTO from 'src/models/BinInfoDTO';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import AdminApiService from 'src/api/AdminApiService';
import ECDeviceDTO from 'src/models/ECDeviceDTO';

interface State {
    binIdList: [number, string, number][];
    filteredBinIdList: [number, string, number][];
    chosenBinId: number | null;
    chosenBinName: string;
    currentBin: BinInfoDTO | null;
    growerIdList: [number, string][];
    deviceList: string[];
    ecDeviceList: ECDeviceDTO[];
    growerFilter: [number, string] | null;
    showStackForm: boolean;

}

// const { Option } = Select;
const { Text } = Typography;

class CreateUpdateBin extends React.Component<{ startBin?: BinInfoDTO, startGrowerFilter?: [number, string] }, State> {

    private readonly _formRef = React.createRef<FormInstance>();
    private readonly abortController = new AbortController();
    constructor(props: { startBin?: BinInfoDTO, startGrowerFilter?: [number, string] }) {
        super(props);

        this.state = {
            binIdList: [],
            ecDeviceList: [],
            filteredBinIdList: [],
            chosenBinId: this.props.startBin?.id ? this.props.startBin?.id : null,
            chosenBinName: this.props.startBin?.name ? this.props.startBin?.name : '',
            currentBin: this.props.startBin ? this.props.startBin : null,
            growerIdList: [],
            deviceList: [],
            growerFilter: this.props.startGrowerFilter ? this.props.startGrowerFilter : null,
            showStackForm: false
        };
    }

    componentDidMount() {
        this.getData();
    }
    componentWillUnmount() {
        this.abortController?.abort();
    }

    render() {
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 4},
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 20 },
        };
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);

        return (
            <Layout.Content className="CreateUpdateBin">

                <Row justify="space-between">
                    <Col>
                        {isAdmin &&
                            <Row gutter={16}>
                                <Col style={{ width: 65, lineHeight: '32px' }}>
                                    <Text>Grower:</Text>
                                </Col>
                                <Col>
                                    <Select
                                    style={{ width: "200px", maxHeight: "300px", overflow: "auto" }}
                                    placeholder="Select Grower"
                                    showSearch
                                    optionFilterProp="children" 
                                    onSelect={ (val) => {
                                        this.handleGrowerIdClicked(val)
                                    }}
                                    >   
                                        <Select.Option value={null}>All growers</Select.Option>
                                        {this.state?.growerIdList.map((pair) => (
                                            <Select.Option key={pair[0]} value={pair[0]}>
                                                {pair[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        }
                        <Row gutter={16}>
                            <Col style={{ width: 65, lineHeight: '32px' }}>
                                <Text>Bin:</Text>
                            </Col>
                            <Col span={8}>
                                <Select 
                                onSelect={(val) => this.handleBinIdClicked(val) } 
                                placeholder="Select bin" 
                                style={{ width:"200px", marginBottom: '16px', marginRight: '8px'}} 
                                optionFilterProp="children" 
                                showSearch
                                >
                                    <Select.Option value={null}>
                                        Create New Bin
                                    </Select.Option>
                                    {
                                        this.state.growerFilter ? 
                                        this.state.filteredBinIdList.map((pair, index) => (
                                            <Select.Option key={pair[0]} value={pair[0]}>
                                                {pair[1]}
                                            </Select.Option>
                                        ))
                                        :
                                        this.state.binIdList.map((pair, index) => (
                                            <Select.Option key={pair[0]} value={pair[0]}>
                                                {pair[1]}
                                            </Select.Option>
                                        ))   
                                    }
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row justify="end">
                            
                            {this.state.chosenBinId &&
                                <Popconfirm
                                    title="Are you sure want to delete this bin?"
                                    onConfirm={this.deleteBin}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{ marginBottom: '16px' }} type="primary" danger={true}>Delete Bin</Button>
                                </Popconfirm>
                            }
                        </Row>
                        <Row>
                            {this.state.chosenBinId && isAdmin &&
                                <Button style={{ marginBottom: '16px' }}
                                    onClick={
                                        () => { this.setState({ showStackForm: !this.state.showStackForm }); }}>
                                    {this.state.showStackForm ? 'Hide EMC Configuration Form' : 'Show EMC Configuration Form'}
                                </Button>
                            }
                        </Row>
                    </Col>
                </Row>
                {
                    this.state.showStackForm ?
                        this.state.chosenBinId
                        :
                        this.state.currentBin &&
                        <Form onFinish={this.onFinish} initialValues={this.state.currentBin} ref={this._formRef}>
                            {!isAdmin &&
                                <div style={{ marginBottom: '16px' }}>
                                    <span style={{ fontStyle: 'italic', fontSize: '0.9em', color: '#666' }}>
                                        * Warning: changing bin data will affect other functions
                                    </span>
                                </div>
                            }
                            <Form.Item name={"isActive"} {...layout} label="Is Active">
                                <Switch />
                            </Form.Item>
                            <Form.Item {...layout} label="Bin Name" name="name" rules={[{ required: true, message: 'Enter the name of the Bin' }]}>
                                <Input />
                            </Form.Item>
                            {isAdmin &&
                                <Form.Item {...layout} label="Grower" name="growerId" rules={[{ required: true, message: 'Choose the Grower' }]}>
                                    <Select style={{ maxWidth: '200px', overflow: "auto", maxHeight: "300px"} } optionFilterProp="children" showSearch >
                                        {
                                            this.state.growerIdList.map((pair, index) => (
                                                <Select.Option key={pair[0]} value={pair[0]}>
                                                    {pair[1]}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            }
                            <Form.Item {...layout} label="Bin Height" name="height"
                                rules={[{ required: true, message: 'Enter the height of the Bin' }]}>
                                <InputNumber />
                            </Form.Item>
                            <Form.Item  {...layout} label="Latitude of The Bin" name="binLat"
                                rules={[{ required: true, message: 'Enter the latitude of the Bin' }]}>
                                <InputNumber max={90} min={-90} />
                            </Form.Item>
                            <Form.Item {...layout} label="Longitude of The Bin" name="binLng"
                                rules={[{ required: true, message: 'Enter the Longitude of the Bin' }]}>
                                <InputNumber max={180} min={-180} />
                            </Form.Item>
                            <Form.Item {...layout} label="Diameter of The Bin" name="diameter"
                                rules={[{ required: true, message: 'Enter the Diameter of the Bin' }]}>
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item {...layout} label="Manufacturer of the Bin" name="manufacturer"
                                rules={[{ required: true, message: 'Enter the Bin Manufacturer' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item {...layout} label="Fan Model" name="fanModel"
                                rules={[{ required: true, message: 'Enter the Model of Fan Used' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item {...layout} label="Target Moisture" name="targetMoisture"
                                rules={[{ required: true, message: 'Enter the Target Moisture' }]}>
                                <InputNumber step={0.5} formatter={val => `${val}%`} />
                            </Form.Item>
                            <Form.Item {...layout} label="Total Estimated Bu Capacity" name="totalCapacityBu"
                                rules={[{ required: true, message: 'Enter the Total Bu Capacity of The Bin' }]}>
                                <InputNumber
                                    step={.5}
                                    formatter={val => `${val}k bu`}
                                    parser={value => value!.replace(/[ !@#$%^&*()a-zA-Z]/g, '')} />
                            </Form.Item>
                            {isAdmin &&
                                <Form.Item {...layout} label="Device" name="deviceId">
                                    <Select style={{ maxWidth: '350px', overflow: "auto", maxHeight: "600px"} } optionFilterProp="children" showSearch >
                                        <Select.Option value={''}>
                                            No Device
                                        </Select.Option>
                                        {
                                            this.state.deviceList.map((id, index) => (
                                                <Select.Option key={id} value={id}>
                                                    {id}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            }
                            {isAdmin && <Form.Item {...layout} label="EC Device" name='ecDeviceID'>
                                <Select style={{ maxWidth: '400px', overflow: "auto", maxHeight: "600px"} } optionFilterProp="children" showSearch >
                                    <Select.Option value={''}>
                                        No Device
                                    </Select.Option>
                                    {
                                        this.state.ecDeviceList.map((ecDevice, index) => (
                                            <Select.Option key={ecDevice.id ?? ''} value={ecDevice.id ?? ''}>
                                                {ecDevice.displayName} - {ecDevice.id}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        }
                            <Form.Item {...layout} label="Bin Description" name="description"
                                rules={[{ required: false, message: 'Enter a Description of the Bin' }]}>
                                <TextArea />
                            </Form.Item>
                            <Form.Item {...layout} label="Additional Notes" name="notes" rules={[{ required: false, message: 'Enter a Description of the Bin' }]}>
                                <TextArea placeholder="Add any additonal notes about the bin here" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                            </Button>
                            </Form.Item>
                        </Form>
                }
            </Layout.Content>
        );
    }

    // private getNextpage = async (offset: number): Promise<ECDeviceListResponseDTO> => {
    //     const result = await AdminApiService.getAllECDevices(offset, this.abortController?.signal);
    //     return result;
    // }

    private getAllECDevices = async () => {
        let offset = 0;
        const devices: ECDeviceDTO[] = [];
        let hasMoreResults = true;
        while (hasMoreResults) {
            const result = await AdminApiService.getAllECDevices(offset, this.abortController?.signal);
            // console.log(`reading devices ${offset}..${offset+result.size-1}`);
            if (result.size == 0 || result.size == null) {
                // no more results
                console.log("no more ec device results (got 0 or null)");
                return devices;
            }
            offset += result.size;
            hasMoreResults = result.limitExceeded;
            if (result.items == null) {
                // no more results
                console.log("no more results. items was null");
                return devices;
            }
            for (let device of result.items) {
                if (device == null) {
                    console.warn("skipping EC device result due to being null");
                    continue;
                }
                devices.push(device);
            }
        }
        devices.sort((a, b) => {
            const result = a.displayName?.localeCompare(b.displayName ?? '');
            if (result === undefined) {
                return 0;
            }
            return result;
        }
        );
        return devices;
    }

    private getData() {
        if (RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN])) {
            Promise.all([
                EnterpriseApiService.getBinIDs(),
                EnterpriseApiService.getGrowerIDs(),
            ]).then(([binIDs, growerIDs]) => {
                let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
                let binNameIDPairs: [number, string, number][] = binIDs.map((bin) => [bin.id, bin.name ? bin.name : 'error: no name', bin.growerId]);
                this.setState({
                    binIdList: binNameIDPairs,
                    growerIdList: GrowerNameIDPairs,
                });
                this.handleGrowerIdClicked(this.state.growerFilter?.[0] ?? 0);
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });


            Promise.all([
                this.getAllECDevices(),
            ]).then(([allECDevices]) => {
                this.setState({
                    ecDeviceList: allECDevices,
                });
            }).catch(error => {
                if (error?.name === "AbortError") {
                    return;
                }
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });

        } else {
            if (this.state.growerFilter) {
                EnterpriseApiService.getBinIDsByGrower(this.state.growerFilter[0]).then((binIDs) => {
                    let binNameIDPairs: [number, string, number][] = binIDs.map((bin) =>
                        [bin.id, bin.name ? bin.name : 'error: no name', bin.growerId]);
                    this.setState({
                        binIdList: binNameIDPairs,
                    });
                    this.handleGrowerIdClicked(this.state.growerFilter?.[0] ?? 0);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            }
        }

    }


    private handleBinIdClicked = async(id:number) => {
        if(id){
            let binIdNamePair : [number, string, number] = [ 0, "" , 0];
            this.state.binIdList.forEach( (e) => {
                if(e[0] === id){
                    binIdNamePair = e
                }
            })
            
            EnterpriseApiService.getBinByID(binIdNamePair[0])
            .then((bin) => {
                // device id is empty string instead of null to have the "no device" field in select
                bin.deviceId = bin.deviceId ? bin.deviceId : '';
                this.setState({
                    chosenBinId: binIdNamePair[0],
                    chosenBinName: binIdNamePair[1],
                    currentBin: bin,
                    showStackForm: false
                });
                this._formRef.current?.resetFields();
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }else{
            await this.setState({
                chosenBinId: null,
                chosenBinName: 'New Bin',
                currentBin: BinInfoDTO.create({
                    growerId: this.state.growerFilter ? this.state.growerFilter[0] : this.state.growerIdList[0][0],
                    deviceId: ''
                }),
                showStackForm: false
            });
            this._formRef.current?.resetFields();
        }
        if (RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN])) {
            EnterpriseApiService.getUnusedDevicesForBin().then(deviceIDs => {
                let updateDeviceList = deviceIDs;
                if (this.state.currentBin?.deviceId) {
                    updateDeviceList.push(this.state.currentBin.deviceId);
                }
                this.setState({
                    deviceList: updateDeviceList
                });
                this._formRef.current?.resetFields();
            });
        }
    }

    private handleGrowerIdClicked = (id: number) => {
        let growerNamePair = this.state.growerIdList.find(b=>b[0] === id);
        if (!growerNamePair) {
            this.setState({
                growerFilter: null,
            });
        } else {
            let filteredBinList = this.state.binIdList.filter((binId) => {
                return binId[2] === growerNamePair?.[0] ?? 0.0;
            });
            this.setState({
                growerFilter: growerNamePair,
                filteredBinIdList: filteredBinList
            });
        }
    }

    private onFinish = (values: BinInfoDTO) => {
        console.log(values);
        let formBin = {
            id: this.state.chosenBinId ? this.state.chosenBinId : 0,
            growerId: RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? values.growerId : (this.state.growerFilter ? this.state.growerFilter[0] : null),
            deviceId: RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? (values.deviceId === '' ? null : values.deviceId) :
                this.state.currentBin?.deviceId,
            ecDeviceID: RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]) ? (values.ecDeviceID === '' ? null : values.ecDeviceID) :
                this.state.currentBin?.ecDeviceID,
            name: values.name,
            binLat: values.binLat,
            binLng: values.binLng,
            height: values.height,
            diameter: values.diameter,
            description: values.description,
            notes: values.notes,
            manufacturer: values.manufacturer,
            fanModel: values.fanModel,
            binStats: this.state.currentBin ? this.state.currentBin.binStats : null,
            levelData: this.state.currentBin ? this.state.currentBin.levelData : null,
            totalCapacityBu: values.totalCapacityBu,
            targetMoisture: values.targetMoisture,
            isActive: values.isActive
        } as BinInfoDTO;
        if (!this.state.chosenBinId) {
            EnterpriseApiService.addBin(formBin).then(() => {
                notification.success({
                    message: 'New Bin Added Succesfully'
                });
                this.getData();
                this.setState({
                    chosenBinId: null,
                    chosenBinName: '',
                    currentBin: null
                });
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        } else {
            EnterpriseApiService.updateBin(formBin).then(() => {
                notification.success({
                    message: 'Bin Updated Succesfully'
                });
                this.getData();
                this.setState({
                    chosenBinId: null,
                    chosenBinName: '',
                    currentBin: null
                });
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }
    }

    private deleteBin = () => {
        if (this.state.chosenBinId) {
            EnterpriseApiService.deleteBin(this.state.chosenBinId).then(() => {
                notification.success({
                    message: 'Bin Deleted Succesfully'
                });
                this.getData();
                this.setState({
                    chosenBinId: null,
                    chosenBinName: '',
                    currentBin: null
                });
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        } else {
            notification.error({
                message: 'No Bin Selected',
            });
        }
    }
}
export default CreateUpdateBin;
