import * as React from 'react';
import * as ReactDOM from 'react-dom';
import addGlobals from 'src/utils/Globals';
import App from './app/App';
import History from './utils/HistoryUtil';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { store } from './redux/Store';
import { setStore } from './redux/getStore';
// @ts-ignore
import { ThroughProvider } from 'react-through';
import 'script-loader!src/webGL/gl-matrix-min.js';
import 'script-loader!src/webGL/litegl.js';
import 'script-loader!src/webGL/litegraph.js';
import 'script-loader!src/webGL/litescene.js';
import 'script-loader!src/webGL/Canvas2DtoWebGL.js';
import './index.less';
import 'antd/dist/reset.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { deviceQueryKeys } from './pages/dashboard/HomeScreen/BinDetails';
import { binDBKeys } from './pages/binOverview/BinCommander';
import { QueryParamProvider } from 'use-query-params';
import {ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
// @ts-ignore
import { worker } from './mocks/browser.js';

addGlobals();
setStore(store);

export const queryClient = new QueryClient();
queryClient.setQueryDefaults(deviceQueryKeys.all, { retry: 0, refetchOnWindowFocus: false, });
queryClient.setQueryDefaults(binDBKeys.all, { retry: 0, refetchOnWindowFocus: false});
console.log("demo running");
let swUrl = '/dist/mockServieWorker.js';
console.log("do these options work, msw?");
worker.start(
    {
        serviceWorker: {
          url: '/dist/mockServiceWorker.js',
          options: {
            scope: "/",
          }
        },
      }
).then(() => {

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <ThroughProvider>
            <   Router history={History}>
                    <QueryParamProvider adapter={ReactRouter5Adapter}>
                        <App />
                    </QueryParamProvider>
                </Router>
            </ThroughProvider>
        </Provider>
    </QueryClientProvider>,
    document.getElementById('root') as HTMLElement
);
});
registerServiceWorker();
