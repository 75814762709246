import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Space, Switch, Form, message, Input, InputNumber, Typography, Skeleton } from 'antd';
import { useForm } from "antd/lib/form/Form"
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from 'src';
import UserAlertSettingsDTO from 'src/models/UserAlertSettingsDTO';
import PhoneInput from '../shared/PhoneInput';
import NumberInput from '../shared/NumberInput';
import { formatNumber } from '../dashboard/BinStatusPage/HeaterControls';

interface UserAlertSetttingsModalProps {
  userId: number,
  open: boolean,
  requestToClose: () => void;
}

export const useChangeUserAlertSettingsMutation = () => {
  return useMutation({
    mutationFn: async (params: {settings: UserAlertSettingsDTO}) => {
      return await EnterpriseApiService.changeUserAlertSettings(params.settings);
    }
  });
}

export const UserAlertSettingsModal = (props: UserAlertSetttingsModalProps) => {
  const queryClient = useQueryClient();
  const changeUserAlertSettingsMutation = useChangeUserAlertSettingsMutation();

  const onFinish = useCallback((dto: UserAlertSettingsDTO) => {
    changeUserAlertSettingsMutation.mutate({settings: dto}, {
      onSuccess: async (data, variables, context) => {
        props.requestToClose();
        message.success("user settings updated");
      },
      onError(error: any, variables, context) {
        console.error("error updating user settings", error);
        //message.error(`problem updating user settings.`);
        message.error(`${error?.message ?? ""}`);

      },
      onSettled: async (data, error, variables, context) => {
          await queryClient.invalidateQueries(["userSettings"])
      },
    })
  }, [props.requestToClose, changeUserAlertSettingsMutation, queryClient]);

return <>
    <Modal 
    title="Alert settings" 
    open={props.open} 
    onCancel={props.requestToClose} 
    destroyOnClose={true}
    width="600px"
    okButtonProps={{
        form: changeAlertSettingsFormId,
        loading: changeUserAlertSettingsMutation.isLoading,
        htmlType: 'submit',
    }}>
      <UserAlertSettings userId={props.userId} onFinish={onFinish} />
    </Modal>
  </>
};

const changeAlertSettingsFormId = "alert-settings-form-id";

interface UserAlertSettingsProps {
  userId: number,
  onFinish: (dto: UserAlertSettingsDTO) => void,
}

const hotspotThresholdPlaceholderTemperatureF = 75.0;

const UserAlertSettings = (props: UserAlertSettingsProps) => {
  const [form] = Form.useForm();
  const hotspotThresholdTemperatureF = Form.useWatch(['hotspotThresholdTemperatureF', 'value'], form);


  var userSettingsQuery = useQuery({
    queryKey: ["userSettings"], queryFn: async (q) => {
      return await EnterpriseApiService.getUserAlertSettings(q.signal);
    },
    //refetchInterval: 5000,
    retry: 0, keepPreviousData: false, refetchOnWindowFocus: false
  });

  const showModal = () => {
    // userSettingsQuery = useQuery({queryKey: ["userSettings"], queryFn: async(q) =>{
    //   return await EnterpriseApiService.getUserAlertSettings(q.signal);
    // });
  };

  const onCo2Click = (value: boolean) => {
    form.setFieldValue("EnableCO2Alert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onHotspotClick = (value: boolean) => {
    form.setFieldValue("EnableHotspotAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onFanRuntimeClick = (value: boolean) => {
    form.setFieldValue("EnableFanRuntimeAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onFanAmpsClick = (value: boolean) => {
    form.setFieldValue("EnableFanAmpsAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onEmailClick = (value: boolean) => {
    form.setFieldValue("EnableEmailAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onSMSClick = (value: boolean) => {
    form.setFieldValue("EnableSMSAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }

  const onPushClick = (value: boolean) => {
    form.setFieldValue("EnablePushAlert", value);
    // console.log(form.getFieldValue("enableCO2Alert"));
  }



  const onFinish = async (vals: any) => {

    try {
      const dto: UserAlertSettingsDTO = {
          enableCo2Alert: vals.EnableCO2Alert,
          enableEmailAlert: vals.EnableEmailAlert,
          enableFanRuntimeAlert: vals.EnableFanRuntimeAlert,
          enableFanAmpsAlert: vals.EnableFanAmpsAlert,
          enableHotspotAlert: vals.EnableHotspotAlert,
          hotspotThresholdTemperatureF: {
            value: vals.hotspotThresholdTemperatureF?.value,
          },
          // enablePushAlert: vals.EnablePushAlert,
          enablePushAlert: false,
          enableSMSAlert: vals.EnableSMSAlert,
          alertEmail: vals.AlertEmail,
          alertPhoneNumber: vals.AlertPhoneNumber
      };
      props.onFinish(dto);
    } catch (err) {
      console.error("error occurred while submitting", err);
      message.error("error updating settings");
    }
  }

  useEffect(() => {
    form.setFieldsValue(userSettingsQuery.data);
 }, [userSettingsQuery.data]);

  const phoneNumberRegex = /^\d{10}$/;
  return (
    <React.Fragment>
      <h2>Alerts</h2>

      <Skeleton loading={userSettingsQuery.data == null} active>


        {userSettingsQuery.data != null && <Form initialValues={{
          hotspotThresholdTemperatureF: userSettingsQuery.data?.hotspotThresholdTemperatureF?.value ?? null,
        }} id={changeAlertSettingsFormId} name="Alert types" form={form} onFinish={onFinish}>
          <h4>CO2</h4>
          <Form.Item name="EnableCO2Alert" help="Notify when CO2 levels reaches 2,000 PPM" initialValue={userSettingsQuery.data?.enableCo2Alert}>
            <Switch defaultChecked={userSettingsQuery.data?.enableCo2Alert} onClick={onCo2Click} />
          </Form.Item>
          <h4>Hotspot</h4>
          <Space direction='horizontal' size="middle">
            <Form.Item name="EnableHotspotAlert" help={<><Typography.Text type='secondary'>You will receive an alert when a temp cable sensor reads </Typography.Text><Typography.Text type="secondary">{formatNumber(hotspotThresholdTemperatureF == null ? hotspotThresholdPlaceholderTemperatureF : hotspotThresholdTemperatureF, { decimalPlaces: true, filler: "__", suffix: "°F", showSuffixIfNoValue: true })}</Typography.Text></>} initialValue={userSettingsQuery.data?.enableHotspotAlert}>
              <Switch defaultChecked={userSettingsQuery.data?.enableHotspotAlert} onClick={onHotspotClick} />
            </Form.Item>
            <Form.Item name={['hotspotThresholdTemperatureF', 'value']}>
              <InputNumber style={{ width: "108px" }} placeholder={hotspotThresholdPlaceholderTemperatureF.toFixed(0)} addonAfter="°F" />
            </Form.Item>
          </Space>
          <h4>Fan Runtime</h4>
          <Form.Item name="EnableFanRuntimeAlert" help="Notify when fan run time has reached 0:00" initialValue={userSettingsQuery.data?.enableFanRuntimeAlert}>
            <Switch defaultChecked={userSettingsQuery.data?.enableFanRuntimeAlert} onClick={onFanRuntimeClick} />
          </Form.Item>
          <h4>Fan Amps</h4>
          <Form.Item name="EnableFanAmpsAlert" help="Notify if fan is on, but fan amperage is out of range (Only applies to DRI-Stack bins)" initialValue={userSettingsQuery.data?.enableFanAmpsAlert}>
            <Switch defaultChecked={userSettingsQuery.data?.enableFanAmpsAlert} onClick={onFanAmpsClick} />
          </Form.Item>
          <br />
          <h3>Notification Preferences</h3>
          <h4>Receive E-mail</h4>
          <Form.Item name="EnableEmailAlert" help="Notify via E-Mail" initialValue={userSettingsQuery.data?.enableEmailAlert}>
            <Switch defaultChecked={userSettingsQuery.data?.enableEmailAlert} onClick={onEmailClick} />
          </Form.Item>
          <h4>Receive SMS</h4>
          <Form.Item name="EnableSMSAlert" help="By turning this toggle 'on', you are opting in to receive SMS messages from Haber Technologies, Inc." initialValue={userSettingsQuery.data?.enableSMSAlert}>
            <Switch defaultChecked={userSettingsQuery.data?.enableSMSAlert} onClick={onSMSClick} />
          </Form.Item>
          {/* <h4>Receive App Alert</h4> 
            <Form.Item name="EnablePushAlert" help="Notify via mobile application" initialValue={userSettingsQuery.data?.enablePushAlert}>
              <Switch defaultChecked={userSettingsQuery.data?.enablePushAlert} onClick={onPushClick} />
            </Form.Item> */}
          <br />
          <h3>Contact Information </h3>
          <h4>E-mail  </h4>
          <Form.Item
            rules={[{ type: 'email', message: 'input is not a valid E-mail' }]}
            name="AlertEmail"
            extra="The e-mail all email alerts should be sent to"
            validateFirst
            initialValue={userSettingsQuery.data?.alertEmail}
          >
            <Input placeholder="E-mail" maxLength={50} />
          </Form.Item>
          <h4>Phone Number </h4>
          <Form.Item
            name="AlertPhoneNumber"
            rules={[
              {
                pattern: phoneNumberRegex,
                message: 'Please enter a valid 10-digit phone number (no spaces or special characters).',
              },
            ]}
            extra="The phone number all SMS alerts should be sent to"
            initialValue={userSettingsQuery.data?.alertPhoneNumber}
            validateFirst

          >
            <Input placeholder='Phone Number' minLength={10} maxLength={10} type='text' />
          </Form.Item>

        </Form>}
      </Skeleton>

    </React.Fragment>
  );
};

export default UserAlertSettings;