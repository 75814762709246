import * as React from 'react';
import { notification, Card, Col, Row, Result } from 'antd';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import ForecastDTO from 'src/models/ForecastDTO';
import DryingForecast from 'src/consts/DryingForecast';
import { formatNumber } from './HeaterControls';

interface State {
    loading: boolean;
    forecast: ForecastDTO;
    latitude: number;
    longitude: number;
    offline: boolean;
}

export default class FillingForcast extends React.Component<{ lat: number, lng: number }, State> {

    constructor(props: { lat: number, lng: number }) {
        super(props);
        this.state = {
            latitude: props.lat,
            longitude: props.lng,
            loading: true,
            offline: false,
            forecast: { forecastedDrying: null, threeDayScore: 0, lat: 0, lon: 0, daily: null, hourly: null }
        };
        this.getForecastData = this.getForecastData.bind(this);
    }

    componentDidMount() {
        this.getForecastData();
    }

    getForecastData = () => {
        EnterpriseApiService.getWeatherForecast(this.state.latitude, this.state.longitude).then(forecast => {
            this.setState({
                loading: false,
                forecast: forecast,
            });
        }).catch(error => {
            this.setState({ loading: false, offline: true });
            console.log(error);
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }

    render() {
        const offline = this.state.forecast == null;
        if (this.state.loading) {
            return (
                <Card title="Weather Forecast" >
                    <p>loading...</p>
                </Card>
            );
        } else {
            const forecast = this.state.forecast.daily;
            let days = [];
            if (forecast) {
                for (let i = 0; i < forecast.length; i++) {
                    let dailyForecast = forecast[i].dailyForecast;
                    if (dailyForecast) {
                        let calColor;
                        switch (dailyForecast.dryingForecast) {
                            case 0:
                                calColor = '#E38A3D';
                                break;
                            default:
                                calColor = '#005893';
                                break;
                        }
                        let weatherIconURL = 'http://openweathermap.org/img/wn/' + dailyForecast.icon + '@2x.png';
                        days.push(
                            <Col key={i} xs={12} sm={12} md={6} lg={6} xl={3} style={{ padding: 10 }}>
                                <Card
                                    title={dailyForecast.day}
                                    style={{ borderColor: calColor }}
                                    styles={{header: {background: calColor, color: "#fefffe"}, body: {display: 'flex', flexDirection: 'column', alignItems: 'center'}}}
                                >
                                    <p>Temperature: {dailyForecast.temp}°F</p>
                                    <p>Humidity: {dailyForecast.rh}%</p>
                                    <p>Dew Point: {formatNumber(dailyForecast.dewPoint, {decimalPlaces: 0, suffix: "°F"})}</p>
                                    <p>{DryingForecast[dailyForecast.dryingForecast]}</p>
                                    <img src={weatherIconURL} />
                                </Card>
                            </Col>
                        );
                    }
                }
            }
            return (
                <Card title="Weather Forecast" >
                    <Row >{!offline ? days : <Result status="warning" title="Error Getting Weather Data" />}</Row>
                </Card >
            );
        }
    }
}
