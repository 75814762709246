import { http, HttpResponse, bypass, passthrough} from 'msw'
import BinDTO from 'src/models/BinDTO';
import {produce} from "immer"
import dristackdto from "./dristackbindtodefault.json";
import autobinDdtodefaultJson from "./autobindtodefault.json";
import getCurrentUserJson from "./getCurrentUser.json";
import getBinByIdJson from "./getBinById.json";
import growerIdOfUserJson from "./api_Enterprise_Groweridsofuser.json";
import growerDetailJson from "./api_Enterprise_growerId.json";
import getAlertHistoryJson from "./getAlertHistory.json";
// @ts-ignore
import demoCameraImage from "./demo-camera-image.jpg";
/*
/api/grainbin/GetLayerSummaryHistory?binId=20&from=2023-11-03T05%3A00%3A00.000Z&to=2023-11-18T22%3A00%3A00.999Z"
*/
import getLayerSummaryHistoryJson from "./getLayerSummaryHistory.json";
//import layerSummaryHistoryJson from "./layerSummaryHistory.json";
import getOPICableHistoryJson from "./getOPICableHistory.json";
import getTemperatureCableHistoryJson from "./getTemperatureCableHistory.json";
import ambientPlenumHistoryJson from "./getAmbientPlenumHistory.json";
import getOperatingModeChangesJson from "./getOperatingModeChanges.json";
import getCO2HistoryChartJson from "./getCO2HistoryChart.json";
import getGrainHeightsHistoryJson from "./getGrainHeightsHistory.json";
import getGrainHeightsHistoryUserEnteredJson from "./getGrainHeightsHistoryUserEntered.json";
import getAmbientPlenumEMCHistoryJson from "./getAmbientPlenumEMCHistory.json";
import getLowestLayerTemperatureHistoryJson from "./getLowestLayerTemperatureHistory.json";
import getFanHeaterChartJson from "./getFanHeaterChart.json";
import getFanHeaterChangeHistoryJson from "./getFanHeaterChangeHistory.json";
import weatherJson from "./weather.json";
import userIdJson from "./user_id.json"
// GET /api/enterprise/userAlertSettings
import getUserAlertSettingsJson from "./getUserAlertSettings.json";
// todo: TargetLayer for Dri-Stack
// todo: Compressor jsonf ro Dri-Stack

import {CompletionMessage, JsonHubProtocol, MessageType,  NullLogger} from "@microsoft/signalr";
import dayjs from 'dayjs';
import OperatingMode from 'src/consts/OperatingMode';
import FanOffReason from 'src/consts/FanOffReason';
import { camelCase } from 'lodash';
import GrainType from 'src/consts/GrainType';
import LoadDTO from 'src/models/LoadDTO';

let defaultDTO: BinDTO = dristackdto as unknown as BinDTO;
defaultDTO = autobinDdtodefaultJson as unknown as BinDTO;

//let notifications: any[] = [];
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const connectionStates: Map<string, any> = new Map();

class FakeModule {

    static TurnOnFans(bindto: BinDTO): BinDTO {
        const nextState = produce(defaultDTO, draft => {
            draft.isFanOn = true;
            draft.reasonForPause = null;
            draft.fanOperations!.desiredFanOn = true;
            draft.fanOperations!.ignoreFanRemainingOnTime = true;
            draft.fanOperations!.offReason = null;
            draft.fans?.map(fan => {
                fan.fan!.isOn = true;
                fan.isOn = true;
                fan.fan!.current!.current_mA = 25_000;
                fan.ampReading = 25000;
                fan.isOn = true;
            })
        }
        );
        return nextState;

    }

    static TurnOffFans(bindto: BinDTO): BinDTO {
        const nextState = produce(defaultDTO, draft => {
            draft.isFanOn = false;
            draft.reasonForPause = "Requested";
            draft.fanOperations!.desiredFanOn = false;
            draft.fanOperations!.ignoreFanRemainingOnTime = false;
            draft.fanOperations!.offReason = FanOffReason.Requested;
            draft.fans?.map(fan => {
                fan.fan!.isOn = false;
                fan.isOn = false;
                fan.fan!.current!.current_mA = 0;
                fan.ampReading = 0;
                fan.isOn = false;
                fan.isHeaterOn = false;
                fan.heaterAmps = 0;
            })
        }
        );
        return nextState;

    }
}

const readUploadedFileAsBase64 = (inputFile: Blob) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export let handlers = [
  // Intercept "GET https://example.com/user" requests...
  //http.all('/api/grainbin/updateFanSettings', (req, res, ctx) => {

  http.post("/api/Account/Login", async (ctx) => {
    return new HttpResponse(null, {status: 200});
  }),
  http.post("/api/Account/Logout", async (ctx) => {
    return new HttpResponse(null, {status: 200});
  }),
  http.get("/api/Account/GetCurrentUser", async (ctx) => {
    return HttpResponse.json(getCurrentUserJson);
  }),
  http.get("/api/enterprise/getbinbyid", async (ctx) => {
    return HttpResponse.json(getBinByIdJson);
  }),
  http.get("/api/enterprise/groweridsofuser", async (ctx) => {
    return HttpResponse.json(growerIdOfUserJson);
  }),
  http.get("/api/enterprise/detail/:id", async (ctx) => {
    return HttpResponse.json(growerDetailJson);
  }),
  http.get("/api/enterprise/weather", async (ctx) => {
    return HttpResponse.json(weatherJson);
  }),

  http.get("/api/user/:id", async (ctx) => {
    return HttpResponse.json(userIdJson);
  }),

  http.post("/api/grainbin/GetAlertHistory", async (ctx) => {
    return HttpResponse.json(getAlertHistoryJson);
  }),
  http.post("/api/Account/ChangePassword", async (ctx) => {
    // disabled for demo purposes
    return HttpResponse.json({
      "type": "https://httpstatuses.io/400",
      "title": "Demo",
      "status": 400,
      "detail": "Not available due to being a demo",
      "traceId": "00-6515f5e8a0b94df2d11b5886d6b8522a-add62b98effeef4f-00"
  }, {status: 400, statusText: "demo"})
  }),
http.get("/api/enterprise/userAlertSettings", async (ctx) => {
  return HttpResponse.json(getUserAlertSettingsJson);
}),
http.post("/api/enterprise/userAlertSettings", async (ctx) => {
    // disabled for demo purposes
    return HttpResponse.json({
      "type": "https://httpstatuses.io/400",
      "title": "Demo",
      "status": 400,
      "detail": "Not saved due to being a demo",
      "traceId": "00-6515f5e8a0b94df2d11b5886d6b8522a-add62b98effeef4f-00"
  }, {status: 400, statusText: "demo"})
}),
// camera
http.get("/api/grainbin/getCameraImage", async (ctx) => {
  return HttpResponse.json({
    base64Image: (demoCameraImage as string).split(",")[1],
    lastModifiedUtc: dayjs().toISOString(),
  })
}),
http.post("/api/grainbin/takeCameraPicture", async (ctx) => {
  return HttpResponse.json(true);
}),

http.post("/api/grainbin/SetGrainType", async (ctx) => {
  const requestDTO = await ctx.request.json() as any;
  const grainType = requestDTO?.grainType as GrainType;
  defaultDTO = produce(defaultDTO, draft => {
    draft.grain!.grainType = grainType;
    draft.currentBatch!.grainType = grainType;
  })
  return HttpResponse.json({success: true});
}),
  http.get("/api/grainbin/getLayerSummaryHistory", async (ctx) => {

    return HttpResponse.json(getLayerSummaryHistoryJson);
  }),
  http.get("/api/grainbin/GetOPICableHistory", async (ctx) => {
    return HttpResponse.json(getOPICableHistoryJson);
  }),
  http.get("/api/grainbin/GetTemperatureCableHistory", async (ctx) => {
    return HttpResponse.json(getTemperatureCableHistoryJson);
  }),
  http.get("/api/grainbin/getAmbientPlenumHistory", async (ctx) => {
    return HttpResponse.json(ambientPlenumHistoryJson);
  }),
  http.get("/api/grainbin/GetOperatingModeChangesDataset", async (ctx) => {
    return HttpResponse.json(getOperatingModeChangesJson);
  }),
  http.get("/api/grainbin/GetCO2History", async (ctx) => {
    return HttpResponse.json(getCO2HistoryChartJson);
  }),
  http.get("/api/grainbin/GetGrainHeightsHistory", async (ctx) => {
    return HttpResponse.json(getGrainHeightsHistoryJson);
  }),
  http.get("/api/grainbin/GetGrainHeightsHistoryUserEntered", async (ctx) => {
    return HttpResponse.json(getGrainHeightsHistoryUserEnteredJson);
  }),
  http.get("/api/grainbin/GetAmbientPlenumEMCHistory", async (ctx) => {
    return HttpResponse.json(getAmbientPlenumEMCHistoryJson);
  }),
  http.get("/api/grainbin/GetLowestLayerTemperatureHistory", async (ctx) => {
    return HttpResponse.json(getLowestLayerTemperatureHistoryJson);
  }),
  http.get("/api/grainbin/GetFanHeaterChart", async (ctx) => {
    return HttpResponse.json(getFanHeaterChartJson);
  }),
  http.get("/api/grainbin/getFanHeaterChangeHistory", async (ctx) => {
    return HttpResponse.json(getFanHeaterChangeHistoryJson);
  }),
  http.get("/api/grainbin/getBinDetailFromDevice", async (ctx) => {
    //notifications.push(1);
    return HttpResponse.json(defaultDTO);
  }),
  http.get("/api/grainbin/getBinDetailFromAzure", async (ctx) => {
    return HttpResponse.json(defaultDTO);
  }),
  http.post("/api/grainbin/UploadBinStateToAzure", async (ctx) => {
    // todo: this would trigger signalR
    return HttpResponse.json(true);
  }),
    http.post("/api/grainbin/RestartDristackModule", async (ctx) => {
      return HttpResponse.json(true);
    }),
    http.post('/api/grainbin/updateFanSettings', async (ctx) => {


      const val = ctx.request;
      const body = await ctx.request.json() as any;
    console.log("MSW url:", {params: ctx.params, body, url: ctx.request.url});
    if (body!.mode == "AlwaysOn") {
        defaultDTO = FakeModule.TurnOnFans(defaultDTO);
      }
    else if (body!.mode == "AlwaysOff") {
        defaultDTO = FakeModule.TurnOffFans(defaultDTO);
    }
    else if (body!.mode === "Auto") {
        //defaultDTO = FakeModule.TurnOffFans(defaultDTO);

    }


  
  return HttpResponse.json(true);
  }),

  http.post("/api/grainbin/addLoad", async (ctx) => {

    // todo: May not be right, not suppported for autobins (yet UI shows in test/prod)
    const loadDto: LoadDTO = await ctx.request.json() as unknown as LoadDTO;
    const loadLength = defaultDTO.currentBatch!.loads?.length ?? 0;
    const loadUnloadLength = defaultDTO.currentBatch!.loadsUnloads?.length ?? 0;
    defaultDTO = produce(defaultDTO, draft => {

      const loadToAdd = {
        ...loadDto,
        id: loadLength + 1,
        batchId: defaultDTO.currentBatch!.batchID,
        amountIn: loadDto.amountIn,
        source: loadDto.source,
        dateTime: loadDto.dateTime,
        loadMCPercent: loadDto.loadMCPercent,
        eventType: loadDto.eventType,        
      };
      draft.currentBatch!.loads?.push(loadToAdd);
      draft.currentBatch!.loadsUnloads?.push({
        ...loadToAdd,
        id: loadUnloadLength + 1,
      })
    });
    return HttpResponse.json(true);
  }),
  http.post("/api/grainbin/Idle", async (ctx) => {
    console.log("MSW url:", {params: ctx.params, url: ctx.request.url});

    const nextState = produce(defaultDTO, draft => {
      draft.operatingMode = OperatingMode.Idle;
      draft.reasonForPause = null;
      draft.fanOperations!.desiredFanOn = false;
      draft.fanOperations!.ignoreFanRemainingOnTime = true;
      draft.fanOperations!.offReason = FanOffReason.Requested;
      draft.fans?.map(fan => {
        fan.fan!.isOn = false;
        fan.isOn = false;
        fan.fan!.current!.current_mA = 0;
        fan.ampReading = 0;
      });
  });
  defaultDTO = nextState;
  return HttpResponse.json(true);
  }),
  http.post('/api/*', async (ctx) => {


    const val = ctx.request;
    const body = await ctx.request.json();
  console.log("MSW url:", {params: ctx.params, body, url: ctx.request.url});
}),

// http.post("/signalr/negotiate", (ctx) => {
// // https://localhost:58372/signalr/negotiate?negotiateVersion=1

// // original resopnse from signalr with all transports
// const originalTransports = {
//     "negotiateVersion": 1,
//     "connectionId": "StcOL_-34NAmDaYOarCqaglo_GEg802",
//     "connectionToken": "o7Z0Kbw64J8Ov_d6bE_a6wlo_GEg802",
//     "availableTransports": [
//         {
//             "transport": "WebSockets",
//             "transferFormats": [
//                 "Text",
//                 "Binary"
//             ]
//         },
//         {
//             "transport": "ServerSentEvents",
//             "transferFormats": [
//                 "Text"
//             ]
//         },
//         {
//             "transport": "LongPolling",
//             "transferFormats": [
//                 "Text",
//                 "Binary"
//             ]
//         }
//     ]
// };

// const onlyLongPollingText = {
//     "negotiateVersion": 1,
//     "connectionId": "StcOL_-34NAmDaYOarCqaglo_GEg802",
//     "connectionToken": "o7Z0Kbw64J8Ov_d6bE_a6wlo_GEg802",
//     "availableTransports": [
//         {
//             "transport": "LongPolling",
//             "transferFormats": [
//                 "Text"
//             ]
//         }
//     ]
// }

// connectionStates.clear();

// return HttpResponse.json(onlyLongPollingText);

// }),

// http.get("/signalr", async (ctx) => {
//     const start = dayjs();
//     const url = new URL(ctx.request.url)
//     const id = url.searchParams.get("id");

//     const TIMEOUT_MS = 20_000;

//     // if id is present we wait for new events
//     if (id != null) {
//         while (notifications.length == 0) {
//             if (connectionStates.get(id) == null) {
//                 // send resopnse heartbeat
//                 connectionStates.set(id, "heartbeat");
//                 const endingMarker = "";
//                 var encoder = new TextEncoder();
//                 const stringified = JSON.stringify({});
//                 const encoded = encoder.encode(`${stringified}${endingMarker}`);
//                 var response = new HttpResponse(encoded)
//                 // looking for 91
//                 response.headers.set("Content-Length", encoded.length.toString());
//                 response.headers.set('Content-Type', 'application/octet-stream');
//                 return response;
//             }
//             const diff = dayjs().diff(start, 'seconds', true);
//             if (diff >= 20) {
//                 connectionStates.delete(id);
//                 const response = new HttpResponse();
//                 response.headers.set("Content-Length", (0).toString());
//                 return response;
//             }
//             await sleep(100);
//         }
//         notifications.pop();

//         const newSnapshotJson = {"type":1,"target":"NewSnapshot","arguments":[{"DeviceId":"Haber2022ShopBin","BinId":49}]};
//         const endingMarker = "";
//         var encoder = new TextEncoder();
//         const stringified = JSON.stringify(newSnapshotJson);
//         const encoded = encoder.encode(`${stringified}${endingMarker}`);
//         var response = new HttpResponse(encoded)
//         // looking for 91
//         response.headers.set("Content-Length", encoded.length.toString());
//         response.headers.set('Content-Type', 'application/octet-stream');
//         //debugger;
//         return response;

//     }

//     const protocol = new JsonHubProtocol();
//     const payload = await ctx.request.text();
//     try {
//         const invocation = TextMessageFormat.parse(payload)[0];
//         const parsedInvocation = JSON.parse(invocation);
//         const invocationId = parsedInvocation.invocationId;
//         const autoHandShake = true;
//         if (parsedInvocation.protocol && parsedInvocation.version && autoHandShake) {
//             const payload = JSON.stringify({});
//             const encoder = new TextEncoder();
//             const encoded = encoder.encode(TextMessageFormat.write(payload))
//             var response = new HttpResponse(encoded)
//             // looking for 91
//             response.headers.set("Content-Length", encoded.length.toString());
//             response.headers.set('Content-Type', 'application/octet-stream');
//             debugger;
//             return response;
//         }
//         throw new Error("didn't get here yet");

//         //console.info("signalr msw: ", message);
//     }
//     catch (err) {
//         debugger;
//         throw err;
//     }
//     const newSnapshotJson = {"type":1,"target":"NewSnapshot","arguments":[{"DeviceId":"Haber2022ShopBin","BinId":49}]};
//     const endingMarker = "";
//     var encoder = new TextEncoder();
//     const stringified = JSON.stringify(newSnapshotJson);
//     const encoded = encoder.encode(`${stringified}${endingMarker}`);
//     var response = new HttpResponse(encoded)
//     // looking for 91
//     response.headers.set("Content-Length", encoded.length.toString());
//     response.headers.set('Content-Type', 'application/octet-stream');
//     return response;

// }),

// http.post("/signalr", async (ctx) => {
//     const url = new URL(ctx.request.url)
//     const id = url.searchParams.get("id");

//     const protocol = new JsonHubProtocol();
//     const payload = await ctx.request.text();
//     try {
//         const invocation = TextMessageFormat.parse(payload)[0];
//         const parsedInvocation = JSON.parse(invocation);
//         const invocationId = parsedInvocation.invocationId;
//         const autoHandShake = true;
//         if (parsedInvocation.protocol && parsedInvocation.version && autoHandShake) {
//             const payload = JSON.stringify({});
//             const encoder = new TextEncoder();
//             const encoded = encoder.encode(TextMessageFormat.write(payload))
//             var response = new HttpResponse(encoded)
//             // looking for 91
//             response.headers.set("Content-Length", encoded.length.toString());
//             response.headers.set('Content-Type', 'application/octet-stream');
//             return response;
//         }
//         //throw new Error("didn't get here yet");

        
//         // todo invocation id

//         //https://github.com/dotnet/aspnetcore/blob/main/src/SignalR/docs/specs/HubProtocol.md
//         // "{\"arguments\":[49],\"invocationId\":\"0\",\"target\":\"SubscribeBin\",\"type\":1}\u001e"
//         if (parsedInvocation.type == MessageType.Invocation) {
//             const protocol = new JsonHubProtocol();
//             const completion: CompletionMessage = {type: MessageType.Completion, invocationId: parsedInvocation.invocationId, result: null};
//             const serialized = protocol.writeMessage(completion);
//             const encoder = new TextEncoder();
//             const encoded = encoder.encode(TextMessageFormat.write(payload))
//             var response = new HttpResponse(encoded)
//             // looking for 91
//             response.headers.set("Content-Length", encoded.length.toString());
//             response.headers.set('Content-Type', 'application/octet-stream');
//             return response;
//         }

//         //console.info("signalr msw: ", message);
//     }

// catch (err) {
//     debugger;
//     throw err;
// }
//     // if (body?.protocol == "json") {
//     //     const endingMarker = "";
//     //     var encoder = new TextEncoder();
//     //     const encoded = encoder.encode(`{}${endingMarker}`);
//     //     var response = new HttpResponse(encoded)
//     //     response.headers.set("Content-Length", encoded.length.toString());
//     //     response.headers.set('Content-Type', 'application/octet-stream');
//     //     return response;
//     // }
//     // var response = new HttpResponse(undefined, {status: 200})
//     // return response;

// }),

// http.get("/signalr/client/", (ctx) => {
    
//     if (notifications.length == 0) {
//         notifications.pop();
//         return new HttpResponse(undefined, {status: 204});
//     }
//     else {
//         const newSnapshotJson = {"type":1,"target":"NewSnapshot","arguments":[{"DeviceId":"Haber2022ShopBin","BinId":49}]};
//         const endingMarker = "";
//         var encoder = new TextEncoder();
//         const encoded = encoder.encode(`${newSnapshotJson}${endingMarker}`);
//         var response = new HttpResponse(encoded)
//         // looking for 91
//         response.headers.set("Content-Length", encoded.length.toString());
//         response.headers.set('Content-Type', 'application/octet-stream');
//         //debugger;
//         return response;
//     }
// })

];

//handlers.splice(0);