// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum OperatingMode {
    Idle = 0,
    FillBin = 1,
    PreDry = 2,
    TopDry = 31,
    Dry = 3,
    Storage = 4,
    EmptyBin = 5,
    Manual = 20,
    FailSafe = 100,
    SelfCheck = 101,
}
export default OperatingMode;
