import { RedoOutlined } from "@ant-design/icons";
import { useIsFetching } from "@tanstack/react-query";
import { Space, Button } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "./ChartContainer";
import React, { useCallback, useState } from "react";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { ChartDatePicker } from "./Co2Chart";
import { RangeFinderChart } from "./RangeFinderChart";
import { BaseChartProps } from "./TemperatureCableChart";

export const FillLevelChartTab = (props: BaseChartProps) => {


    const currentDate = dayjs('2023-11-20T04:59:59Z');
    const priorDate = dayjs('2023-11-03T13:32:00Z');
  
  
    const isControlled = typeof props.value != 'undefined';
    const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);
    const inprogress = useIsFetching([...binDBKeys.bin(props.binId), "charts"]);
  
    // Internally, we need to deal with some value. Depending on whether
    // the component is controlled or not, that value comes from its
    // props or from its internal state.
  
    const value = isControlled ? props.value : internalValue;
  
  
    const onDateChange = useCallback((values: RangeValue, formatter?: any) => {
        if (props.onChange) {
          props.onChange(values);
        }
        else {
          setIntervalValue(values);
        }
      }, [props.onChange]);

    return <>
        <div>
            <Space direction="horizontal" wrap>
                <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />
                <Button type="primary" disabled={inprogress > 0} icon={<RedoOutlined spin={inprogress > 0} />} onClick={() => {
                    onDateChange([value?.[0]!, dayjs()], ['', '']);
                }}>
                </Button>

            </Space>

            <div style={{ width: "100%" }}>
                <RangeFinderChart binId={props.binId} deviceId={props.deviceId} onChange={onDateChange} value={value} />
            </div>
        </div>
    </>
}